import React, { Fragment, useState, useEffect, useRef } from "react";
import Navbar from "../../Navbar";
import Footer from "../../Footer";
import { useTranslation } from 'react-i18next';
import travelimg from '../../tr.png';
import { format } from 'date-fns';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
import { motion, AnimatePresence } from "framer-motion";
import Select2Wrapper from '../../Select2Wrapperins';
import SignatureCanvas from 'react-signature-canvas';
import $ from 'jquery';
import { faLadderWater } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from 'react-router-dom';


const { config } = require('../../api/api');

function SandTravelInsurance() {
  const base_url = config.baseURL;
  const redirecturl = config.redirectURL
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  const formattedDate = tomorrow.toISOString().split('T')[0];
  const formattedDate1 = today.toISOString().split('T')[0];
  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';
  const [isHidePage, setIsHidePage] = useState('');
  const { t } = useTranslation();
  const selectOpt1 = useRef(null);
  const [travelrequestid, setTravelRequestId] = useState(null);
  const [isOmanResident, setIsOmanResident] = useState(null);
  const [loading, setLoading] = useState(false);
  const [plan_detail_loading, setPlanLoading] = useState(false);
  const [paymet_loading, setPaymentLoading] = useState(false);
  const [vendorKey, setVendorKey] = useState(null);
  const [travelInsuranceToken, setTravelInsuranceToken] = useState(null);
  const [omanvalue, setOmanvalue] = useState(false);
  const [notomanvalue, setNotOmanvalue] = useState(false);
  const [countryData, setCountryData] = useState(null);
  const [sanadRequesteeName, setSanadRequesteename] = useState('');
  const [sanadUserName, setSanadUserName] = useState('');
  const [sanadUserMobileNumber, setSanadUserMobileNumber] = useState('');
  const [sanadReferenceNumber, setSanadReferenceNumber] = useState('');
  const [sanadCivilIDNumber, setSanadCivilIDNumber] = useState('');
  const [sanadTransDate, setSanadTransDate] = useState('');
  const [sanadReturnUrl, setSanadReturnUrl] = useState('');
  const [sanadErrorUrl, setSanadErrorUrl] = useState('');
  const [sanadBackUrl, setSanadBackUrl] = useState('');
  const [sanadLanguage, setSanadLanguage] = useState('');
  // Inbound
  const [inboundProductData, setInboundProductData] = useState(null);
  const [selectedInboundNumberOfDays, setSelectedInboundNumberOfDays] = useState(null);
  const [selectedInboundCoverageOptionId, setSelectedInboundCoverageOptionId] = useState(null);
  const [selectedInboundCoverageOption, setSelectedInboundCoverageOption] = useState(null);
  const [selectedInboundFromCountryOption, setSelectedInboundFromCountryOption] = useState(null);
  const [selectedInboundFromCountryOptionid, setSelectedInboundFromCountryOptionid] = useState(null);
  const [selectedInboundFromCountry, setSelectedInboundFromCountry] = useState(null);

  const [selectedInboundNationalityOption, setSelectedInboundNationalityOption] = useState(null);
  const [selectedInboundNationalityid, setSelectedInboundNationalityid] = useState(null);
  const [selectedInboundNationality, setSelectedInboundNationality] = useState(null);
  const [selectedstartdOption, setSelectedstartdOption] = useState(formattedDate);
  const [errors, setErrors] = React.useState({});
  const [isInboundPersonalDeclarationChecked, setIsInboundPersonalDeclarationChecked] = React.useState(false);
  const [isInboundfinalChecked, setfinalDeclarationChecked] = React.useState(false);
  const [selectedgenderOption, setSelectedgenderOption] = useState(null);
  const [selectedgenderOptionid, setSelectedgenderOptionid] = useState(null);
  const [selectedMaritelOption, setSelectedMaritalOption] = useState(null);
  const [selectedMaritalOptionid, setSelectedMaritalOptionid] = useState(null);
  const [phoneaValue, setphoneaValue] = useState('');

  const [inboundPlanDetails, setInboundPlanDetails] = useState(null);
  const [showplan, SetshowPlan] = useState(false);
  const [inboundQuestionData, setInboundQuestionData] = useState(null);
  const [selectedInboundQuestionOption, setSelectedInboundQuestionOption] = useState(null);
  const [moptions, setMarital] = useState([]);
  const [goptions, setGender] = useState([]);
  const [policy_no, SetPolicyNo] = useState(null);
  const [paymemnt_link, seyPaymetLink] = useState(null);
  const [done, setDone] = useState(false);
  const [done1, setDone1] = useState(false);
  const [inboundpage, setInboundPage] = useState(true);
  const [viewsummary, setViewSummary] = useState(false);
  const [isFormFrozen, setIsFormFrozen] = useState(false);
  const [policydetails, setPolicyDetails] = useState(null);
  // const formattedDate = new Date().toISOString().split("T")[0]; // Current date in YYYY-MM-DD format
  //error
  const [coverageerror, setCoverageError] = useState("");
  const [dayserror, setdaysError] = useState("");
  const [countryserror, setCountryError] = useState("");
  const [dateerror, setDateError] = useState("");
  const [fnameerror, setFnameError] = useState("");
  const [lnameerror, setLnameError] = useState("");
  const [doberror, setDobError] = useState("");
  const [emailerror, setEmailError] = useState("");
  const [passporterror, setpassportError] = useState("");
  const [nationalityerror, setNationalityError] = useState("");
  const [phoneerror, setPhoneError] = useState("");
  const [gendererror, setGenderError] = useState("");
  const [maritalstatuserror, setMaritalError] = useState("");

  const [isSignatureEmpty, setIsSignatureEmpty] = useState(true);
  const signatureRef = useRef();
  const clearSignature = () => {
    signatureRef.current.clear();
    setIsSignatureEmpty(true); // Disable button after clearing
  };
  const handleSignature = () => {
    debugger
    if (!signatureRef?.current?.isEmpty()) {
      setIsSignatureEmpty(false); // Enable button when signature is drawn
    }
  };


  // OUTBOUND
  const outsignatureRef = useRef(null);
  const [outboundvendorKey, setOutboundVendorKey] = useState(null);
  const [outboundtravelInsuranceToken, setOutboundTravelInsuranceToken] = useState(null);
  const [outboundmoptions, setOutboundMarital] = useState([]);
  const [outboundgoptions, setOutboundGender] = useState([]);
  const [outboundProductData, setOutboundProductData] = useState(null);
  const [outboundcountryData, setOutboundCountryData] = useState(null);
  const [outboundQuestionData, setOutboundQuestionData] = useState(null);

  // const [selectedcOption, setSelectedcOption] = useState(null);
  // const [selectedcOptionid, setSelectedcOptionid] = useState(null);

  const [selectedOutboundProductOption, setSelectedOutboundProductOption] = useState(null);
  const [selectedOutboundProductOptionid, setSelectedOutboundProductOptionid] = useState(null);
  const [selectedOutboundCoverageOption, setSelectedOutboundCoverageOption] = useState(null);
  const [selectedOutboundCoverageOptionid, setSelectedOutboundCoverageOptionid] = useState(null);

  const [outboundPackageData, setOutboundPackageData] = useState(null);
  const [selectedOutboundNumberOfDays, setSelectedOutboundNumberOfDays] = useState(null);
  const [selectedOutboundtravellers, setSelectedOutboundtravellers] = useState(null);
  const [viewdone, setViewDone] = useState(false);
  const [phonecode, setphonecode] = useState('');
  const [outpolicy_no, SetOutPolicyNo] = useState(null);
  const [outboundPlanDetails, setOutboundPlanDetails] = useState(null);
  const [outplanshowvalue, setOutPlanShowValue] = useState(false);
  const [selectedoutboundQuestionOption, setSelectedOutboundQuestionOption] = useState(null);
  const [selectedoutboundQuestionOption1, setSelectedOutboundQuestionOption1] = useState(null);
  const [outInboundfinalChecked, setoutfinalDeclarationChecked] = React.useState(false);
  const [outInboundfinalChecked1, setoutfinalDeclarationChecked1] = React.useState(false);
  const [outInboundfinalChecked2, setoutfinalDeclarationChecked2] = React.useState(false);
  const [selectedcaOption, setSelectedcaOption] = useState(null);
  const [selectedcaOptionid, setSelectedcaOptionid] = useState(null);
  const [outboundpolicydetails, setOutBoundPolicyDetails] = useState(null);


  const [outboundpage, setOutboundPage] = useState(true);
  const [viewoutboundsummary, setViewOutBoundSummary] = useState(false);
  const [outboundpolicy_no, SetOutBoundPolicyNo] = useState(null);
  const [outbound_paymemnt_link, setOutBoundPaymetLink] = useState(null);


  const [selectedoutboundOption, setSelectedOutboundOption] = useState([]);
  const [selectedfrmOption, setSelectedfrmOption] = useState(null);
  const [selectedoutboundgenderOption, setSelectedOutboundgenderOption] = useState(null);
  const [selectedoutboundgenderOptionid, setSelectedOutboundgenderOptionid] = useState(null);
  const [selectedoutboundmaritalOption, setSelectedOutboundMaritalOption] = useState(null);
  const [selectedoutboundmaritalOptionid, setSelectedOutboundMaritalOptionid] = useState(null);


  const [outboundtravellerserror, setoutboundTravellersError] = useState("");
  const [outboundproductserror, setOutboundProductError] = useState("");
  const [outboundcoverageerror, setOutBoundCoverageError] = useState("");
  const [outbounddateerror, setOutboundDateError] = useState("");
  const [outboundfnameerror, setOutboundFnameError] = useState("");
  const [outboundlnameerror, setOutboundLnameError] = useState("");
  const [outbounddoberror, setOutboundDobError] = useState("");
  const [outboundemailerror, setOutboundEmailError] = useState("");
  const [outboundpassporterror, setOutboundpassportError] = useState("");
  const [outboundnationalityerror, setOutboundNationalityError] = useState("");
  const [outboundphoneerror, setOutboundPhoneError] = useState("");
  const [outboundgendererror, setOutboundGenderError] = useState("");
  const [outboundmaritalstatuserror, setOutboundMaritalError] = useState("");
  const [outboundplandetailserror, setOutboundPlanDetailsError] = useState("");


  const [selectedOutboundStartDate, setSelectedOutboundStartDate] = useState(formattedDate);
  const [outboundnationality, setOutNationality] = useState("");

  const [outboundisSignatureEmpty, setoutboundIsSignatureEmpty] = useState(true);
  const [outboundSignatureValue, setOutboundSignatureValue] = useState("");

  const outclearSignature = () => {
    outsignatureRef.current.clear();
    setoutboundIsSignatureEmpty(true); // Disable button after clearing
  };
  const handleOutSignature = () => {
    debugger
    if (!outsignatureRef?.current?.isEmpty()) {
      setoutboundIsSignatureEmpty(false); // Enable button when signature is drawn
    }
  };

  const [personalInfo, setPersonalInfo] = React.useState({
    firstName: '',
    lastName: '',
    dob: '',
    email: '',
    nationality: '',
    phone: '',
    gender: '',
    maritalStatus: '',
    passportNumber: '',
  });



  useEffect(() => {
    const urlSearchString = window.location.search;
    const uparams = new URLSearchParams(urlSearchString);
    const sanadUserName = uparams.get('SANADUSERNAME');
    const sanadRequesteename = uparams.get('REQUESTEENAME');
    const sanadUserMobileNumber = uparams.get('REQUESTEEMOBILENO');
    const sanadReferenceNumber = uparams.get('SANADREFNUMBER');
    const sanadCivilIDNumber = uparams.get('REQUESTEECIVILNO');
    const sanadAccessToken = uparams.get('SANADACCESSTOKEN');
    const sanad_TransDate = uparams.get('TRANSDATE');
    const sanad_ReturnUrl = uparams.get('RETURNURL');
    const sanad_ErrorUrl = uparams.get('ERRORURL');
    const sanad_BackUrl = uparams.get('BACKURL');
    const sanad_Language = uparams.get('LANGUAGE');
    const requestid = uparams.get('SERVICEREQUESTID');
    setSanadUserName(sanadUserName);
    setSanadRequesteename(sanadRequesteename);
    setSanadUserMobileNumber(sanadUserMobileNumber);
    setSanadReferenceNumber(sanadReferenceNumber);
    setSanadCivilIDNumber(sanadCivilIDNumber);
    setSanadTransDate(sanad_TransDate);
    setSanadReturnUrl(sanad_ReturnUrl);
    setSanadErrorUrl(sanad_ErrorUrl);
    setSanadBackUrl(sanad_BackUrl);
    setSanadLanguage(sanad_Language);
    setTravelRequestId(requestid);

  // if (sanadAccessToken === "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImFkbWluQHNhbmFkLmNvbSIsInBhc3N3b3JkIjoiMTIzNCIsImlhdCI6MTcyODA1MjA0NywiZXhwIjoxNzI4MDUyOTQ3fQ.ucyIy6q6ovSDstXZL1zO-9swTAGYFeypV0uo8_rQJJc") {
  //     setIsHidePage('success');
  // } else {
  //     setIsHidePage('fail');
  // }
  setPersonalInfo({
    firstName: sanadRequesteename,
    lastName: '',
    dob: '',
    email: '',
    nationality: '',
    phone: sanadUserMobileNumber,
    gender: '',
    maritalStatus: '',
    passportNumber: '',
  });
  }, []);
  const styles = {
    section: {
      background: "#f4f4f4",
      padding: "30px 0",
      fontFamily: "'Arial', sans-serif",

    },
    container: {
      width: "100%",
      // maxWidth: "600px",
      margin: "0 auto",
      background: "#ffffff",
      padding: "20px",
      borderRadius: "10px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start", // Align content to the left
    },
    heading: {
      fontSize: "25px",
      color: "#333",
      textAlign: "center",
      marginBottom: "20px",
    },
    radioGroup: {
      display: "flex",
      // justifyContent: "center",
      // flexDirection: 'column', // Stack vertically
      alignItems: 'flex-start', // Align items to the left
      gap: "20px",
      margin: "20px 0",
    },
    radioLabel: {
      fontSize: "18px",
      color: "#444",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
    },
    radioLabelOption: {
      fontSize: "14px",
      color: "#444",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
    },
    radioInput: {
      marginRight: "8px",
      fontSize: "20px",
    },
    questionSection: {
      // marginTop: "20px",
      // textAlign: "center",
      alignItems: 'flex-start',
    },
    question: {
      fontSize: "15px",
      color: "#333",
      // marginBottom: "15px",
    },
    feedback: {
      marginTop: "20px",
      textAlign: "center",
      padding: "10px",
      borderRadius: "8px",
      background: "#e6f7e6",
      color: "#2e7d32",
      fontSize: "16px",
    },
    card: {
      backgroundColor: '#f8f9fa',
      borderRadius: '8px',
      padding: '15px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      transition: 'all 0.3s ease-in-out',
      margin: '10px 0',
      width: '100%',  // Full width within the container
      maxWidth: '600px',  // Optional: set max width for larger screens
    },
    option: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    label: {
      fontSize: '16px',
      fontWeight: '600',
      color: '#333',
      flex: 1,  // Allow the label to take up available space
    },
    radio: {
      width: '20px',  // Increased radio button size
      height: '20px',
      backgroundColor: '#007bff',
      borderRadius: '50%',
      marginLeft: '10px',
    },
    select: {
      width: '100%',  // Make dropdown take full width
      padding: '12px',  // Increase padding for better appearance
      fontSize: '16px',  // Ensure text is clear and readable
      borderRadius: '8px',
      transition: 'opacity 0.5s ease',
      '&:hover': {
        opacity: 0.9,  // Slightly fade the dropdown
        borderColor: '#007bff', // Highlight with blue border on hover
      },
    },
    heading: {
      marginBottom: '10px',
      marginTop: '15px',
    },
    motionSection: {
      width: '100%',
      // marginTop: "20px",
      // textAlign: "center",
      alignItems: 'flex-start',
    },
  };

  const validate = () => {
    const newErrors = {};

    // First name validation (letters only)
    if (!/^[A-Za-z]+$/.test(personalInfo.firstName)) {
      newErrors.firstName = 'First name must only contain letters.';
    }

    // Last name validation (letters only)
    if (!/^[A-Za-z]+$/.test(personalInfo.lastName)) {
      newErrors.lastName = 'Last name must only contain letters.';
    }

    // DOB validation (ensure not empty)
    if (!personalInfo.dob) {
      newErrors.dob = 'Date of Birth is required.';
    }

    // Email validation
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(personalInfo.email)) {
      newErrors.email = 'Enter a valid email address.';
    }

    // Phone validation (numbers only)
    if (!/^\d+$/.test(personalInfo.phone)) {
      newErrors.phone = 'Phone number must contain only numbers.';
    }

    // Passport number validation (ensure not empty)
    if (!personalInfo.passportNumber) {
      newErrors.passportNumber = 'Passport number is required.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when location changes
  }, [location]);

  // agent_token
  const [userData, setUserData] = useState();

  useEffect(() => {
    const storedUserString = Cookies.get('userData'); //sessionStorage.getItem('userData')
    if (storedUserString != undefined) {
      const storedUser = JSON.parse(storedUserString);
      setUserData(storedUser);
    } else {
      // alert("token expired");
      // window.location.href = '/';
    }
  }, []);

  const maxDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate()).toISOString().split('T')[0];



  const handleChange = (field, value) => {
    setPersonalInfo((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };
  const getInboundData = async (vendor_key, insuranceToken) => {

    var tokenData = { token: insuranceToken };
    // Get Gender
    var inbound_gender_response = await fetch(`${base_url}/getsanadtravel_inbound_gender`, { method: 'POST', headers: { 'Authorization': `Bearer ${vendor_key}`, 'Content-Type': 'application/json' }, body: JSON.stringify(tokenData) });
    var inbound_gender_response_data = await inbound_gender_response.json();
    if (inbound_gender_response_data.message == 'success') {

      if (inbound_gender_response_data?.result?.data.response_data) {
        setGender(inbound_gender_response_data.result.data.response_data);
      }
    }
    // Get martial
    var inbound_marital_response = await fetch(`${base_url}/getsanadtravel_inbound_married`, { method: 'POST', headers: { 'Authorization': `Bearer ${vendor_key}`, 'Content-Type': 'application/json' }, body: JSON.stringify(tokenData) });
    var inbound_marital_response_data = await inbound_marital_response.json();
    if (inbound_marital_response_data.message == 'success') {

      if (inbound_marital_response_data?.result?.data.response_data) {
        setMarital(inbound_marital_response_data.result.data.response_data);
      }
    }
    // Get Products
    var inbound_product_response = await fetch(`${base_url}/getsanadtravel_inbound_producttype`, { method: 'POST', headers: { 'Authorization': `Bearer ${vendor_key}`, 'Content-Type': 'application/json' }, body: JSON.stringify(tokenData) });
    var inbound_product_response_data = await inbound_product_response.json();
    if (inbound_product_response_data.message == 'success') {

      if (inbound_product_response_data?.result?.data[0]) {
        setInboundProductData(inbound_product_response_data.result.data[0]);
      }
    }
    // Get Countries
    var countries_response = await fetch(`${base_url}/getsanadtravel_inbound_contries`, { method: 'POST', headers: { 'Authorization': `Bearer ${vendor_key}`, 'Content-Type': 'application/json' }, body: JSON.stringify(tokenData) });
    var countries_response_data = await countries_response.json();
    if (countries_response_data.message == 'success') {
      if (countries_response_data?.result?.data?.response_data
      ) {
        setCountryData(countries_response_data.result.data.response_data
        );
      }

    }

    // Get Questions
    var question_response = await fetch(`${base_url}/getsanadtravel_inbound_questions`, { method: 'POST', headers: { 'Authorization': `Bearer ${vendor_key}`, 'Content-Type': 'application/json' }, body: JSON.stringify(tokenData) });
    var question_response_data = await question_response.json();

    if (question_response_data.message == 'success') {
      if (question_response_data?.result?.data
      ) {
        setInboundQuestionData(question_response_data.result.data
        );
      }
    }
  }


  useEffect(() => {
    const postvData = { organization_name: "Superjet" };
    const loadTravelInsuranceData = async () => {
      try {
        var vendor_key = null;
        var travel_insurance_token = null;
        setLoading(true);
        const organization_response = await fetch(`${base_url}/create_organization`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(postvData) });
        const organization_response_data = await organization_response.json();
        if (organization_response_data.message == 'success') {
          vendor_key = organization_response_data.result[0].vendor_key;
          setVendorKey(vendor_key);
          var travel_insurance_token_response = await fetch(`${base_url}/create_sanad_personal_accident_token`, { method: 'GET', headers: { 'Authorization': `Bearer ${vendor_key}` } });
          const travel_insurance_token_response_data = await travel_insurance_token_response.json();
          if (travel_insurance_token_response_data.message == 'success') {
            travel_insurance_token = travel_insurance_token_response_data?.result?.data?.token;
            setTravelInsuranceToken(travel_insurance_token);
            if (travel_insurance_token) {
              var token_validation_response = await fetch(`${base_url}/validate_dhofar_access_token`, { method: 'GET', headers: { 'Authorization': `${travel_insurance_token}`, 'Content-Type': 'application/json' } });
              const token_validation_response_data = await token_validation_response.json();
              if (token_validation_response_data.message == 'success') {
                await getInboundData(vendor_key, travel_insurance_token);
                setInboundPage(true);
              } else {
                window.alert('Session Timed Out');
                window.location.href = '/';
              }

            }

          }

        }
        setLoading(false);
        // setVendorKey(data.result?.[0]?.vendor_key || null);
      } catch (error) {
        setLoading(false);
        console.error('Error posting data:', error);
      }
    };

    loadTravelInsuranceData();

  }, []);




  useEffect(() => {
    debugger
    // Ensure the element is available in the DOM
    const element = document.getElementsByName("coverageselection")[0];
    if (element) {
      if (element.selectedIndex == 0) {
        element.focus();  // Focus the element once it's available
      }
      // element.style.border = '3px solid red'
    }
  }, []);

  useEffect(() => {
    debugger
    // Ensure the element is available in the DOM
    const element = document.getElementsByName("outbound_traveller")[0];
    if (element) {
      if (element.selectedIndex == 0) {
        element.focus();  // Focus the element once it's available
      }
      // element.style.border = '3px solid red'
    }
  }, []);




  const travelerRefs = useRef([]);
  const [outerrors, setOutErrors] = useState([]);

  // error
  const [outbounddayserror, setOutbounddaysError] = useState("");


  const [outboundFormRows, setOutboundFormRows] = useState([{ // Initial state for one traveler
    first_name: '',
    last_name: '',
    dob: '',
    email: '',
    passport_number: '',
    ccode: '',
    mobile_number: '',
    gender_value: '',
    marital_status_value: '',
    nationality: '',
    nationality_id: '',
    declaration: false,
    relation_id: 5,
    relation_value: "Principal",
    gender_id: '',
    marital_status_id: '',
    isValidEmail: '',
    isValidDob: '',
    isValidPassport: '',
    isValidFirstName: true,
    isValidLastName: true,
    isValidNationality: true,
    isValidMobilenumber: true,
    isValidGender: true,
    isValidMarried: true,
    isValid1dob: true,
    isValidEmail1: true,
    isValidPassport1: true,
  }]);


  const handleOutboundInputChange = (index, event, field) => {
    const updatedRows = [...outboundFormRows];
    if (event.target) {
      updatedRows[index][field] = event.target.value;
    } else if (field == 'dob') {

      updatedRows[index][field] = format(new Date(event), 'dd-MM-yyyy');
    } else {
      // For Select2Wrapper or non-standard inputs
      updatedRows[index][field] = event;
    }
    setOutboundFormRows(updatedRows);
  };

  const handleOutboundAddRow = () => {
    setOutboundFormRows([...outboundFormRows, {
      first_name: '',
      last_name: '',
      dob: '',
      email: '',
      passport_number: '',
      ccode: '',
      mobile_number: '',
      gender_value: '',
      marital_status_value: '',
      nationality: '',
      nationality_id: '',
      declaration: false,
      relation_id: 5,
      relation_value: "Principal",
      gender_id: '',
      marital_status_id: '',
      isValidEmail: '',
      isValidDob: '',
      isValidPassport: '',
      isValidFirstName: true,
      isValidLastName: true,
      isValidNationality: true,
      isValidMobilenumber: true,
      isValidGender: true,
      isValidMarried: true,
      isValid1dob: true,
      isValidEmail1: true,
      isValidPassport1: true,

    }]);
  };

  const handleOutboundRemoveRow = (index) => {
    setOutboundFormRows(outboundFormRows.filter((_, i) => i !== index));
  };

  // const handlecOptionChange = (event, extraValue) => {
  //

  //   const selectedValue = event.target.value;
  //   const selectOptionId = event.target.selectedIndex;

  //   setSelectedOutboundCoverageOptionid(selectOptionId);
  //   setSelectedOutboundCoverageOption(selectedValue);
  //   const parsedInt = parseInt(selectOptionId);
  //   setPackageData(outboundProductData[parsedInt].packages);


  // };


  const [appendsValuesid, setAppendsValuesid] = useState([]);
  const [appendedValuesid, setAppendedValuesid] = useState(['130']);
  const [appendedValues, setAppendedValues] = useState(['Oman']); // State to store appended values


  // Function to handle select change
  const handlefromcountryChange = (value) => {

    // alert(value);

    const [description, id] = value.split("#");

    const isValueInArray = appendedValues.includes(description);

    if (!isValueInArray) {
      setAppendedValues([...appendedValues, description]);
      setAppendedValuesid([...appendedValuesid, id]);
    }

  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('index', index.toString());
  };


  const handleDrop = (e, newIndex) => {

    if (newIndex !== 0) {

      const draggedIndex = parseInt(e.dataTransfer.getData('index'));
      const updatedValues = [...appendedValues];
      const updatedValuesid = [...appendedValuesid];
      const draggedItem = updatedValues[draggedIndex];
      const draggedItemid = updatedValuesid[draggedIndex];

      // alert(newIndex);

      // Remove the dragged item from its original position
      updatedValues.splice(draggedIndex, 1);
      updatedValuesid.splice(draggedIndex, 1);

      // Insert the dragged item at the new index
      updatedValues.splice(newIndex, 0, draggedItem);
      updatedValuesid.splice(newIndex, 0, draggedItemid);

      setAppendedValues(updatedValues);
      setAppendedValuesid(updatedValuesid);

    }

  };

  const removeItem = (index) => {
    const updatedItems = [...appendedValues];
    const updatedItemsid = [...appendedValuesid];
    updatedItems.splice(index, 1);
    updatedItemsid.splice(index, 1);
    setAppendedValues(updatedItems);
    setAppendedValuesid(updatedItemsid);
  };




  const formatDate = (dateString) => {
    const [day, month, year] = dateString.split("-");
    const date = new Date(`${year}-${month}-${day}`);
    return date.toISOString().split("T")[0]; // Returns "2024-11-01"
  };


  const [formRows, setFormRows] = useState([{

    first_name: '',
    last_name: '',
    email: '',
    mobile_number: '',
    passport_number: '',
    relation_id: 5,
    relation_value: "Principal",
    dob: '',
    nationality: '',
    nationality_id: '',
    gender_id: '',
    gender_value: '',
    marital_status_id: '',
    marital_status_value: '',
    ccode: '',
    declaration: false

  }]);


  const handleAddRow = () => {
    setFormRows([...formRows, {
      first_name: '',
      last_name: '',
      email: '',
      mobile_number: '',
      passport_number: '',
      relation_id: 5,
      relation_value: "Principal",
      dob: '',
      nationality: '',
      nationality_id: '',
      gender_id: '',
      gender_value: '',
      marital_status_id: '',
      marital_status_value: '',
      ccode: '',
      declaration: false
    }]);
  };


  const handleRemoveRow = (index) => {
    const rows = [...formRows];
    rows.splice(index, 1);
    const valuesList = rows.map((item) => `${item.nationality}#${item.nationality_id}`);
    setSelectedOutboundOption(valuesList);

    // const dobList = rows.map((item) => item.dob==''?item.dob: format(new Date(item.dob), 'yyyy-dd-MM'));
    // setSelectedDob(dobList);

    // const dobList = rows
    //   .filter((item) => item.dob) // This filters out items with null or undefined dob
    //   .map((item) => format(new Date(item.dob), 'yyyy-dd-MM'));
    //   setSelectedDob(dobList);
    // rows[index][name] = format(new Date(value), 'dd-MM-yyyy');
    setFormRows(rows);
  };


  const handleSubmit = async (e) => {

    e.preventDefault();



  }


  const handleChange1 = (field, value) => {
    setPersonalInfo((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };
  const getOutboundData = async (vendor_key, insuranceToken) => {

    var tokenData = { token: insuranceToken };
    // Get Gender
    var outbound_gender_response = await fetch(`${base_url}/getsanad_outbound_gender`, { method: 'POST', headers: { 'Authorization': `Bearer ${vendor_key}`, 'Content-Type': 'application/json' }, body: JSON.stringify(tokenData) });
    var outbound_gender_response_data = await outbound_gender_response.json();
    if (outbound_gender_response_data.message == 'success') {

      if (outbound_gender_response_data?.result?.data.response_data) {
        setOutboundGender(outbound_gender_response_data.result.data.response_data);
      }
    }
    // Get martial
    var outbound_marital_response = await fetch(`${base_url}/getsanad_outbound_marriedstatus`, { method: 'POST', headers: { 'Authorization': `Bearer ${vendor_key}`, 'Content-Type': 'application/json' }, body: JSON.stringify(tokenData) });
    var outbound_marital_response_data = await outbound_marital_response.json();
    if (outbound_marital_response_data.message == 'success') {

      if (outbound_marital_response_data?.result?.data.response_data) {
        setOutboundMarital(outbound_marital_response_data.result.data.response_data);
      }
    }
    // Get Products

    var outbound_product_response = await fetch(`${base_url}/getsanad_outbound_Productindividual`, { method: 'POST', headers: { 'Authorization': `Bearer ${vendor_key}`, 'Content-Type': 'application/json' }, body: JSON.stringify(tokenData) });
    var outbound_product_response_data = await outbound_product_response.json();

    if (outbound_product_response_data.message == 'success') {

      if (outbound_product_response_data?.result?.data) {
        setOutboundProductData(outbound_product_response_data.result.data);
      }
    }
    // Get Countries
    var outbound_countries_response = await fetch(`${base_url}/getsanad_outbound_contries`, { method: 'POST', headers: { 'Authorization': `Bearer ${vendor_key}`, 'Content-Type': 'application/json' }, body: JSON.stringify(tokenData) });
    var outbound_countries_response_data = await outbound_countries_response.json();
    if (outbound_countries_response_data.message == 'success') {
      if (outbound_countries_response_data?.result?.data?.response_data
      ) {
        setOutboundCountryData(outbound_countries_response_data.result.data.response_data
        );
      }

    }

    // Get Questions
    var outbound_question_response = await fetch(`${base_url}/getsanad_outbound_questions`, { method: 'POST', headers: { 'Authorization': `Bearer ${vendor_key}`, 'Content-Type': 'application/json' }, body: JSON.stringify(tokenData) });
    var outbound_question_response_data = await outbound_question_response.json();

    if (outbound_question_response_data.message == 'success') {
      if (outbound_question_response_data?.result?.data
      ) {
        setOutboundQuestionData(outbound_question_response_data.result.data
        );
      }
    }
  }

  useEffect(() => {
    const postvData = { organization_name: "Superjet" };
    const loadTravelOutBoundInsuranceData = async () => {
      try {
        var vendor_key = null;
        var travel_insurance_token = null;
        setLoading(true);
        const organization_response = await fetch(`${base_url}/create_organization`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(postvData) });
        const organization_response_data = await organization_response.json();
        if (organization_response_data.message == 'success') {
          vendor_key = organization_response_data.result[0].vendor_key;
          setOutboundVendorKey(vendor_key);
          var travel_insurance_token_response = await fetch(`${base_url}/create_sanad_personal_accident_token`, { method: 'GET', headers: { 'Authorization': `Bearer ${vendor_key}` } });
          const travel_insurance_token_response_data = await travel_insurance_token_response.json();
          if (travel_insurance_token_response_data.message == 'success') {
            travel_insurance_token = travel_insurance_token_response_data?.result?.data?.token;
            setOutboundTravelInsuranceToken(travel_insurance_token);
            if (travel_insurance_token) {
              var token_validation_response = await fetch(`${base_url}/validate_dhofar_access_token`, { method: 'GET', headers: { 'Authorization': `${travel_insurance_token}`, 'Content-Type': 'application/json' } });
              const token_validation_response_data = await token_validation_response.json();
              if (token_validation_response_data.message == 'success') {
                await getOutboundData(vendor_key, travel_insurance_token);
                // document.getElementsByName("outbound_traveller")[0].focus();

                var ele = document.getElementsByName("outbound_traveller")[0].focus()
              } else {
                window.alert('Session Timed Out');
                window.location.href = '/';
              }

            }

          }

        }
        // setVendorKey(data.result?.[0]?.vendor_key || null);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error('Error posting data:', error);
      }
    };

    loadTravelOutBoundInsuranceData();

  },[]);



  const validateForm = () => {

    let isValid = true;
    const newErrors = [];
    var outbound_firstname_list = document.getElementsByName('out_first_name');
    var outbound_lastname_list = document.getElementsByName('out_last_name');
    var outbound_dob_list = document.getElementsByName('out_dob');
    var outbound_email_list = document.getElementsByName('out_email');
    var outbound_passport_list = document.getElementsByName("out_passport_number");
    var outbound_nationality_list = document.getElementsByName('out_nationality');
    var outbound_mobile_list = document.getElementsByName('out_mobile_number');
    var outbound_gender_list = document.getElementsByName('out_gender_value');
    var outbound_married_list = document.getElementsByName('out_marital_status_value');

    const updatedRows = [...outboundFormRows];
    for (let index = 0; index < outboundFormRows.length; index++) {
      let row = outboundFormRows[index];
      var fn_ele_value = row.first_name;
      var ln_ele_value = row.last_name;
      var dob_ele_value = row.dob;
      var email_ele_value = row.email;
      var passport_ele_value = row.passport_number;
      var nationality_ele_value = row.nationality;
      var mobile_ele_value = row.mobile_number
      var gender_ele_value = row.gender_value;
      var married_ele_value = row.marital_status_value;
      var declaration_ele_value = row.declaration;

      if (fn_ele_value == '') {
        outbound_firstname_list[index].focus();
        updatedRows[index]['isValidFirstName'] = false;
        setOutboundFormRows(updatedRows);
        isValid = false;
        break;

      }
      else if (ln_ele_value == '') {
        outbound_lastname_list[index].focus();
        updatedRows[index]['isValidLastName'] = false;
        setOutboundFormRows(updatedRows);
        isValid = false;
        break;
      }
      else if (dob_ele_value == '') {
        outbound_dob_list[index].focus();
        updatedRows[index]['isValid1dob'] = false;
        setOutboundFormRows(updatedRows);
        isValid = false;
        break;
      }
      else if (email_ele_value == '') {
        outbound_email_list[index].focus();
        updatedRows[index]['isValidEmail1'] = false;
        setOutboundFormRows(updatedRows);
        isValid = false;

        break;
      }
      else if (passport_ele_value == '') {
        outbound_passport_list[index].focus();
        updatedRows[index]['isValidPassport1'] = false;
        setOutboundFormRows(updatedRows);
        isValid = false;
        break;
      }
      else if (nationality_ele_value == '') {
        alert("Please Select the Nationality");
        updatedRows[index]['isValidNationality'] = false;
        isValid = false;
        setOutboundFormRows(updatedRows);
        break;
      }
      else if (mobile_ele_value == '') {
        outbound_mobile_list[index].focus();
        updatedRows[index]['isValidMobilenumber'] = false;
        isValid = false;
        setOutboundFormRows(updatedRows);
        break;
      }
      else if (gender_ele_value == '') {
        outbound_gender_list[index].focus();
        updatedRows[index]['isValidGender'] = false;
        isValid = false;
        setOutboundFormRows(updatedRows);
        break;
      }
      else if (married_ele_value == '') {

        outbound_married_list[index].focus();
        updatedRows[index]['isValidMarried'] = false;
        isValid = false;
        setOutboundFormRows(updatedRows);
        break;
      } else if (declaration_ele_value == false) {
        alert("Please ensure the declaration");
        isValid = false;
        break;
      }
      // else{
      //   isValid = false;
      //   continue ;
      // }
    }
    return isValid;

    // if(outbound_firstname_list.length > 0){
    //   for(var out_first_name in outbound_firstname_list){
    //     var fn_ele_value = outbound_firstname_list[out_first_name]?.value;
    //     if(fn_ele_value == ''){
    //       outbound_firstname_list[out_first_name].focus();
    //       outbound_firstname_list[out_first_name].style.border = '1px solid red';
    //       return;
    //     }
    //   }
    // }
    //  if(outbound_lastname_list.length > 0){
    //   for(var out_last_name in outbound_lastname_list){
    //     var ln_ele_value = outbound_lastname_list[out_last_name]?.value;
    //     if(ln_ele_value == ''){
    //       outbound_lastname_list[out_last_name].focus();
    //       outbound_lastname_list[out_last_name].style.border = '1px solid red';
    //       return;
    //     }
    //   }
    // }
  };

  const InboundTravelPlan = () => {
    const styles = {
      section: {
        padding: '0px 0px',
      },
      container: {
        // maxWidth: '1200px',
        width: '100%',
        margin: '0 auto',
        padding: '0 20px',
        display: 'flex', // Flexbox layout for left and right columns
        flexWrap: 'wrap', // Allow wrapping for smaller screens
        justifyContent: 'space-between', // Space between the two columns
      },
      leftColumn: {
        flex: '1 1 45%', // Take 45% width, grow/shrink based on space
        marginRight: '10px',
      },
      rightColumn: {
        flex: '1 1 45%', // Take 45% width, grow/shrink based on space
        marginLeft: '10px',
        // marginTop: '8px',
      },
      card: {
        backgroundColor: '#f8f9fa',
        borderRadius: '8px',
        padding: '15px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        transition: 'all 0.3s ease-in-out',
        margin: '10px 0',
        width: '100%',
      },
      option: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
      },
      label: {
        fontSize: '16px',
        color: '#333',
        flex: 1,
        marginTop: '10px'
      },
      radio: {
        width: '20px',
        height: '20px',
        backgroundColor: '#007bff',
        borderRadius: '50%',
        marginLeft: '10px',
      },
      select: {
        width: '100%',
        padding: '12px',
        fontSize: '16px',
        borderRadius: '8px',
        transition: 'opacity 0.5s ease',
        '&:hover': {
          opacity: 0.9,  // Slightly fade the dropdown
          borderColor: '#007bff', // Highlight with blue border on hover
        },
      },
      input: {
        padding: '8px',
        width: '100%',
        borderRadius: '5px',
        border: '1px solid #ccc',
        fontSize: '16px',
        // marginTop: '10px'
        '&:hover': {
          borderColor: '#007bff', // Blue border on hover
          boxShadow: '0 4px 8px rgba(0, 123, 255, 0.2)', // Subtle shadow for focus
        },
      },
      heading: {
        // marginBottom: '10px',
        marginTop: '10px',
        // fontWeight: 'bold',

      }
    };
    const handleValidation = () => {
      if (!selectedInboundCoverageOptionId) {
        setCoverageError("Please select a coverage option.");
        document.getElementsByName("coverageselection")[0].focus();
        return false;
      }
      setCoverageError("");
      return true;
    };
    const handledaysValidation = () => {
      if (!selectedInboundNumberOfDays) {
        setdaysError(lnstr === "en" ? "Please select the number of days." : "يرجى اختيار عدد الأيام.");
        document.getElementsByName("numberofdays")[0].focus();
        return false;
      }
      setdaysError("");
      return true;
    };
    const handleNameBlur = (e) => {
      const { value } = e.target;

      // Regular expression for English letters and spaces
      const englishLetterPattern = /^[a-zA-Z\s]*$/;

      // Check if the name is empty or contains invalid characters
      if (value === '') {
        setFnameError('Please enter the Full Name');
      } else if (!englishLetterPattern.test(value)) {
        setFnameError('Please enter the Full Name, accept english letters only.');
      } else {
        setFnameError('');
      }
    };
    const handleLnameBlur = (e) => {
      const { value } = e.target;

      // Regular expression for English letters and spaces
      const englishLetterPattern = /^[a-zA-Z\s]*$/;

      // Check if the name is empty or contains invalid characters
      if (value === '') {
        setLnameError('Please enter the Last Name');
      } else if (!englishLetterPattern.test(value)) {
        setLnameError('Please enter the Last Name, accept english letters only.');
      } else {
        setLnameError('');
      }
    };

    const handleEmailBlur = (e) => {
      const { value } = e.target;

      // Regular expression to validate a basic email format
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      // Check if the email matches the pattern
      if (!emailPattern.test(value)) {
        setEmailError('Please enter the email address. Example: example@domain.com');
      } else {
        setEmailError(''); // Clear the error if valid
      }
    };

    const handlePassportBlur = (e) => {
      const { value } = e.target;

      // Regular expression to allow only letters and numbers
      const passportPattern = /^[a-zA-Z0-9]*$/;

      // Check if the Passport Number matches the pattern
      if (!passportPattern.test(value)) {
        setpassportError('Please enter valid passport number, it accept numbers & letters only.');
      } else {
        setpassportError('');
      }
    };
    const parseDate = (dateString) => {
      const [day, month, year] = dateString.split('-').map(Number);
      return new Date(year, month - 1, day); // month is 0-indexed
    };
    const calculateAge = (dob) => {
      const today = new Date();
      const birthDate = parseDate(dob);

      // Check if birthDate is valid
      if (isNaN(birthDate.getTime())) {
        // throw new Error('Invalid date');
      }

      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDifference = today.getMonth() - birthDate.getMonth();

      // Adjust age if the birth date hasn't occurred yet this year
      if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }

      return age;
    };
    const [age, setAge] = useState(null);
    const buttonDisabled = !selectedInboundCoverageOptionId || !selectedInboundNumberOfDays || !selectedInboundFromCountryOption || !selectedstartdOption || selectedstartdOption < formattedDate || personalInfo.firstName == '' || personalInfo.lastName == '' || personalInfo.dob == '' || age < 0 || personalInfo.email == '' || emailerror !== "" || personalInfo.passportNumber == '' || !selectedInboundNationality || personalInfo.phone == '' || !selectedgenderOptionid || !selectedMaritalOptionid || !isInboundPersonalDeclarationChecked


    const buttonDisabled1 = !selectedInboundCoverageOptionId || !selectedInboundNumberOfDays || !selectedInboundFromCountryOption || !selectedstartdOption || selectedstartdOption < formattedDate || personalInfo.firstName == '' || personalInfo.lastName == '' || personalInfo.dob == '' || age < 0 || personalInfo.email == '' || personalInfo.passportNumber == '' || !selectedInboundNationality || personalInfo.phone == '' || !selectedgenderOptionid || !selectedMaritalOptionid || !isInboundPersonalDeclarationChecked || !selectedInboundQuestionOption || isSignatureEmpty || !isInboundfinalChecked

    return (
      <div style={styles.section}>
        <div style={{ display: 'flex', paddingBottom: '20px' }}>
                        <button style={{ width: '100px', fontWeight: 'bold' }} onClick={() => {
                            const regex = /^(ftp|http|https):\/\/[^ "]+$/;
                            var isValidUrl = regex.test(sanadBackUrl);
                            if (isValidUrl) {
                                window.location.href = sanadBackUrl;
                            } else {
                                window.location.reload();
                            }

                        }} >Back</button>
                    </div>
        {!loading && inboundpage && (
          <div style={styles.container}>
            {/* Left Column */}

            <div style={styles.card}>
              <h4 style={styles.heading}>
                <span><strong>{lnstr === 'en' ? 'Travel Inbound' : 'السفر إلى الداخل'}</strong></span>
              </h4>
              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                <div style={styles.leftColumn}>
                  <div style={styles.card}>
                    <div style={styles.option}>
                      {inboundProductData?.productDescription}
                      <input type="radio" name="travelplan" checked style={styles.radio} />
                    </div>
                  </div>

                  <label style={styles.label}>Coverage Selection<span style={{ color: 'red', marginLeft: '7px' }}>*</span></label>
                  {/* <h4 style={styles.heading}>
                {lnstr === 'en' ? 'Coverage Selection' : 'اختيار التغطية'}<span style={{color:'red', marginLeft:'7px'}}>*</span>
              </h4> */}
                  <select
                    // style={styles.select}
                    className="form-control"
                    onBlur={handleValidation}
                    onChange={(event) => {

                      const selectedOption = inboundProductData?.packages.find(
                        (option) => option.coverageArea.id.toString() === event.target.value.toString()
                      );
                      // if (selectedOption) {
                      //
                      //   setSelectedInboundCoverageOptionId(selectedOption.coverageArea.id);
                      //   setSelectedInboundCoverageOption(selectedOption.coverageArea.name);
                      // }
                      if (selectedOption) {
                        setSelectedInboundCoverageOptionId(selectedOption.coverageArea.id);
                        setSelectedInboundCoverageOption(selectedOption.coverageArea.name);
                        setDone(false);
                        SetshowPlan(false);
                        setCoverageError("");
                      } else {
                        setSelectedInboundCoverageOptionId(null);
                        setSelectedInboundCoverageOption("");
                      }
                    }}
                    value={selectedInboundCoverageOptionId}
                    name="coverageselection"
                  >
                    <option value="">
                      {lnstr === 'en' ? '-- Select Coverage --' : '--اختر التغطية--'}
                    </option>
                    {inboundProductData?.packages.map((option) => (
                      <option key={option.coverageArea.id} value={option.coverageArea.id}>
                        {option.coverageArea.name}
                      </option>
                    ))}
                  </select>
                  {coverageerror && <p style={{ color: "red", marginTop: "5px" }}>{coverageerror}</p>}
                  <label style={styles.label}>Number Of Days<span style={{ color: 'red', marginLeft: '7px' }}>*</span></label>
                  {/* <h4 style={styles.heading}>{lnstr === 'en' ? 'Number Of Days' : 'عدد الأيام'}<span style={{color:'red', marginLeft:'7px'}}>*</span></h4> */}
                  <select
                    // style={styles.select}
                    className="form-control"
                    onBlur={handledaysValidation}
                    value={selectedInboundNumberOfDays}
                    disabled={isFormFrozen}
                    onChange={(event) => {
                      setSelectedInboundNumberOfDays(event.target.value);
                      setDone(false);
                      SetshowPlan(false);
                      setdaysError("");
                    }}
                    name="numberofdays"
                  >
                    <option value="">{lnstr === 'en' ? '-- Select --' : '--اختار--'}</option>
                    <option value="30">30</option>
                    <option value="60">60</option>
                    <option value="90">90</option>
                  </select>
                  {dayserror && <p style={{ color: "red", marginTop: "5px" }}>{dayserror}</p>}
                </div>

                {/* Right Column */}
                <div style={styles.rightColumn}>
                  <label style={styles.label}>Select From Country<span style={{ color: 'red', marginLeft: '7px' }}>*</span></label>
                  {/* <h4 className="px-0 py-2 mt-3">
                {lnstr === 'en' ? 'Select From Country' : 'اختر من البلد'}<span style={{color:'red', marginLeft:'7px'}}>*</span>
              </h4> */}
                  <Select2Wrapper

                    value={selectedInboundFromCountryOption}
                    options={countryData}
                    disabled={isFormFrozen}
                    className="form-control"
                    onChange={async (value) => {
                      const [description, id] = value.split("#");
                      setSelectedInboundFromCountryOptionid(id);
                      setSelectedInboundFromCountry(description);
                      setSelectedInboundFromCountryOption(value);
                      handleChange('nationality', description);
                      setSelectedInboundNationalityid(id);
                      setSelectedInboundNationalityOption(value);
                      setSelectedInboundNationality(description);
                      setDone(false);
                      SetshowPlan(false);
                      setCountryError("");
                      const cnData = { country: description };

                      await fetch(base_url + '/get_country_code', {
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(cnData),
                      })
                        .then(response => response.json())
                        .then(data => {
                          //

                          const cde = data.result.replace(/\+/g, '');

                          setphoneaValue(cde);

                        })
                        .catch(error => {
                          console.error('Error posting data:', error);
                        });

                    }}
                    required
                    style={{ padding: 20 }}
                  />
                  {countryserror && <p style={{ color: "red", marginTop: "5px" }}>{countryserror}</p>}

                  <label style={styles.label}>Start Date<span style={{ color: 'red', marginLeft: '7px' }}>*</span></label>
                  {/* <h4 className="px-0 py-2 mt-3">
                {lnstr === 'en' ? 'Start Date' : 'تاريخ البدء'}<span style={{color:'red', marginLeft:'7px'}}>*</span>
              </h4> */}
                  <input
                    type="date"
                    min={formattedDate}
                    value={selectedstartdOption}
                    readOnly={isFormFrozen}
                    name="insurancestartdate"
                    className="form-control"
                    onChange={(event) => {
                      const selectedDate = event.target.value;
                      setSelectedstartdOption(selectedDate);
                      setDone(false);
                      SetshowPlan(false);

                      if (selectedDate < formattedDate) {
                        var ele_startdate = document.getElementsByName("insurancestartdate")[0];
                        if (ele_startdate) {
                          ele_startdate.style.border = "1px solid blue";
                        }
                        setDateError("Please select a future date.");
                      } else {
                        var ele_startdate = document.getElementsByName("insurancestartdate")[0];
                        if (ele_startdate) {
                          ele_startdate.style.border = "";
                        }
                        setDateError("");
                      }
                      // setSelectedstartdOption(event.target.value);
                    }}
                    style={styles.input}
                  />
                  {dateerror && <p style={{ color: "red", marginTop: "5px" }}>{dateerror}</p>}

                </div>
              </div>
            </div>

            {/* Personal Information Section */}
            <div style={{ width: '100%' }}>
              <div style={styles.card}>
                <h4 style={{ ...styles.heading, textAlign: 'left' }}>
                  <strong>{lnstr === 'en' ? 'Personal Information' : 'المعلومات الشخصية'}</strong>
                </h4>
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                  {/* Left Column */}
                  <div style={styles.leftColumn}>
                    <label style={styles.label}>First Name<span style={{ color: 'red', marginLeft: '7px' }}>*</span></label>
                    <input
                      type="text"
                      name="firstName"
                      // style={styles.input}
                      className="form-control"
                      readOnly={isFormFrozen}
                      value={personalInfo.firstName}
                      onChange={(e) => {
                        handleChange('firstName', e.target.value);
                        setDone(false);
                        SetshowPlan(false);
                        setFnameError("");
                      }}
                      // onBlur={handleNameBlur}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, '');
                      }}
                    // onInput={(e) => {
                    //   const value = e.target.value;
                    //   handleChange('firstName', value);
                    //   setErrors((prev) => ({
                    //     ...prev,
                    //     firstName: /^[A-Za-z]+$/.test(value)
                    //       ? ''
                    //       : 'First name must contain only letters',
                    //   }));
                    // }}
                    />
                    {fnameerror && <p style={{ color: "red", marginTop: "5px" }}>{fnameerror}</p>}
                    {/* {errors.firstName && <div style={styles.error}>{errors.firstName}</div>} */}

                    <label style={styles.label}>Last Name<span style={{ color: 'red', marginLeft: '7px' }}>*</span></label>
                    <input
                      type="text"
                      name="lastName"
                      // style={styles.input}
                      className="form-control"
                      readOnly={isFormFrozen}
                      value={personalInfo.lastName}
                      // onBlur={handleLnameBlur}
                      onChange={(e) => {
                        handleChange('lastName', e.target.value);
                        setDone(false);
                        SetshowPlan(false);
                        setLnameError("");
                      }}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, '');
                      }}
                    // onInput={(e) => {
                    //   const value = e.target.value;
                    //   handleChange('lastName', value);
                    //   setErrors((prev) => ({
                    //     ...prev,
                    //     lastName: /^[A-Za-z]+$/.test(value)
                    //       ? ''
                    //       : 'Last name must contain only letters',
                    //   }));
                    // }}
                    />
                    {lnameerror && <p style={{ color: "red", marginTop: "5px" }}>{lnameerror}</p>}
                    {/* {errors.lastName && <div style={styles.error}>{errors.lastName}</div>} */}

                    <label style={styles.label}>Date of Birth<span style={{ color: 'red', marginLeft: '7px' }}>*</span></label>
                    <input
                      type="date"
                      name="dob"
                      // style={styles.input}
                      className="form-control"
                      readOnly={isFormFrozen}
                      value={personalInfo.dob}
                      max={new Date().toISOString().split('T')[0]}
                      onChange={(e) => {
                        const enteredDate = e.target.value;
                        const todayDate = new Date().toISOString().split('T')[0];
                        const formattedDate = e.target.value.replace(/\//g, '-');
                        const [year, month, day] = formattedDate.split("-");
                        const finalformattedDate = `${day}-${month}-${year}`;

                        if (enteredDate > todayDate) {
                          setDobError("Future dates are not allowed.");
                          e.target.value = todayDate; // Reset the value to today if it's in the future
                        } else {
                          setDobError("");
                        }
                        const age = calculateAge(finalformattedDate);
                        setAge(age);

                        if (formattedDate > formattedDate1) {
                          setDobError("Please select a future date.");
                        } else if (age > 85) {
                          setDobError('Age must be within 0 to 85 years.');
                        } else {
                          setDobError("");
                        }

                        if (age < 0) {
                          setDobError('Your Age is invalid');

                        }
                        if (age >= 0) {
                          var ele_dob = document.getElementsByName("dob")[0];
                          setDobError('');

                        }
                      }
                      }
                      onInput={(e) => {
                        const value = e.target.value;
                        handleChange('dob', value);
                        setDone(false);
                        SetshowPlan(false);
                        setErrors((prev) => ({
                          ...prev,
                          dob: value ? '' : 'Date of Birth is required',
                        }));
                      }}
                    />
                    {doberror && <p style={{ color: "red" }}>{doberror}</p>}
                    {/* {errors.dob && <div style={styles.error}>{errors.dob}</div>} */}

                    <label style={styles.label}>Email<span style={{ color: 'red', marginLeft: '7px' }}>*</span></label>
                    <input
                      type="email"
                      name="email"
                      // style={styles.input}
                      className="form-control"
                      value={personalInfo.email}
                      onChange={(e) => {
                        handleChange('email', e.target.value)
                        setDone(false);
                        SetshowPlan(false);
                        setEmailError("");
                      }}
                      onBlur={handleEmailBlur}
                    // onInput={(e) => {
                    //   const value = e.target.value;
                    //   handleChange('email', value);
                    //   setErrors((prev) => ({
                    //     ...prev,
                    //     email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
                    //       ? ''
                    //       : 'Please enter a valid email address',
                    //   }));
                    // }}
                    />
                    {emailerror && <p style={{ color: "red", marginTop: "5px" }}>{emailerror}</p>}
                    {/* {errors.email && <div style={styles.error}>{errors.email}</div>} */}
                    <label style={styles.label}>Passport Number<span style={{ color: 'red', marginLeft: '7px' }}>*</span></label>
                    <input
                      type="text"
                      name="passportNumber"
                      // style={styles.input}
                      className="form-control"
                      pattern="[A-Za-z0-9]+"
                      readOnly={isFormFrozen}
                      value={personalInfo.passportNumber}
                      onInput={(e) => {
                        const value = e.target.value;
                        handleChange('passportNumber', value);
                        setDone(false);
                        SetshowPlan(false);
                        setpassportError("");
                        setErrors((prev) => ({
                          ...prev,
                          passportNumber: /^[A-Za-z0-9]+$/.test(value)
                            ? ''
                            : 'Passport number must be alphanumeric',
                        }));
                      }}
                      onBlur={(e) => handlePassportBlur(e)}
                    />
                    {/* {errors.passportNumber && <div style={styles.error}>{errors.passportNumber}</div>} */}
                    {passporterror && <p style={{ color: "red" }}>{passporterror}</p>}

                  </div>

                  {/* Right Column */}
                  <div style={styles.rightColumn}>
                    {/* <label style={styles.label}>Nationality</label>
                  <input
                    type="text"
                    style={styles.input}
                    value={personalInfo.nationality}
                    onInput={(e) => handleChange('nationality', e.target.value)}
                  /> */}

                    <label style={styles.label}>Nationality<span style={{ color: 'red', marginLeft: '7px' }}>*</span></label>

                    {/* <h4 className="px-0 py-2 mt-3">
                    {lnstr === 'en' ? 'Nationality' : 'اختر من البلد'}<span style={{color:'red', marginLeft:'7px'}}>*</span>
                  </h4> */}
                    <Select2Wrapper
                      name="nationality"
                      value={selectedInboundFromCountryOption}
                      options={countryData}
                      disabled={isFormFrozen}
                      className="form-control"
                      onChange={async (value) => {

                        const [description, id] = value.split("#");
                        setSelectedInboundNationalityid(id);
                        setSelectedInboundNationalityOption(value);
                        setSelectedInboundNationality(description);
                        handleChange('nationality', description);
                        setDone(false);
                        SetshowPlan(false);

                        const cnData = { country: description };

                        await fetch(base_url + '/get_country_code', {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json',
                          },
                          body: JSON.stringify(cnData),
                        })
                          .then(response => response.json())
                          .then(data => {
                            //

                            const cde = data.result.replace(/\+/g, '');

                            setphoneaValue(cde);

                          })
                          .catch(error => {
                            console.error('Error posting data:', error);
                          });

                      }}
                      required
                      style={{ padding: 20 }}
                    />
                    {nationalityerror && <p style={{ color: "red", marginTop: "5px" }}>{nationalityerror}</p>}
                    <label style={styles.label}>Phone<span style={{ color: 'red', marginLeft: '7px' }}>*</span></label>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '1px' }}>
                      <input
                        type="text"
                        value={phoneaValue}
                        style={{ ...styles.input, width: '100px' }}
                        readOnly
                      />
                      <input
                        type="text"
                        name="phone"
                        maxLength={12}
                        // style={styles.input}
                        className="form-control"
                        readOnly={isFormFrozen}
                        value={personalInfo.phone}
                        onChange={(e) => {
                          handleChange('phone', e.target.value);
                          setDone(false);
                          SetshowPlan(false);
                          setPhoneError("");
                        }}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        }}
                      />
                    </div>
                    {/* {errors.phone && <div style={styles.error}>{errors.phone}</div>} */}
                    {phoneerror && <p style={{ color: "red", marginTop: "5px" }}>{phoneerror}</p>}
                    <label style={styles.label}>Gender<span style={{ color: 'red', marginLeft: '7px' }}>*</span></label>
                    <select
                      // style={styles.input}
                      className="form-control"
                      disabled={isFormFrozen}
                      value={personalInfo.gender}
                      name="gender"
                      // value={personalInfo.gender}
                      // onChange={(e) => handleChange('gender', e.target.value)}
                      onChange={(event) => {

                        setSelectedgenderOptionid(event.target.options[event.target.selectedIndex].id);
                        setSelectedgenderOption(event.target.value);
                        handleChange('gender', event.target.value);
                        setDone(false);
                        SetshowPlan(false);
                        setGenderError("");
                      }}
                    >
                      <option value="">{(lnstr === 'en') ? '-- Select --' : '--اختار--'}</option>
                      {goptions && goptions.map((opt) =>
                        <option key={opt.id} id={opt.id} value={opt.description}>{t(opt.description)}</option>
                      )}
                    </select>
                    {gendererror && <p style={{ color: "red", marginTop: "5px" }}>{gendererror}</p>}

                    <label style={styles.label}>Marital Status<span style={{ color: 'red', marginLeft: '7px' }}>*</span></label>
                    <select
                      // style={styles.input}
                      className="form-control"
                      disabled={isFormFrozen}
                      value={personalInfo.maritalStatus}
                      name="maritalStatus"
                      // value={personalInfo.maritalStatus}
                      onChange={(event) => {

                        setSelectedMaritalOptionid(event.target.options[event.target.selectedIndex].id);
                        setSelectedMaritalOption(event.target.value);
                        handleChange('maritalStatus', event.target.value);
                        setDone(false);
                        SetshowPlan(false);
                        setMaritalError("");
                      }}
                    // onChange={(e) => handleChange('maritalStatus', e.target.value)}
                    >
                      <option value="">{(lnstr === 'en') ? '-- Select --' : '--اختار--'}</option>
                      {moptions && moptions.map((mopt) =>
                        <option key={mopt.id} id={mopt.id}
                          value={mopt.description}>{t(mopt.description)}</option>
                      )}
                    </select>
                    {maritalstatuserror && <p style={{ color: "red", marginTop: "5px" }}>{maritalstatuserror}</p>}

                  </div>


                  <p className={lnstr}>
                    {(lnstr === 'en') ? 'We use this to create your insurance and send you updates about your application'
                      :
                      'نستخدم هذه المعلومات لإنشاء تأمينك وإرسال تحديثات حول طلبك'}
                  </p>

                  <p className={lnstr}><input type="checkbox" readOnly={isFormFrozen} checked={isInboundPersonalDeclarationChecked}
                    onChange={(event) => {
                      setIsInboundPersonalDeclarationChecked(event.target.checked);
                    }} name="note" />
                    {(lnstr === 'en') ? ' I want to receive insurance updates, product launches and personalized offers. I can opt out anytime.'
                      :
                      'أرغب في تلقي تحديثات التأمين، وإطلاقات المنتجات، والعروض الشخصية. يمكنني الانسحاب في أي وقت.'}
                  </p>
                </div>
              </div>

              <div style={{ marginTop: '30px', width: '100%' }}>
                {/* <h4 style={{ ...styles.heading, textAlign: 'left' }}>
                {lnstr === 'en' ? 'Personal Information' : 'المعلومات الشخصية'}
              </h4> */}

                {!done && (
                  <button
                    type="button"
                    // style={styles.button}
                    style={{ backgroundColor: !buttonDisabled ? '#007bff' : 'grey', borderColor: '#007bff', marginTop: '0px' }}
                    // onClick={() => setShowValue((prev) => !prev)}
                    onClick={async () => {

                      setCountryError("");
                      setCoverageError("");
                      setdaysError("");
                      setCountryError("");
                      setDateError("");
                      setFnameError("");
                      setLnameError("");
                      setDobError("");
                      setEmailError("");
                      setpassportError("");
                      setNationalityError("");
                      setPhoneError("");
                      setGenderError("");
                      setMaritalError("");

                      console.log(personalInfo);
                      if (!selectedInboundCoverageOptionId) {
                        setCoverageError(lnstr === "en" ? "Please select a coverage option." : "الرجاء تحديد خيار التغطية.");
                        document.getElementsByName("coverageselection")[0].focus();
                        var ele_firstname = document.getElementsByName("coverageselection")[0];


                      } else if (!selectedInboundNumberOfDays) {
                        setdaysError(lnstr === "en" ? "Please select the number of days." : "يرجى اختيار عدد الأيام.");
                        document.getElementsByName("numberofdays")[0].focus();
                        var ele_numberdays = document.getElementsByName("numberofdays")[0];

                      } else if (!selectedInboundFromCountryOption) {
                        alert("Please select a country.");
                        setCountryError("Please select a country.");
                        // document.getElementsByName("insurancefromcountry")[0].focus();

                      } else if (!selectedstartdOption) {
                        document.getElementsByName("insurancestartdate")[0].focus();
                        var ele_startdate = document.getElementsByName("insurancestartdate")[0];

                        setDateError("Please select a Start Date.");

                      } else if (selectedstartdOption < formattedDate) {
                        document.getElementsByName("insurancestartdate")[0].focus();
                        var ele_startdate = document.getElementsByName("insurancestartdate")[0];

                        setDateError("Please select a Valid Start Date.");
                      } else if (personalInfo.firstName == '') {
                        document.getElementsByName("firstName")[0].focus();
                        var ele_firstname1 = document.getElementsByName("firstName")[0];

                        setFnameError("Please enter the First name");
                      } else if (personalInfo.lastName == '') {
                        document.getElementsByName("lastName")[0].focus();
                        var ele_lastname = document.getElementsByName("lastName")[0];

                        setLnameError("Please enter the last name");

                      } else if (personalInfo.dob == '') {
                        document.getElementsByName("dob")[0].focus();
                        var ele_dob = document.getElementsByName("dob")[0];

                        setDobError("Please enter the Date of Birth");

                      } else if (age < 0) {
                        document.getElementsByName("dob")[0].focus();
                        var ele_dob = document.getElementsByName("dob")[0];

                        setDobError("Age must be within 0 to 85 years.");

                      } else if (age > 85) {
                        document.getElementsByName("dob")[0].focus();
                        var ele_dob = document.getElementsByName("dob")[0];

                        setDobError("Age must be within 0 to 85 years.");

                      } else if (personalInfo.email == '') {
                        document.getElementsByName("email")[0].focus();
                        var ele_email = document.getElementsByName("email")[0];

                        setEmailError("Please enter the email address. Example: example@domain.com");

                      } else if (emailerror !== "") {
                        document.getElementsByName("email")[0].focus();
                        var ele_email = document.getElementsByName("email")[0];

                        setEmailError('Please enter the email address. Example: example@domain.com')
                      } else if (personalInfo.passportNumber == '') {
                        document.getElementsByName("passportNumber")[0].focus();
                        var ele_passportNumber = document.getElementsByName("passportNumber")[0];

                        setpassportError("Please enter the Passport Number ");

                      } else if (!selectedInboundNationality) {
                        alert("Please Select a Nationality");
                        document.getElementsByName("passportNumber")[0].focus();
                        setNationalityError("Please Select a Nationality ");

                      } else if (personalInfo.phone == '') {
                        document.getElementsByName("phone")[0].focus();
                        var ele_phone = document.getElementsByName("phone")[0];

                        setPhoneError("Please enter the Phone no");

                      } else if (!selectedgenderOptionid) {
                        document.getElementsByName("gender")[0].focus();
                        var ele_gender = document.getElementsByName("gender")[0];

                        setGenderError("Please Select a Gender ");
                      } else if (!selectedMaritalOptionid) {
                        document.getElementsByName("maritalStatus")[0].focus();
                        var ele_maritalStatus = document.getElementsByName("maritalStatus")[0];

                        setMaritalError("Please select a Marital Status");
                      } else if (!isInboundPersonalDeclarationChecked) {
                        alert("Please ensure the declaration");

                      } else {
                        const fnum = phoneaValue + '' + personalInfo.phone;

                        const desiredLength = 12;

                        const padToTwelveDigits = (number) => {
                          const zerosToAdd = desiredLength - number.length;

                          if (zerosToAdd < 0) {
                            const pn = personalInfo.phone;

                            const zToAdd = desiredLength - pn.length;

                            const paddedNumber = '0'.repeat(zToAdd) + pn;

                            return paddedNumber;
                          }
                          else {
                            const paddedNumber = '0'.repeat(zerosToAdd) + number;

                            return paddedNumber;
                          }



                        };

                        const twelveDigitNumber = padToTwelveDigits(fnum);
                        setPlanLoading(true);
                        var QoutataionData = {

                          "nationality": selectedInboundNationality,
                          "nationalityCode": selectedInboundNationalityid,
                          "inbound_gender_id": selectedgenderOptionid,
                          "inbound_gender_detail": selectedgenderOption,
                          "inbound_marital_status_id": selectedMaritalOptionid,
                          "inbound_marital_status_detail": selectedMaritelOption,
                          "inbound_product_type_id": "2",
                          "inbound_product_type_name": inboundProductData.type.description,
                          "inbound_product_id": inboundProductData.productId,
                          "inbound_product_name": inboundProductData.productDescription,
                          "inbound_coverage_id": selectedInboundCoverageOptionId,
                          "inbound_coverage_name": selectedInboundCoverageOption,
                          "inbound_country_id": selectedInboundFromCountryOptionid,
                          "inbound_country_name": selectedInboundFromCountry,
                          "inbound_period_type_id": 1,
                          "inbound_period_value_id": 6,
                          "cumulative_days": selectedInboundNumberOfDays,
                          "inbound_travel_start_date": selectedstartdOption,
                          'first_name': personalInfo.firstName,
                          'last_name': personalInfo.lastName,
                          'email': personalInfo.email,
                          'mobileNumber': twelveDigitNumber,
                          'passport': personalInfo.passportNumber,
                          'dob': personalInfo.dob
                        };
                        console.log(QoutataionData);
                        const getQuotationData = [{ token: travelInsuranceToken }, QoutataionData];
                        var inbound_qoutation_response = await fetch(`${base_url}/getsanadtravel_inbound_qutations`, { method: 'POST', headers: { 'Authorization': `Bearer ${vendorKey}`, 'Content-Type': 'application/json' }, body: JSON.stringify(getQuotationData) });
                        var inbound_qoutation_response_data = await inbound_qoutation_response.json();

                        // setLoading(false);
                        if (inbound_qoutation_response_data.message == 'success') {

                          setInboundPlanDetails(inbound_qoutation_response_data.result.data);
                          SetshowPlan(true);
                          setDone(true);
                          setPlanLoading(false);
                          //  console.log(personalInfo);
                          //
                          // if (inbound_product_response_data?.result?.data[0]) {
                          //   setInboundProductData(inbound_product_response_data.result.data[0]);
                          // }
                        } else {

                          if (inbound_qoutation_response_data.result.message == 'The dob field is mandatory.') {
                            window.alert('Please Select Date of Birth');
                            setPlanLoading(false);
                          }
                        }

                      }

                    }
                    }
                  >
                    <strong>{lnstr === "en" ? "View Plan Details" : "عرض"}</strong>
                  </button>

                )}

              </div>




              {
                showplan && (
                  <div>
                    <h3>Plan Details :<span> {inboundPlanDetails?.amount.base_price} OMR</span></h3>
                  </div>
                )}

              {/* <AnimatePresence>

                {
                  inboundPlanDetails && (
                    <motion.div
                      style={styles.motionDiv}
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -10 }}
                      transition={{ duration: 0.3 }}
                    >
                      {inboundPlanDetails?.amount.base_price} OMR
                    </motion.div>
                  )}
              </AnimatePresence> */}

              <div style={styles.card}>
                <h4 className="px-0 py-2">
                  <strong>Question</strong>
                </h4>

                {inboundQuestionData && <div style={{ marginTop: '20px' }}>





                  <p>1.{inboundQuestionData[0]?.English}</p>

                  <Row style={{ direction: 'ltr' }}>
                    <Col md={4}>
                      <div class="align-items-center travel-selection-option">
                        {(lnstr === 'en') ? 'YES' : 'نعم'}
                        <input type="radio" readOnly={isFormFrozen} name="questionans" value="Yes" onChange={(event) => {
                          setSelectedInboundQuestionOption(event.target.value);
                        }} className="d-inline-block option-circle ml-auto" />
                      </div>
                    </Col>

                    <Col md={1}></Col>

                    <Col md={4}>
                      <div class="align-items-center travel-selection-option">
                        {(lnstr === 'en') ? 'NO' : 'لا'}
                        <input type="radio" readOnly={isFormFrozen} name="questionans" value="No" onChange={(event) => {
                          setSelectedInboundQuestionOption(event.target.value);
                        }} className="d-inline-block option-circle ml-auto" />
                      </div>
                    </Col>
                  </Row>
                </div>}

                <h4 className="px-0 py-2">Please put your signature here</h4>


                <SignatureCanvas
                  ref={signatureRef}
                  penColor="black"
                  readOnly={isFormFrozen}
                  canvasProps={{ width: 340, height: 200, className: 'sigCanvas' }}
                  style={{ border: '1px solid #ccc' }}
                  onEnd={handleSignature} // Detect when drawing ends
                />
                <div>
                  <button type="button"
                    style={{ backgroundColor: !isSignatureEmpty ? '#007bff' : 'grey', borderColor: '#007bff' }}
                    className="rounded-pill text-white my-3 py-1 " onClick={async () => {
                      clearSignature();
                    }}
                    disabled={isSignatureEmpty}
                  >
                    Clear Signature
                  </button>
                </div>
                <p className={lnstr} style={{ marginTop: '3px' }}><input type="checkbox" readOnly={isFormFrozen} checked={isInboundfinalChecked}
                  onChange={(event) => {
                    setfinalDeclarationChecked(event.target.checked);
                  }} name="note" />
                  {(lnstr === 'en') ? ' I/We hereby declare that to the best of my/our knowledge and belief, the above statements and particulars are complete and true and that I/We have not misstated or suppressed any material facts. (A material fact is one which is likely to influence Superjetom company acceptance or assessment of this proposal. If in any doubt whether facts are material, they should be disclosed). Submitting this form does not bind the Proposer to complete the Insurance, nor Superjetom company to accept, but it is agreed that this form shall be the basis of the contract should a policy be issued.'
                    :
                    'أقر/نحن بموجب هذا أنه على حد علمي/اعتقادنا، فإن البيانات والتفاصيل المذكورة أعلاه كاملة وصحيحة وأنني/نحن لم نخطئ أو نخفي أي حقائق مادية. (الحقيقة المادية هي تلك التي من المحتمل أن تؤثر على قبول شركة Superjetom أو تقييمها لهذا الاقتراح. وإذا كان هناك أي شك فيما إذا كانت الحقائق مادية، فيجب الكشف عنها). إن تقديم هذا النموذج لا يلزم مقدم الطلب بإكمال التأمين، ولا يلزم شركة Superjetom بقبوله، ولكن من المتفق عليه أن هذا النموذج سيكون أساس العقد في حالة إصدار بوليصة التأمين.'}
                </p>
                <div>
                  {!done1 && (
                    <button type="button"
                      style={{ backgroundColor: !buttonDisabled1 ? '#007bff' : 'grey', borderColor: '#007bff', fontWeight: 'bold' }}
                      className="rounded-pill text-white my-1 py-1 " onClick={async () => {

                        setCountryError("");
                        setCoverageError("");
                        setdaysError("");
                        setCountryError("");
                        setDateError("");
                        setFnameError("");
                        setLnameError("");
                        setDobError("");
                        setEmailError("");
                        setpassportError("");
                        setNationalityError("");
                        setPhoneError("");
                        setGenderError("");
                        setMaritalError("");

                        console.log(personalInfo);
                        if (!selectedInboundCoverageOptionId) {
                          setCoverageError(lnstr === "en" ? "Please select a coverage option." : "الرجاء تحديد خيار التغطية.");
                          document.getElementsByName("coverageselection")[0].focus();
                          var ele_firstname = document.getElementsByName("coverageselection")[0];
                          if (ele_firstname) {
                            ele_firstname.style.border = "1px solid blue";
                          }

                        } else if (!selectedInboundNumberOfDays) {
                          setdaysError(lnstr === "en" ? "Please select the number of days." : "يرجى اختيار عدد الأيام.");
                          document.getElementsByName("numberofdays")[0].focus();
                          var ele_numberdays = document.getElementsByName("numberofdays")[0];
                          if (ele_numberdays) {
                            ele_numberdays.style.border = "1px solid blue";
                          }
                        } else if (!selectedInboundFromCountryOption) {
                          alert("Please select a country.");
                          setCountryError("Please select a country.");
                          // document.getElementsByName("insurancefromcountry")[0].focus();

                        } else if (!selectedstartdOption) {
                          document.getElementsByName("insurancestartdate")[0].focus();
                          var ele_startdate = document.getElementsByName("insurancestartdate")[0];
                          if (ele_startdate) {
                            ele_startdate.style.border = "1px solid blue";
                          }
                          setDateError("Please select a Start Date.");

                        } else if (selectedstartdOption < formattedDate) {
                          document.getElementsByName("insurancestartdate")[0].focus();
                          var ele_startdate = document.getElementsByName("insurancestartdate")[0];
                          if (ele_startdate) {
                            ele_startdate.style.border = "1px solid blue";
                          }
                          setDateError("Please select a Valid Start Date.");
                        } else if (personalInfo.firstName == '') {
                          document.getElementsByName("firstName")[0].focus();
                          var ele_firstname1 = document.getElementsByName("firstName")[0];
                          if (ele_firstname1) {
                            ele_firstname1.style.border = "1px solid blue";
                          }
                          setFnameError("Please enter the First name");
                        } else if (personalInfo.lastName == '') {
                          document.getElementsByName("lastName")[0].focus();
                          var ele_lastname = document.getElementsByName("lastName")[0];
                          if (ele_lastname) {
                            ele_lastname.style.border = "1px solid blue";
                          }
                          setLnameError("Please enter the last name");

                        } else if (personalInfo.dob == '') {
                          document.getElementsByName("dob")[0].focus();
                          var ele_dob = document.getElementsByName("dob")[0];
                          if (ele_dob) {
                            ele_dob.style.border = "1px solid blue";
                          }
                          setDobError("Please enter the Date of Birth");

                        } else if (age < 0) {
                          document.getElementsByName("dob")[0].focus();
                          var ele_dob = document.getElementsByName("dob")[0];
                          if (ele_dob) {
                            ele_dob.style.border = "1px solid blue";
                          }
                          setDobError("Age must be within 0 to 85 years.");

                        } else if (age > 85) {
                          document.getElementsByName("dob")[0].focus();
                          var ele_dob = document.getElementsByName("dob")[0];
                          if (ele_dob) {
                            ele_dob.style.border = "1px solid blue";
                          }
                          setDobError("Age must be within 0 to 85 years.");

                        } else if (personalInfo.email == '') {
                          document.getElementsByName("email")[0].focus();
                          var ele_email = document.getElementsByName("email")[0];
                          if (ele_email) {
                            ele_email.style.border = "1px solid blue";
                          }
                          setEmailError("Please enter the  email address. Example: example@domain.com");

                        } else if (emailerror !== "") {
                          document.getElementsByName("email")[0].focus();
                          var ele_email = document.getElementsByName("email")[0];
                          if (ele_email) {
                            ele_email.style.border = "1px solid blue";
                          }
                          setEmailError('Please enter the  email address. Example: example@domain.com')
                        } else if (personalInfo.passportNumber == '') {
                          document.getElementsByName("passportNumber")[0].focus();
                          var ele_passportNumber = document.getElementsByName("passportNumber")[0];
                          if (ele_passportNumber) {
                            ele_passportNumber.style.border = "1px solid blue";
                          }
                          setpassportError("Please enter the Passport Number ");

                        } else if (!selectedInboundNationality) {
                          alert("Please Select a Nationality");
                          document.getElementsByName("passportNumber")[0].focus();
                          setNationalityError("Please Select a Nationality ");

                        } else if (personalInfo.phone == '') {
                          document.getElementsByName("phone")[0].focus();
                          var ele_phone = document.getElementsByName("phone")[0];
                          if (ele_phone) {
                            ele_phone.style.border = "1px solid blue";
                          }
                          setPhoneError("Please enter the Phone no");

                        } else if (!selectedgenderOptionid) {
                          document.getElementsByName("gender")[0].focus();
                          var ele_gender = document.getElementsByName("gender")[0];
                          if (ele_gender) {
                            ele_gender.style.border = "1px solid blue";
                          }
                          setGenderError("Please Select a Gender ");
                        } else if (!selectedMaritalOptionid) {
                          document.getElementsByName("maritalStatus")[0].focus();
                          var ele_maritalStatus = document.getElementsByName("maritalStatus")[0];
                          if (ele_maritalStatus) {
                            ele_maritalStatus.style.border = "1px solid blue";
                          }
                          setMaritalError("Please select a Marital Status");
                        } else if (!isInboundPersonalDeclarationChecked) {
                          alert("Please ensure the declaration");

                        } else if (!isInboundPersonalDeclarationChecked) {
                          alert("Please ensure the declaration");

                        } else if (!isInboundPersonalDeclarationChecked) {
                          alert("Please ensure the declaration");
                        } else if (!inboundPlanDetails) {
                          alert("Please ensure the plan details");
                        } else if(selectedInboundQuestionOption == 'No') {
                          alert("Please select the Answer Yes in question 1")
                        } else if (!selectedInboundQuestionOption) {
                          alert("Please ensure the Questions");
                        } else if (isSignatureEmpty) {
                          alert("Please Put the Signature");
                        } else if (!isInboundfinalChecked) {
                          alert("Please ensure the declaration")
                        } else {


                          const fnum = phoneaValue + '' + personalInfo.phone;

                          const desiredLength = 12;

                          const padToTwelveDigits = (number) => {
                            const zerosToAdd = desiredLength - number.length;

                            if (zerosToAdd < 0) {
                              const pn = personalInfo.phone;

                              const zToAdd = desiredLength - pn.length;

                              const paddedNumber = '0'.repeat(zToAdd) + pn;

                              return paddedNumber;
                            }
                            else {
                              const paddedNumber = '0'.repeat(zerosToAdd) + number;

                              return paddedNumber;
                            }



                          };
                          const twelveDigitNumber = padToTwelveDigits(fnum);
                          var travel_insurance_token_response = await fetch(`${base_url}/create_sanad_personal_accident_token`, { method: 'GET', headers: { 'Authorization': `Bearer ${vendorKey}` } });
                          const travel_insurance_token_response_data = await travel_insurance_token_response.json();
                          if (travel_insurance_token_response_data.message == 'success') {
                            var travel_insurance_token = travel_insurance_token_response_data?.result?.data?.token;
                          }
debugger 
                          var PolicyData = {
                            "token": travel_insurance_token,
                            "sanad_username": sanadUserName,
                            "sanad_requesteename": sanadRequesteeName,
                            "sanad_reference_no": sanadReferenceNumber,
                            "sanad_civil_no": sanadCivilIDNumber,
                            "sanad_passport_no": personalInfo.passportNumber,
                            "sanad_service_requestid": travelrequestid,
                            "sanad_data": {
                              "sanadUserName": sanadUserName,
                              "sanad_requesteename": sanadRequesteeName,
                              "sanadUserMobileNumber": sanadUserMobileNumber,
                              "sanadReferenceNumber": sanadReferenceNumber,
                              "sanadCivilIDNumber": sanadCivilIDNumber,
                              "sanad_ServiceRequestId": travelrequestid,
                              "sanad_TransDate": sanadTransDate,
                              "sanad_ReturnUrl": sanadReturnUrl,
                              "sanad_ErrorUrl": sanadErrorUrl,
                              "sanad_BackUrl": sanadBackUrl,
                              "sanad_Language": sanadLanguage,
                              "sanad_passport_no": personalInfo.passportNumber
                            },
                            "policy_data": {
                              "nationality": selectedInboundNationality,
                              "nationalityCode": selectedInboundNationalityid,
                              "inbound_gender_id": selectedgenderOptionid,
                              "inbound_gender_detail": selectedgenderOption,
                              "inbound_marital_status_id": selectedMaritalOptionid,
                              "inbound_marital_status_detail": selectedMaritelOption,
                              "inbound_product_type_id": "2",
                              "inbound_product_type_name": inboundProductData.type.description,
                              "inbound_product_id": inboundProductData.productId,
                              "inbound_product_name": inboundProductData.productDescription,
                              "inbound_coverage_id": selectedInboundCoverageOptionId,
                              "inbound_coverage_name": selectedInboundCoverageOption,
                              "inbound_country_id": selectedInboundFromCountryOptionid,
                              "inbound_country_name": selectedInboundFromCountry,
                              "inbound_period_type_id": 1,
                              "inbound_period_value_id": 6,
                              "cumulative_days": selectedInboundNumberOfDays,
                              "inbound_travel_start_date": selectedstartdOption,
                              'first_name': personalInfo.firstName,
                              'last_name': personalInfo.lastName,
                              'email': personalInfo.email,
                              'mobileNumber': twelveDigitNumber,
                              'passport': personalInfo.passportNumber,
                              'dob': personalInfo.dob,
                              "inGoodHealth": selectedInboundQuestionOption,
                              "amount": inboundPlanDetails.amount,
                              "signature": signatureRef.current.toDataURL()
                            }
                          };

                          // debugger
                          // var inbound_Policynumber_response = await fetch(`${base_url}/getsanadtravel_inbound_addpolicy`, { method: 'POST', headers: { 'Authorization': `Bearer ${vendorKey}`, 'Content-Type': 'application/json' }, body: JSON.stringify(PolicyData) });

                          // var inbound_PolicyNo_response_data = await inbound_Policynumber_response.json();


                          // if (inbound_PolicyNo_response_data.message == 'success') {

                          //   if (inbound_PolicyNo_response_data.result == "sanad reference number already exist") {
                          //     alert("sanad reference number already exist")
                          //     setLoading(false);
                          //   } else {
                          //     SetPolicyNo(inbound_PolicyNo_response_data.result.policy_number);
                          //     var get_policy_number = inbound_PolicyNo_response_data.result.policy_number;
                          //     alert(inbound_PolicyNo_response_data.result.policy_number);
                          //   }

                          // } else {

                          //   if (inbound_PolicyNo_response_data.result.message == 'The dob field is mandatory.') {
                          //     window.alert('Please Select Date of Birth');
                          //   }
                          // }
                          setPolicyDetails(PolicyData)
                          setInboundPage(false);
                          setViewSummary(true);
                          // document.getElementById('mainvalue')[0].focus()
                          window.scrollTo(0, 0);
                          setPaymentLoading(true);
                          setPaymentLoading(false);
                        }
                      }}
                    >
                      Get Summary
                    </button>
                  )}

                </div>
              </div>
            </div>
          </div>
        )}


        {viewsummary && (
          <div>
            <div style={styles.container}>
              <div style={styles.card}>
                <h4 className="px-0 py-2">Summary</h4>



                <div className="colmds-4 card m-0">

                  <Row className="py-2" style={{ direction: 'ltr' }}>

                    <Col md={6}>

                      Travel Type<br />

                      <b id='mainvalue'>{inboundProductData.productDescription}</b><br /><br />


                      Coverage Type<br />

                      <b>{selectedInboundCoverageOption}</b><br /><br />





                      Traveller Type<br />

                      <b>Individual</b>

                    </Col>

                    <Col md={6}>

                      Travel Start Date<br />

                      <b>{(selectedstartdOption) ? format(new Date(selectedstartdOption), 'dd-MM-yyyy') : ''}</b><br /><br />


                      Travel Duration<br />

                      <b>{selectedInboundNumberOfDays}</b><br /><br />

                      Plan<br />

                      <b>{inboundProductData.productDescription}</b><br /><br />

                    </Col>

                    <br /><br />

                  </Row>

                </div>



                <br />

                <b>Traveller Details</b>

                {/* <div className="mobdiv" style={{ direction: 'ltr' }}> */}

                <div style={{ overflowX: 'auto' }}>
                  <table className="table table-bordered table-condensed" style={{ direction: 'ltr' }}>
                    <tbody>
                      <tr><td>First Name</td><td><b>{personalInfo.firstName}</b></td>
                        <td>Last Name</td><td><b>{personalInfo.lastName}</b></td></tr>

                      <tr><td>Email</td><td><b>{personalInfo.email}</b></td>
                        <td>Mobile</td><td><b>{personalInfo.phone}</b></td></tr>

                      <tr><td>Passport</td><td><b>{personalInfo.passportNumber}</b></td>
                        <td>Relation</td><td><b>Principal</b></td></tr>

                      <tr><td>Gender</td><td><b>{selectedgenderOption}</b></td>
                        <td>Marital Status</td><td><b>{selectedMaritelOption}</b></td></tr>

                      <tr><td>DOB</td><td><b>{(personalInfo.dob) ? format(new Date(personalInfo.dob), 'dd-MM-yyyy') : ''}</b></td>
                        <td>Nationality</td><td><b>{selectedInboundNationality}</b></td></tr>
                    </tbody>
                  </table>
                </div>

                {/* </div> */}

                <br />

                <b>Premium Details</b>

                <table className="table table-bordered table-condenced" style={{ direction: 'ltr' }}>

                  <tbody>

                    <tr><td>
                      Basic premium</td><td><b>{inboundPlanDetails.amount?.base_price} OMR</b></td></tr>

                    <tr><td>
                      Policy Fee</td><td><b>{inboundPlanDetails.amount?.policy_fee} OMR</b></td></tr>

                    <tr><td>
                      Government Fee</td><td><b>{inboundPlanDetails.amount?.government_fee} OMR</b></td></tr>

                    <tr><td>
                      Emergency Fund Fee</td><td><b>{inboundPlanDetails.amount?.emergency_fund_fee} OMR</b></td></tr>

                    <tr><td>
                      VAT</td><td><b>{inboundPlanDetails.amount?.vat} OMR</b></td></tr>

                    <tr><td>
                      Total</td><td><b>{inboundPlanDetails.amount?.total} OMR</b></td></tr>


                  </tbody>

                </table>
              </div>

              <div className="d-flex  my-3">

                <button className="btn btn-primary" style={{ padding: '10px 30px', marginRight: '10px', backgroundColor: '#007bff' }} onClick={async () => {

                  setViewSummary(false);
                  setInboundPage(true);
                }}>Edit</button>

                <button className="btn btn-primary" style={{ padding: '10px 30px', backgroundColor: '#007bff' }} onClick={async () => {

                  setLoading(true);

                  debugger
                  var inbound_Policynumber_response = await fetch(`${base_url}/getsanadtravel_inbound_addpolicy`, { method: 'POST', headers: { 'Authorization': `Bearer ${vendorKey}`, 'Content-Type': 'application/json' }, body: JSON.stringify(policydetails)});

                  var inbound_PolicyNo_response_data = await inbound_Policynumber_response.json();


                  if (inbound_PolicyNo_response_data.message == 'success') {

                    if(inbound_PolicyNo_response_data.result == "sanad reference number already exist"){
                      alert("sanad reference number already exist")
                      setLoading(false);
                    } else{
                      SetOutPolicyNo(inbound_PolicyNo_response_data.result.policy_number);
                    var get_policy_number = inbound_PolicyNo_response_data.result.policy_number;
                    const redirect_url = redirecturl + "/sanadtravel-inbound-downloadpolicy?policyno=" + get_policy_number;
                    const getPaymenntData = {
                      "token": travelInsuranceToken,
                      "policy_no": get_policy_number,
                      "redirect_url": redirect_url
                    };
                    var inbound_Payment_response = await fetch(`${base_url}/getsanadtravel_inbound_paymentlink`, { method: 'POST', headers: { 'Authorization': `Bearer ${vendorKey}`, 'Content-Type': 'application/json' }, body: JSON.stringify(getPaymenntData) });
  
                    var inbound_Payment_response_data = await inbound_Payment_response.json();
  
                    // setLoading(false);
                    if (inbound_Payment_response_data.message == ' success') {
  
                      seyPaymetLink(inbound_Payment_response_data.result?.data?.payment_url);
                      setLoading(false);
                      window.location.href = inbound_Payment_response_data.result?.data?.payment_url;
                    } else {
  
                      if (inbound_Payment_response_data.result.message == 'The dob field is mandatory.') {
                        window.alert('Please Select Date of Birth');
                      }
                    }
                    }

                  } else {

                    if (inbound_PolicyNo_response_data.result.message == 'The dob field is mandatory.') {
                      window.alert('Please Select Date of Birth');
                    }
                  }

                  // payment Link
                  debugger
                 

                }}   > Proceed to Payment</button>

              </div>

            </div>

          </div>
        )}
      </div>

    );

  };


  const OutboundTravelPlan = () => {
    const styles = {
      section: {
        padding: '0px 0px',
      },
      container: {
        // maxWidth: '1200px',
        width: '100%',
        margin: '0 auto',
        padding: '0 20px',
        display: 'flex', // Flexbox layout for left and right columns
        flexWrap: 'wrap', // Allow wrapping for smaller screens
        justifyContent: 'space-between', // Space between the two columns
      },
      leftColumn: {
        flex: '1 1 45%', // Take 45% width, grow/shrink based on space
        marginRight: '10px',
      },
      rightColumn: {
        flex: '1 1 45%', // Take 45% width, grow/shrink based on space
        marginLeft: '10px',
        // marginTop: '8px',
      },
      card: {
        backgroundColor: '#f8f9fa',
        borderRadius: '8px',
        padding: '15px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        transition: 'all 0.3s ease-in-out',
        margin: '10px 0',
        width: '100%',
      },
      option: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
      },
      label: {
        fontSize: '16px',
        color: '#333',
        flex: 1,
        marginTop: '10px'
      },
      radio: {
        width: '20px',
        height: '20px',
        backgroundColor: '#007bff',
        borderRadius: '50%',
        marginLeft: '10px',
      },
      select: {
        width: '100%',
        padding: '12px',
        fontSize: '16px',
        borderRadius: '8px',
        transition: 'opacity 0.5s ease',
        '&:hover': {
          opacity: 0.9,  // Slightly fade the dropdown
          borderColor: '#007bff', // Highlight with blue border on hover
        },
      },
      input: {
        padding: '8px',
        width: '100%',
        borderRadius: '5px',
        border: '1px solid #ccc',
        fontSize: '16px',
        '&:hover': {
          borderColor: '#007bff', // Blue border on hover
          boxShadow: '0 4px 8px rgba(0, 123, 255, 0.2)', // Subtle shadow for focus
        },
        // marginTop: '10px'
      },
      heading: {
        // marginBottom: '10px',
        marginTop: '10px',
        // fontWeight: 'bold',

      }
    };
    const handleValidation = () => {
      if (!selectedInboundCoverageOptionId) {
        setCoverageError("Please select a coverage option.");
        // document.getElementsByName("coverageselection")[0].focus();
        return false;
      }
      setCoverageError("");
      return true;
    };
    const handledaysValidation = () => {
      if (!selectedInboundNumberOfDays) {
        setdaysError(lnstr === "en" ? "Please select the number of days." : "يرجى اختيار عدد الأيام.");
        // document.getElementsByName("numberofdays")[0].focus();
        return false;
      }
      setdaysError("");
      return true;
    };
    const handleNameBlur = (e) => {
      const { value } = e.target;

      // Regular expression for English letters and spaces
      const englishLetterPattern = /^[a-zA-Z\s]*$/;

      // Check if the name is empty or contains invalid characters
      if (value === '') {
        setFnameError('Please enter the Full Name');
      } else if (!englishLetterPattern.test(value)) {
        setFnameError('Please enter the Full Name, accept english letters only.');
      } else {
        setFnameError('');
      }
    };
    const handleLnameBlur = (e) => {
      const { value } = e.target;

      // Regular expression for English letters and spaces
      const englishLetterPattern = /^[a-zA-Z\s]*$/;

      // Check if the name is empty or contains invalid characters
      if (value === '') {
        setLnameError('Please enter the Last Name');
      } else if (!englishLetterPattern.test(value)) {
        setLnameError('Please enter the Last Name, accept english letters only.');
      } else {
        setLnameError('');
      }
    };

    const handleOutEmailBlur = (e, index) => {

      const { value } = e.target;

      // Regular expression to validate a basic email format
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const updatedRows = [...outboundFormRows];
      // Check if the email matches the pattern
      if (!emailPattern.test(value)) {
        updatedRows[index]['isValidEmail'] = false;
        setEmailError('Please enter the email address. Example: example@domain.com');

      } else {
        updatedRows[index]['isValidEmail'] = true;
        setEmailError(''); // Clear the error if valid
      }
      setOutboundFormRows(updatedRows);
    };

    const OuthandlePassportBlur = (e, index) => {
      const { value } = e.target;

      // Regular expression to allow only letters and numbers
      const passportPattern = /^[a-zA-Z0-9]*$/;
      const updatedRows = [...outboundFormRows];

      // Check if the Passport Number matches the pattern
      if (!passportPattern.test(value)) {
        updatedRows[index]['isValidPassport'] = false;
        setOutboundpassportError('Please enter valid passport number, it accept numbers & letters only.');
      } else {
        updatedRows[index]['isValidPassport'] = true;
        setOutboundpassportError('');
      }
    };

    const handleDOBBlur = (e, index) => {
      const { value } = e.target;
      const updatedRows = [...outboundFormRows];
      if (age < 18) {
        document.getElementsByName("dob")[0].focus();
        updatedRows[index]['isValidDob'] = true;

      }
    };
    const parseDate = (dateString) => {
      const [day, month, year] = dateString.split('-').map(Number);
      return new Date(year, month - 1, day); // month is 0-indexed
    };
    const calculateAge = (dob) => {
      const today = new Date();
      const birthDate = parseDate(dob);

      // Check if birthDate is valid
      if (isNaN(birthDate.getTime())) {
        // throw new Error('Invalid date');
      }

      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDifference = today.getMonth() - birthDate.getMonth();

      // Adjust age if the birth date hasn't occurred yet this year
      if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }

      return age;
    };
    const [age, setAge] = useState(null);



    const handleoutqueOptionChange = (event, extraValue) => {
      debugger
          if (extraValue === 0) {
            setSelectedOutboundQuestionOption(event.target.value);
            // setSelectedqOptionid1(event.target.id);
          }
          else if (extraValue === 1) {
            setSelectedOutboundQuestionOption1(event.target.value);
            // setSelectedqOptionid2(event.target.id);
          }
      
        };
      
    // OUTBOUND
    return (


      <div style={styles.section}>
        <div style={{ display: 'flex', paddingBottom: '20px' }}>
                        <button style={{ width: '100px', fontWeight: 'bold' }} onClick={() => {
                            const regex = /^(ftp|http|https):\/\/[^ "]+$/;
                            var isValidUrl = regex.test(sanadBackUrl);
                            if (isValidUrl) {
                                window.location.href = sanadBackUrl;
                            } else {
                                window.location.reload();
                            }

                        }} >Back</button>
                    </div>

        {outboundpage && (

<div>
<div style={styles.container}>
  {/* Left Column */}

  <div style={styles.card}>
    <h4 style={{ ...styles.heading, textAlign: 'left' }}>
      <strong>{lnstr === 'en' ? 'Travel Outbound' : 'المعلومات الشخصية'}</strong>
    </h4>
    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
      <div style={styles.leftColumn}>

        <label style={styles.label}>Choose Type of Travellers<span style={{ color: 'red', marginLeft: '7px' }}>*</span></label>

        <select
          // style={styles.select}
          className="form-control"
          onBlur={handleValidation}
          onChange={(event) => {
            setSelectedOutboundtravellers(event.target.value);
            setViewDone(false);
            setOutPlanShowValue(false);
            var ele_traveller = document.getElementsByName("outbound_traveller")[0];
            if (ele_traveller) {
              ele_traveller.style.border = '';
            }
            setoutboundTravellersError("");
          }}
          value={selectedOutboundtravellers}
          name="outbound_traveller"
        >
          <option value="">
            {lnstr === 'en' ? '-- Select travellers --' : '--اختر التغطية--'}
          </option>
          <option value='Individual'>Individual</option>
          <option value='Family'>Family</option>
        </select>

        {outboundtravellerserror && (<div style={{ color: 'red' }}>{outboundtravellerserror}</div>)}





        <label style={styles.label}>Select Product<span style={{ color: 'red', marginLeft: '7px' }}>*</span></label>

        <select
          // style={styles.select}
          className="form-control"
          onBlur={handleValidation}
          // onChange={(e) => handlecOptionChange(e, e.target.value)}
          onChange={(e) => {

            const selectedProduct = outboundProductData.find(
              (product) => product.productDescription === e.target.value
            );
            setSelectedOutboundProductOption(e.target.value);
            setSelectedOutboundProductOptionid(selectedProduct.productId);
            setOutboundPackageData(selectedProduct.packages);
            setSelectedcaOption(selectedProduct.packages[0].destinations[0].countryName)
            setSelectedcaOptionid(selectedProduct.packages[0].destinations[0].countryID)
            setViewDone(false);
            setOutPlanShowValue(false);
            var ele_traveller = document.getElementsByName("outbound_product_selection")[0];
            if (ele_traveller) {
              ele_traveller.style.border = '';
            }
            setOutboundProductError("");

          }}
          value={selectedOutboundProductOption}
          name="outbound_product_selection"
        >
          <option selected disabled>{(lnstr === 'en') ? '-- Select --' : '--اختار--'}</option>
          {outboundProductData && Array.isArray(outboundProductData) && outboundProductData.map((option, index) =>
            <option key={index} value={option.productDescription} data-id={option.productId} >{t(option.productDescription)}</option>
          )}
        </select>
        {outboundproductserror && <p style={{ color: "red", marginTop: "5px" }}>{outboundproductserror}</p>}


        {selectedOutboundProductOption && (<div>
          <label style={styles.label}>Coverage Selection<span style={{ color: 'red', marginLeft: '7px' }}>*</span></label>
          <select
            // style={styles.select}
            className="form-control"
            onBlur={handleValidation}
            disabled={isFormFrozen}
            // onChange={(e) => handlecOptionChange(e, e.target.value)}
            onChange={(e) => {

              const selectedOption = outboundPackageData.find(
                (option) => option.coverageArea.id.toString() === e.target.value.toString()
              );
              // if (selectedOption) {
              //
              //   setSelectedInboundCoverageOptionId(selectedOption.coverageArea.id);
              //   setSelectedInboundCoverageOption(selectedOption.coverageArea.name);
              // }
              if (selectedOption) {

                setSelectedOutboundCoverageOptionid(selectedOption.coverageArea.id);
                setSelectedOutboundCoverageOption(selectedOption.coverageArea.name);
                setViewDone(false);
                setOutPlanShowValue(false);
                var ele_traveller = document.getElementsByName("outbound_coverageselection")[0];
                if (ele_traveller) {
                  ele_traveller.style.border = '';
                }
                setOutBoundCoverageError("");
              } else {
                setSelectedOutboundCoverageOptionid(null);
                setSelectedOutboundCoverageOption("");
              }

            }}
            value={selectedOutboundCoverageOptionid}
            name="outbound_coverageselection"
          >
            <option selected disabled >{(lnstr === 'en') ? '-- Select --' : '--اختار--'}</option>
            {outboundPackageData && outboundPackageData.map((poption, index) =>
              <option key={index} value={poption.coverageArea.id}>{t(poption.coverageArea.name)}</option>
            )}
          </select>
          {outboundcoverageerror && <p style={{ color: "red", marginTop: "5px" }}>{outboundcoverageerror}</p>}

        </div>)}

      </div>

      {/* Right Column */}
      <div style={styles.rightColumn}>


        <label style={styles.label}>Number Of Days<span style={{ color: 'red', marginLeft: '7px' }}>*</span></label>


        {(selectedOutboundProductOption === 'Standard') ?

          <select className="form-control" value={selectedOutboundNumberOfDays} onChange={(event) => {
            setSelectedOutboundNumberOfDays(event.target.value);
            setViewDone(false);
            setOutPlanShowValue(false);
            var ele_traveller = document.getElementsByName("outbound_numberofdays")[0];
            if (ele_traveller) {
              ele_traveller.style.border = '';
            }
            setOutbounddaysError("");
          }} name="outbound_numberofdays">
            <option value="">{(lnstr === 'en') ? '-- Select --' : '--اختار--'}</option>


            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="60">60</option>
            <option value="90">90</option>
            {/* <option value="120">120</option>
<option value="1 Year">1 Year</option>
<option value="2 Years">2 Years</option> */}



          </select>

          :

          <select className="form-control" value={selectedOutboundNumberOfDays} onChange={(event) => {
            setSelectedOutboundNumberOfDays(event.target.value);
            setViewDone(false);
            setOutPlanShowValue(false);
            var ele_traveller = document.getElementsByName("outbound_numberofdays")[0];
            if (ele_traveller) {
              ele_traveller.style.border = '';
            }
            setOutbounddaysError("");
          }} name="outbound_numberofdays">
            <option value="">{(lnstr === 'en') ? '-- Select --' : '--اختار--'}</option>


            <option value={15}>15</option>
            <option value={25}>25</option>
            <option value={45}>45</option>


          </select>

        }
        {outbounddayserror && <p style={{ color: "red", marginTop: "5px" }}>{outbounddayserror}</p>}


        <label style={styles.label}>Start Date<span style={{ color: 'red', marginLeft: '7px' }}>*</span></label>
        <input
          type="date"
          min={formattedDate}
          value={selectedOutboundStartDate}
          name="outbound_insurancestartdate"
          className="form-control"
          onChange={(event) => {
            const selectedDate = event.target.value;
            setSelectedOutboundStartDate(selectedDate);
            setViewDone(false);
            setOutPlanShowValue(false);

            if (selectedDate < formattedDate) {
              setOutboundDateError("Please select a future date.");
            } else {
              setOutboundDateError("");
            }
            // setSelectedstartdOption(event.target.value);
          }}
        // style={styles.input}
        />
        {/* {outbounddateerror && <p style={{ color: "red", marginTop: "5px" }}>{outbounddateerror}</p>} */}


        {selectedOutboundtravellers == 'Family' && selectedOutboundProductOption == 'Standard' && (<div>

          <label style={styles.label}>Select Destination Country<span style={{ color: 'red', marginLeft: '7px' }}>*</span></label>
          <Select2Wrapper name="insurancefromcountry" value={appendsValuesid} options={countryData} className='form-control' onChange={handlefromcountryChange} required style={{ padding: 15 }} />

          <p className="my-1"><b className="my-3">In Case of Multiple Destinations required
            Please select the countries one by one from the above dropdown</b></p>


          {appendedValues && appendedValues.map((value, index) => (


            <div key={index}>
              {index === 0 ? (

                <div>
                  <div
                    key={index}

                    onDragStart={(e) => handleDragStart(e, index)}
                    onDragOver={handleDragOver}
                    onDrop={(e) => handleDrop(e, index)}
                    style={{
                      padding: '10px',
                      margin: '5px 0',
                      backgroundColor: '#f0f0f0',
                      borderRadius: '4px',
                      cursor: 'move',
                    }}
                  >
                    {value}
                  </div>

                  {(appendedValues.length != (index + 1)) ?
                    <div class="outboundarrow"></div> : ''
                  }

                </div>) : (
                <div>
                  <div
                    key={index}
                    draggable
                    onDragStart={(e) => handleDragStart(e, index)}
                    onDragOver={handleDragOver}
                    onDrop={(e) => handleDrop(e, index)}
                    style={{
                      padding: '10px',
                      margin: '5px 0',
                      backgroundColor: '#f0f0f0',
                      borderRadius: '4px',
                      cursor: 'move',
                    }}
                  >
                    {value} <button style={{ float: 'right', fontWeight: 'normal', fontSize: 10, width: 30 }} className="btn btn-primary btn-sm my-0 text-white" onClick={() => removeItem(index)}>X</button>
                  </div>

                  {(appendedValues.length != (index + 1)) ?
                    <div class="outboundarrow"></div> : ''
                  }

                </div>)}

            </div>

          ))}

        </div>)

        }
      </div>
    </div>
  </div>





  {/* Personal Information Section */}

  {/*
  <form onSubmit={handleSubmit}>
   */}





  <div style={styles.card}>
    <h4 style={{ ...styles.heading, textAlign: 'left' }}>
      <strong>{lnstr === 'en' ? 'Personal Information' : 'المعلومات الشخصية'}</strong>
    </h4>

    {outboundFormRows.map((row, index) => (
      <div key={index} style={{ marginBottom: '20px' }}>
        <div style={{ marginTop: '10px', width: '100%' }}>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
            {/* Left Column */}
            <div style={styles.leftColumn}>
              <label style={styles.label}>First Name<span style={{ color: 'red', marginLeft: '7px' }}>*</span></label>
              {/* <input
                type="text"
                name="out_first_name"
                style={styles.input}
                onChange={(e) => handleOutboundInputChange(index, e, 'first_name')}
                value={row.first_name}
                required
              /> */}
              <input
                ref={(el) =>
                (travelerRefs.current[index] = {
                  ...travelerRefs.current[index],
                  first_name: el,
                })
                }
                type="text"
                name="out_first_name"
                // style={styles.input}
                className="form-control"
                onChange={(e) => {
                  handleOutboundInputChange(index, e, 'first_name');
                  setViewDone(false);
                  setOutPlanShowValue(false);
                  const updatedRows = [...outboundFormRows];
                  updatedRows[index]['isValidFirstName'] = true;
                  setOutboundFormRows(updatedRows);
                }}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, '');
                }}
                value={row.first_name}
                required
              />
              {!row.isValidFirstName && <p style={{ color: 'red', fontSize: '12px' }}>
                {'Please enter the First Name'}
              </p>}

              {/* {outerrors[index]?.first_name && (
                    <p style={{ color: 'red', fontSize: '12px' }}>
                      {outerrors[index].first_name}
                    </p>
                  )} */}
              {/* Add other input fields similarly */}

              <label style={styles.label}>Last Name<span style={{ color: 'red', marginLeft: '7px' }}>*</span></label>
              <input ref={(el) =>
              (travelerRefs.current[index] = {
                ...travelerRefs.current[index],
                last_name: el,
              })
              }
                type="text"
                name="out_last_name"
                // style={styles.input}
                className="form-control"
                onChange={(e) => {
                  handleOutboundInputChange(index, e, 'last_name')
                  setViewDone(false);
                  setOutPlanShowValue(false);
                  const updatedRows = [...outboundFormRows];
                  updatedRows[index]['isValidLastName'] = true;
                  setOutboundFormRows(updatedRows);
                }}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, '');
                }}
                value={row.last_name}
                required
              />
              {!row.isValidLastName && <p style={{ color: 'red', fontSize: '12px' }}>
                {'Please enter the Last Name'}
              </p>}
              {/* Add other input fields similarly */}
              {/* {outerrors[index]?.last_name && (
                    <p style={{ color: 'red', fontSize: '12px' }}>
                      {outerrors[index].last_name}
                    </p>
                  )} */}

              <label style={styles.label}>Date of Birth<span style={{ color: 'red', marginLeft: '7px' }}>*</span></label>
              <input
                ref={(el) =>
                (travelerRefs.current[index] = {
                  ...travelerRefs.current[index],
                  dob: el,
                })
                }
                type="date"
                name="out_dob"
                // style={styles.input}
                className="form-control"
                value={row.dob ? formatDate(row.dob) : ''}
                max={new Date().toISOString().split('T')[0]}
                onChange={(e) => {
                  const enteredDate = e.target.value;
                  const todayDate = new Date().toISOString().split('T')[0];
                  const formattedDate = e.target.value.replace(/\//g, '-');
                  const [year, month, day] = formattedDate.split("-");
                  const finalformattedDate = `${day}-${month}-${year}`;
                  const age = calculateAge(finalformattedDate);

                  const updatedRows = [...outboundFormRows];

                  if (enteredDate > todayDate) {
                    updatedRows[index]['isValidDob'] = false;
                    setOutboundDobError("Future dates are not allowed.");
                    e.target.value = todayDate; // Reset the value to today if it's in the future
                  } else {
                    updatedRows[index]['isValidDob'] = true;
                    setOutboundDobError("");
                  }
                  if (formattedDate > formattedDate1) {
                    updatedRows[index]['isValidDob'] = false;
                    setOutboundDobError("Please select a future date.");
                  } else if (age > 85) {
                    updatedRows[index]['isValidDob'] = false;
                    setOutboundDateError("Age must be within 0 to 85 years.")
                  } else {
                    updatedRows[index]['isValidDob'] = true;
                    setOutboundDobError("");
                  }

                  setAge(age);
                  if (age < 0) {
                    updatedRows[index]['isValidDob'] = false;
                    setOutboundDobError('Your Age is invalid');

                  }
                  if (age >= 0) {
                    updatedRows[index]['isValidDob'] = true;
                    setOutboundDobError('');

                  }
                }
                }
                onInput={(e) => {
                  const value = e.target.value;
                  handleOutboundInputChange(index, value, 'dob');
                  setViewDone(false);
                  setOutPlanShowValue(false);
                  const updatedRows = [...outboundFormRows];
                  updatedRows[index]['isValid1dob'] = true;
                  setOutboundFormRows(updatedRows);
                  setErrors((prev) => ({
                    ...prev,
                    dob: value ? '' : 'Date of Birth is required',
                  }));
                }}
              />
              {!row.isValidDob && row.dob != '' && <p style={{ color: 'red', fontSize: '12px' }}>
                {'Please enter the valid Date of Birth'}
              </p>}
              {!row.isValid1dob && <p style={{ color: 'red', fontSize: '12px' }}>
                {'Please enter the Date of Birth'}
              </p>}
              {/* {outerrors[index]?.dob && (
                    <p style={{ color: 'red', fontSize: '12px' }}>
                      {outerrors[index].dob}
                    </p>
                  )} */}


              <label style={styles.label}>Email<span style={{ color: 'red', marginLeft: '7px' }}>*</span></label>
              <input ref={(el) =>
              (travelerRefs.current[index] = {
                ...travelerRefs.current[index],
                email: el,
              })
              }
                type="email"
                name="out_email"
                // style={styles.input}
                className="form-control"
                value={row.email}
                onChange={(e) => {
                  handleOutboundInputChange(index, e, 'email');
                  setViewDone(false);
                  setOutPlanShowValue(false);
                  const updatedRows = [...outboundFormRows];
                  updatedRows[index]['isValidEmail1'] = true;
                  setOutboundFormRows(updatedRows);
                }}
                onBlur={(e) => handleOutEmailBlur(e, index)}
              />
              {!row.isValidEmail && row.email != '' && <p style={{ color: 'red', fontSize: '12px' }}>
                {'Please enter the email address. Example: example@domain.com'}
              </p>}
              {!row.isValidEmail1 && <p style={{ color: 'red', fontSize: '12px' }}>
                {'Please enter the email address. Example: example@domain.com'}
              </p>}

              {/* {outerrors[index]?.email && (
                    <p style={{ color: 'red', fontSize: '12px' }}>
                      {outerrors[index].email}
                    </p>
                  )} */}

              <label style={styles.label}>Passport Number<span style={{ color: 'red', marginLeft: '7px' }}>*</span></label>
              <input ref={(el) =>
              (travelerRefs.current[index] = {
                ...travelerRefs.current[index],
                passport_number: el,
              })
              }
                type="text"
                name="out_passport_number"
                // style={styles.input}
                className="form-control"
                value={row.passport_number}
                onInput={(e) => {
                  const value = e.target.value;
                  handleOutboundInputChange(index, e, 'passport_number');
                  setViewDone(false);
                  setOutPlanShowValue(false);
                  const updatedRows = [...outboundFormRows];
                  updatedRows[index]['isValidPassport1'] = true;
                  setOutboundFormRows(updatedRows);
                  setErrors((prev) => ({
                    ...prev,
                    passportNumber: /^[A-Za-z0-9]+$/.test(value)
                      ? ''
                      : 'Passport number must be alphanumeric',
                  }));
                }}
                onBlur={(e) => OuthandlePassportBlur(e, index)}
              />
              {!row.isValidPassport && row.passport_number != '' && <p style={{ color: 'red', fontSize: '12px' }}>
                {'Please enter valid passport number, it accept numbers & letters only.'}
              </p>}
              {!row.isValidPassport1 && <p style={{ color: 'red', fontSize: '12px' }}>
                {'Please enter valid passport number, it accept numbers & letters only.'}
              </p>}

              {/* {outerrors[index]?.passport_number && (
                    <p style={{ color: 'red', fontSize: '12px' }}>
                      {outerrors[index].passport_number}
                    </p>
                  )} */}
            </div>


            {/* Right Column */}
            <div style={styles.rightColumn}>
              <label style={styles.label}>Nationality<span style={{ color: 'red', marginLeft: '7px' }}>*</span></label>
              <Select2Wrapper
                name="out_nationality"
                options={countryData}
                value={row.nationality}
                onChange={async (value) => {

                  const [description, id] = value.split("#");

                  //
                  // setOutNationality(description)
                  handleOutboundInputChange(index, value, 'nationality');
                  handleOutboundInputChange(index, id, 'nationality_id');
                  setViewDone(false);
                  setOutPlanShowValue(false);
                  const updatedRows = [...outboundFormRows];
                  updatedRows[index]['isValidNationality'] = true;
                  setOutboundFormRows(updatedRows);
                  const cnData = { country: description };
                  //

                  await fetch(base_url + '/get_country_code', {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(cnData),
                  })
                    .then(response => response.json())
                    .then(data => {


                      const cde = data.result.replace(/\+/g, '');

                      setphonecode(cde);
                      handleOutboundInputChange(index, cde, 'ccode');

                    })
                    .catch(error => {
                      console.error('Error posting data:', error);
                    });
                }}
                style={styles.input}
                required
              />
              {!row.isValidNationality && <p style={{ color: 'red', fontSize: '12px' }}>
                {'Please Select a Nationality'}
              </p>}
              {/* {outerrors[index]?.nationality && (
                    <p style={{ color: 'red', fontSize: '12px' }}>
                      {outerrors[index].nationality}
                    </p>
                  )} */}

              <label style={styles.label}>Phone<span style={{ color: 'red', marginLeft: '7px' }}>*</span></label>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '1px' }}>
                <input
                  type="text"
                  value={row.ccode}
                  style={{ ...styles.input, width: '100px' }}
                  readOnly
                />
                <input ref={(el) =>
                (travelerRefs.current[index] = {
                  ...travelerRefs.current[index],
                  mobile_number: el,
                })
                }
                  type="text"
                  name="out_mobile_number"
                  // style={styles.input}
                  className="form-control"
                  maxLength={12}
                  value={row.mobile_number}
                  onChange={(e) => {
                    handleOutboundInputChange(index, e, 'mobile_number');
                    setViewDone(false);
                    setOutPlanShowValue(false);
                    const updatedRows = [...outboundFormRows];
                    updatedRows[index]['isValidMobilenumber'] = true;
                    setOutboundFormRows(updatedRows);
                  }}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                  }}
                />

              </div>
              {!row.isValidMobilenumber && <p style={{ color: 'red', fontSize: '12px' }}>
                {'Please enter the Phone no'}
              </p>}
              {/* {outerrors[index]?.mobile_number && (
                    <p style={{ color: 'red', fontSize: '12px' }}>
                      {outerrors[index].mobile_number}
                    </p>
                  )} */}


              <label style={styles.label}>Gender<span style={{ color: 'red', marginLeft: '7px' }}>*</span></label>
              <select ref={(el) =>
              (travelerRefs.current[index] = {
                ...travelerRefs.current[index],
                gender_value: el,
              })
              }
                // style={styles.input}
                className="form-control"
                value={row.gender_value}
                name="out_gender_value"
                onChange={(event) => {

                  setSelectedOutboundgenderOptionid(event.target.options[event.target.selectedIndex].id);
                  setSelectedOutboundgenderOption(event.target.value);
                  handleOutboundInputChange(index, event.target.value, 'gender_value');
                  handleOutboundInputChange(index, event.target.options[event.target.selectedIndex].id, 'gender_id');
                  setViewDone(false);
                  setOutPlanShowValue(false);
                  const updatedRows = [...outboundFormRows];
                  updatedRows[index]['isValidGender'] = true;
                  setOutboundFormRows(updatedRows);
                }}
              >
                <option value="">{(lnstr === 'en') ? '-- Select --' : '--اختار--'}</option>
                {outboundgoptions && outboundgoptions.map((opt) =>
                  <option key={opt.id} id={opt.id} value={opt.description}>{t(opt.description)}</option>
                )}
              </select>
              {!row.isValidGender && <p style={{ color: 'red', fontSize: '12px' }}>
                {'Please Select a Gender '}
              </p>}
              {/* {outerrors[index]?.gender_value && (
                    <p style={{ color: 'red', fontSize: '12px' }}>
                      {outerrors[index].gender_value}
                    </p>
                  )} */}


              <label style={styles.label}>Marital Status<span style={{ color: 'red', marginLeft: '7px' }}>*</span></label>
              <select ref={(el) =>
              (travelerRefs.current[index] = {
                ...travelerRefs.current[index],
                marital_status_value: el,
              })
              }
                // style={styles.input}
                className="form-control"
                value={row.marital_status_value}
                name="out_marital_status_value"
                // value={personalInfo.maritalStatus}
                onChange={(event) => {

                  setSelectedOutboundMaritalOptionid(event.target.options[event.target.selectedIndex].id);
                  setSelectedOutboundMaritalOption(event.target.value);
                  handleOutboundInputChange(index, event.target.value, 'marital_status_value');
                  handleOutboundInputChange(index, event.target.options[event.target.selectedIndex].id, 'marital_status_id');
                  setViewDone(false);
                  setOutPlanShowValue(false);
                  const updatedRows = [...outboundFormRows];
                  updatedRows[index]['isValidMarried'] = true;
                  setOutboundFormRows(updatedRows);
                }}
              >
                <option value="">{(lnstr === 'en') ? '-- Select --' : '--اختار--'}</option>
                {outboundmoptions && outboundmoptions.map((mopt) =>
                  <option key={mopt.id} id={mopt.id}
                    value={mopt.description}>{t(mopt.description)}</option>
                )}
              </select>
              {!row.isValidMarried && <p style={{ color: 'red', fontSize: '12px' }}>
                {'Please select a Marital Status'}
              </p>}
              {/* {outerrors[index]?.marital_status_value && (
                    <p style={{ color: 'red', fontSize: '12px' }}>
                      {outerrors[index].marital_status_value}
                    </p>
                  )} */}




            </div>
            <p className={lnstr}><input type="checkbox" checked={outboundFormRows[index]['declaration']}
              onChange={(e) => handleOutboundInputChange(index, e, 'declaration')} name="note" />
              {(lnstr === 'en') ? ' I want to receive insurance updates, product launches and personalized offers. I can opt out anytime.'
                :
                'أرغب في تلقي تحديثات التأمين، وإطلاقات المنتجات، والعروض الشخصية. يمكنني الانسحاب في أي وقت.'}
            </p>
            <p>
              {(lnstr === 'en') ? 'We use this to create your insurance and send you updates about your application' : 'نحن نستخدم هذا لإنشاء التأمين الخاص بك وإرسال تحديثات إليك حول طلبك'}
            </p>

          </div>
        </div>

        {selectedOutboundtravellers === 'Family' && outboundFormRows.length > 1 && (
          <button
            type="button"
            className="btn btn-danger my-2"
            onClick={() => handleOutboundRemoveRow(index)}
          >
            Remove Traveler
          </button>
        )}
      </div>
    ))}

    {selectedOutboundtravellers === 'Family' && (
      <button
        type="button"
        className="btn btn-success"
        onClick={handleOutboundAddRow}
      >
        Add Traveler
      </button>
    )}
  </div>

  {!viewdone && (

    <div>

      <button
        type="button"
        name='outbutton'
        style={{ backgroundColor: '#007bff', borderColor: '#007bff', marginTop: '10px' }}
        onClick={async () => {



          setoutboundTravellersError("");
          setOutBoundCoverageError("");
          setOutbounddaysError("");
          setOutboundProductError("");
          setOutboundDateError("");
          setOutboundFnameError("");
          setOutboundLnameError("");
          setOutboundDobError("");
          setOutboundEmailError("");
          setOutboundpassportError("");
          setOutboundNationalityError("");
          setOutboundPhoneError("");
          setOutboundGenderError("");
          setOutboundMaritalError("");

          const invalidEmails = outboundFormRows.filter(
            (item) => item.isValidEmail === '' || item.isValidEmail === false
          );



          console.log(personalInfo);
          if (!selectedOutboundtravellers) {
            setoutboundTravellersError(lnstr === "en" ? "Please select a Travellers option." : "الرجاء تحديد خيار المسافرين.");
            document.getElementsByName("outbound_traveller")[0].focus();

          } else if (!selectedOutboundProductOption) {
            setOutboundProductError(lnstr === "en" ? "Please select a Product." : "الرجاء تحديد المنتج.");
            document.getElementsByName("outbound_product_selection")[0].focus();

          } else if (!selectedOutboundCoverageOption) {
            document.getElementsByName("outbound_coverageselection")[0].focus();
            setOutBoundCoverageError(lnstr === "en" ? "Please select a Coverage Selection" : "الرجاء تحديد اختيار التغطية")

          } else if (!selectedOutboundNumberOfDays) {
            setOutbounddaysError(lnstr === "en" ? "Please select the number of days." : "يرجى اختيار عدد الأيام.");
            document.getElementsByName("outbound_numberofdays")[0].focus();

          } else if (!selectedOutboundStartDate) {
            document.getElementsByName("outbound_insurancestartdate")[0].focus();
            setOutboundDateError("Please select a Start Date.");

          } else if (selectedOutboundStartDate < formattedDate) {
            document.getElementsByName("outbound_insurancestartdate")[0].focus();
            setOutboundDateError("Please select a Valid Start Date.");
          }
          else if (selectedOutboundStartDate < formattedDate) {
            document.getElementsByName("outbound_insurancestartdate")[0].focus();
            setOutboundDateError("Please select a Valid Start Date.");
          }
          else if (validateForm() != true) {
            return
          } else if (selectedOutboundProductOption === 'Standard' && selectedOutboundtravellers == 'Family' && appendedValues && appendedValues.length === 1) {

            // if (appendedValues && appendedValues.length === 1) {
            alert(' Please Select the Destination Country');
            // }else{
            //   return
            // }
          }

          else {





            if (selectedOutboundProductOption === 'Standard' && selectedOutboundtravellers == 'Family') {
              const appendedValuesd = [];

              Object.keys(appendedValues).forEach(key => {

                //

                if (appendedValuesid[key] !== '130') {
                  const vl = { 'countryID': appendedValuesid[key], 'countryName': appendedValues[key] }
                  appendedValuesd.push(vl);
                }

              });
              debugger

              var data = {

                "outbound_product_type_id": "2",
                "outbound_product_id": selectedOutboundProductOptionid,
                "outbound_product_name": selectedOutboundProductOption,
                "outbound_coverage_id": selectedOutboundCoverageOptionid,
                "outbound_coverage_name": selectedOutboundCoverageOption,
                "outbound_travel_destinations": [
                  {
                    "countryID": selectedcaOptionid.toString(),
                    "countryName": selectedcaOption
                  }
                ],
                "outbound_period_type_id": 1,
                "outbound_period_value_id": 4,
                "cumulative_days": selectedOutboundNumberOfDays,
                "outbound_travel_start_date": selectedOutboundStartDate,
                "beneficiaries": outboundFormRows
              }
              setPlanLoading(true);
              const getQuotationData = [{ token: travelInsuranceToken }, data];
              var outbound_qoutation_response = await fetch(`${base_url}/getsanad_outbound_quotations`, { method: 'POST', headers: { 'Authorization': `Bearer ${vendorKey}`, 'Content-Type': 'application/json' }, body: JSON.stringify(getQuotationData) });
              var outbound_qoutation_response_data = await outbound_qoutation_response.json();

              // setLoading(false);
              if (outbound_qoutation_response_data.message == 'success') {

                setOutboundPlanDetails(outbound_qoutation_response_data.result.data);
                setOutPlanShowValue(true);
                setViewDone(true);
                setPlanLoading(false);

              } else {

                if (outbound_qoutation_response_data.result.message == 'The dob field is mandatory.') {
                  window.alert('Please Select Date of Birth');
                  setPlanLoading(false);
                }
              }


            } else {

              const appendedValuesd = [];

              Object.keys(appendedValues).forEach(key => {

                //

                if (appendedValuesid[key] !== '130') {
                  const vl = { 'countryID': appendedValuesid[key], 'countryName': appendedValues[key] }
                  appendedValuesd.push(vl);
                }

              });
              var data1 = {
                "outbound_product_type_id": "2",
                "outbound_product_id": selectedOutboundProductOptionid,
                "outbound_product_name": selectedOutboundProductOption,
                "outbound_coverage_id": selectedOutboundCoverageOptionid,
                "outbound_coverage_name": selectedOutboundCoverageOption,
                "outbound_travel_destinations": [
                  {
                    "countryID": selectedcaOptionid.toString(),
                    "countryName": selectedcaOption
                  }
                ],
                "outbound_period_type_id": 1,
                "outbound_period_value_id": 4,
                "cumulative_days": selectedOutboundNumberOfDays,
                "outbound_travel_start_date": selectedOutboundStartDate,
                "beneficiaries": outboundFormRows
              }
              setPlanLoading(true);
              console.log(data1);
              const getQuotationData = [{ token: travelInsuranceToken }, data1];
              var outbound_qoutation_response = await fetch(`${base_url}/getsanad_outbound_quotations`, { method: 'POST', headers: { 'Authorization': `Bearer ${vendorKey}`, 'Content-Type': 'application/json' }, body: JSON.stringify(getQuotationData) });
              var outbound_qoutation_response_data = await outbound_qoutation_response.json();

              // setLoading(false);
              if (outbound_qoutation_response_data.message == 'success') {

                setOutboundPlanDetails(outbound_qoutation_response_data.result.data);
                setOutPlanShowValue(true);
                setViewDone(true);
                setPlanLoading(false);
                //  console.log(personalInfo);
                //
                // if (inbound_product_response_data?.result?.data[0]) {
                //   setInboundProductData(inbound_product_response_data.result.data[0]);
                // }
              } else {

                if (outbound_qoutation_response_data.result.message == 'The dob field is mandatory.') {
                  window.alert('Please Select Date of Birth');
                  setPlanLoading(false);
                }
              }


            }


          }


        }}
      >
        <strong>{lnstr === "en" ? "View Plan Details" : "عرض"}</strong>

      </button>
      {outboundplandetailserror && <p style={{ color: "red", marginTop: "5px" }}>{outboundplandetailserror}</p>}

    </div>

  )}

  {/* </form> */}





  <div style={{ width: '100%' }}>

    {
      outplanshowvalue && (
        <div>
          <h3>Plan Details :<span> {outboundPlanDetails?.amount.base_price} OMR</span></h3>
        </div>

      )}

    {/* <AnimatePresence>

      {
        inboundPlanDetails && (
          <motion.div
            style={styles.motionDiv}
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.3 }}
          >
            {inboundPlanDetails?.amount.base_price} OMR
          </motion.div>
        )}
    </AnimatePresence> */}


    <div style={styles.card}>
      {outboundQuestionData && <div style={{ marginTop: '5px' }}>

        <h4 className="px-0 py-2">
          <strong>Question</strong>
        </h4>

        {outboundQuestionData && outboundQuestionData.map((mopt, index) =>

          <div style={{ direction: 'ltr' }}>

            <p>{index+1}.{mopt.English}</p>

            <Row>
              <Col md={4}>
                <div class="align-items-center travel-selection-option">
                  {(lnstr === 'en') ? 'YES' : 'نعم\n'}
                  <input type="radio" name={`questionans${index}`} id={mopt.accepted_answer} value="Yes" onChange={(e) => handleoutqueOptionChange(e, index)} className="d-inline-block option-circle ml-auto" />
                </div>
              </Col>

              <Col md={1}></Col>

              <Col md={4}>
                <div class="align-items-center travel-selection-option">
                  {(lnstr === 'en') ? 'NO' : 'لا'}
                  <input type="radio" name={`questionans${index}`} id={mopt.accepted_answer} value="No" onChange={(e) => handleoutqueOptionChange(e, index)} className="d-inline-block option-circle ml-auto" />
                </div>
              </Col>
            </Row><br />

          </div>

        )}
      </div>}

      <h4 className="px-0 py-2">Please put your signature here</h4>


      {/* <SignatureCanvas
        ref={signatureRef}
        penColor="black"
        canvasProps={{ width: 340, height: 200, className: 'sigCanvas' }}
        style={{ border: '1px solid #ccc' }}
        onEnd={handleOutSignature}
      />
      <div>
        <button type="button"
          style={{ backgroundColor: !isSignatureEmpty ? '#007bff' : 'grey', borderColor: '#007bff' }}
          className="rounded-pill text-white my-3 py-1 " onClick={async () => {
            outclearSignature();
          }}
          disabled={isSignatureEmpty}
        >
          Clear Signature
        </button>
      </div> */}


      {/* <div className="row">
            <div className="col-md-4"> */}
           <SignatureCanvas
           id = 'outbound_sign'
            ref={outsignatureRef}
            penColor="black"
            canvasProps={{ width: 340, height: 200, className: 'sigCanvas' }}
            style={{ border: '1px solid #ccc' }}
            // onEnd={handleOutSignature} // Detect when drawing ends
            onEnd={(e)=>{
              if (e.target && e.target.matches('canvas.sigCanvas')) {
                setOutboundSignatureValue(e.target.toDataURL());
                console.log(e.target.toDataURL());
                setoutboundIsSignatureEmpty(false);
              } else {
                console.log('e.target is not the correct canvas element');
              }
            }}
          />
          {/* </div></div> */}
          <div>
            <button type="button"
              style={{ backgroundColor: !outboundisSignatureEmpty ? '#007bff' : 'grey', borderColor: '#007bff' }}
              className="rounded-pill text-white my-3 py-1 " onClick={async () => {
                var ele = document.getElementsByClassName('sigCanvas')
                const canvas = ele[0];
                const ctx = canvas.getContext('2d');
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                setoutboundIsSignatureEmpty(true);
              }}
              disabled={outboundisSignatureEmpty}
            >
              Clear Signature
            </button>
          </div>
      <br />
      <p className={lnstr}><input type="checkbox" style={{ marginRight: '5px' }} checked={outInboundfinalChecked}
        onChange={(event) => {
          setoutfinalDeclarationChecked(event.target.checked);
        }} name="note" />
        {(lnstr === 'en') ? 'I/We agree that this application shall form the basis of a contract between me/us and Dhofar Insurance Co. (SAOG).'
          :
          'أوافق/نوافق على أن هذا الطلب يشكل أساس العقد المبرم بيني/بيننا وشركة ظفار للتأمين (ش.م.ع.ع).'}
      </p>

      <p className={lnstr}><input type="checkbox" style={{ marginRight: '5px' }} checked={outInboundfinalChecked1}
        onChange={(event) => {
          setoutfinalDeclarationChecked1(event.target.checked);
        }} name="note" />
        {(lnstr === 'en') ? 'I/We agree to abide by the terms and conditions set out in the Policy.'
          :
          'أوافق/نوافق على الالتزام بالشروط والأحكام المنصوص عليها في السياسة.'}
      </p>

      <p className={lnstr}><input type="checkbox" style={{ marginRight: '5px' }} checked={outInboundfinalChecked2}
        onChange={(event) => {
          setoutfinalDeclarationChecked2(event.target.checked);
        }} name="note" />
        {(lnstr === 'en') ? 'I/We hereby declare that information entered in this application is valid/correct and to the best of my/our knowledge.'
          :
          'أقر/نحن بموجب هذا أن المعلومات المدخلة في هذا الطلب صالحة/صحيحة وعلى حد علمي/علمنا.'}
      </p>
      <div>
        {!done1 && (
          <button type="button"
            style={{ backgroundColor: '#007bff', borderColor: '#007bff', fontWeight: 'bold' }}
            className="rounded-pill text-white my-3 py-1 " onClick={async () => {

              setoutboundTravellersError("");
              setOutBoundCoverageError("");
              setOutbounddaysError("");
              setOutboundProductError("");
              setOutboundDateError("");
              setOutboundFnameError("");
              setOutboundLnameError("");
              setOutboundDobError("");
              setOutboundEmailError("");
              setOutboundpassportError("");
              setOutboundNationalityError("");
              setOutboundPhoneError("");
              setOutboundGenderError("");
              setOutboundMaritalError("");
              setOutboundPlanDetailsError("");
debugger 
              console.log(personalInfo);
              if (!selectedOutboundtravellers) {
                setoutboundTravellersError(lnstr === "en" ? "Please select a Travellers option." : "الرجاء تحديد خيار المسافرين.");
                document.getElementsByName("outbound_traveller")[0].focus();

              } else if (!selectedOutboundNumberOfDays) {
                setOutbounddaysError(lnstr === "en" ? "Please select the number of days." : "يرجى اختيار عدد الأيام.");
                document.getElementsByName("outbound_numberofdays")[0].focus();

              } else if (!selectedOutboundProductOption) {
                setOutboundProductError(lnstr === "en" ? "Please select a Product." : "الرجاء تحديد المنتج.");
                document.getElementsByName("outbound_product_selection")[0].focus();

              } else if (!selectedOutboundCoverageOption) {
                document.getElementsByName("outbound_coverageselection")[0].focus();
                setOutBoundCoverageError(lnstr === "en" ? "Please select a Coverage Selection" : "الرجاء تحديد اختيار التغطية")

              } else if (!selectedOutboundStartDate) {
                document.getElementsByName("outbound_insurancestartdate")[0].focus();
                setOutboundDateError("Please select a Start Date.");

              } else if (selectedOutboundStartDate < formattedDate) {
                document.getElementsByName("outbound_insurancestartdate")[0].focus();
                setOutboundDateError("Please select a Valid Start Date.");
              } else if (!validateForm()) {
                return
              } else if (!outboundPlanDetails) {
                alert(lnstr === 'en' ? "Please check the Plan Details" : "يرجى التحقق من تفاصيل الخطة")
              } else if (selectedoutboundQuestionOption == 'Yes') {
                alert("Please select the Answer No in question 1");
              }else if (selectedoutboundQuestionOption1 == 'No') {
                alert("Please select the Answer Yes in question 2");
              }  else if (outboundisSignatureEmpty) {
                alert("Please Put the Signature");
              } else if (!outInboundfinalChecked) {
                alert("Please ensure the declaration")
              } else if (!outInboundfinalChecked1) {
                alert("Please ensure the declaration")
              } else if (!outInboundfinalChecked2) {
                alert("Please ensure the declaration")
              } else {

                const fnum = phoneaValue + '' + personalInfo.phone;

                const desiredLength = 12;

                const padToTwelveDigits = (number) => {
                  const zerosToAdd = desiredLength - number.length;

                  if (zerosToAdd < 0) {
                    const pn = personalInfo.phone;

                    const zToAdd = desiredLength - pn.length;

                    const paddedNumber = '0'.repeat(zToAdd) + pn;

                    return paddedNumber;
                  }
                  else {
                    const paddedNumber = '0'.repeat(zerosToAdd) + number;

                    return paddedNumber;
                  }



                };
                const twelveDigitNumber = padToTwelveDigits(fnum);
                setPaymentLoading(true);
debugger
                var travel_insurance_token_response = await fetch(`${base_url}/create_sanad_personal_accident_token`, { method: 'GET', headers: { 'Authorization': `Bearer ${vendorKey}` } });
                const travel_insurance_token_response_data = await travel_insurance_token_response.json();
                if (travel_insurance_token_response_data.message == 'success') {
                  var travel_insurance_token = travel_insurance_token_response_data?.result?.data?.token;
                }

                var outbounddetails = {
                  "token": travel_insurance_token,
                  "sanad_username": sanadUserName,
                  "sanad_requesteename": sanadRequesteeName,
                  "sanad_reference_no": sanadReferenceNumber,
                  "sanad_civil_no": sanadCivilIDNumber,
                  "sanad_passport_no": personalInfo.passportNumber,
                  "sanad_service_requestid": travelrequestid,
                  "sanad_data": {
                    "sanadUserName": sanadUserName,
                    "sanad_requesteename": sanadRequesteeName,
                    "sanadUserMobileNumber": sanadUserMobileNumber,
                    "sanadReferenceNumber": sanadReferenceNumber,
                    "sanadCivilIDNumber": sanadCivilIDNumber,
                    "sanad_ServiceRequestId": travelrequestid,
                    "sanad_TransDate": sanadTransDate,
                    "sanad_ReturnUrl": sanadReturnUrl,
                    "sanad_ErrorUrl": sanadErrorUrl,
                    "sanad_BackUrl": sanadBackUrl,
                    "sanad_Language": sanadLanguage,
                    "sanad_passport_no": personalInfo.passportNumber
                  },
                  "policy_data": {
                    "outbound_product_type_id": "2",
                    "outbound_product_id": selectedOutboundProductOptionid,
                    "outbound_product_name": selectedOutboundProductOption,
                    "outbound_coverage_id": selectedOutboundCoverageOptionid,
                    "outbound_coverage_name": selectedOutboundCoverageOption,
                    "outbound_travel_destinations": [
                      {
                        "countryID": selectedcaOptionid.toString(),
                        "countryName": selectedcaOption
                      }
                    ],
                    "outbound_period_type_id": 1,
                    "outbound_period_value_id": 4,
                    "cumulative_days": selectedOutboundNumberOfDays,
                    "outbound_travel_start_date": selectedOutboundStartDate,
                    "beneficiaries": outboundFormRows,
                    "noActualCircumstances": selectedoutboundQuestionOption,
                    "inGoodHealth": selectedoutboundQuestionOption1,
                    "amount": outboundPlanDetails.amount,
                    // "signature": outsignatureRef.current.toDataURL()
                    "signature": outboundSignatureValue
                  }

                };

                // var inbound_Policynumber_response = await fetch(`${base_url}/getsanad_outbound_addpolicy`, { method: 'POST', headers: { 'Authorization': `Bearer ${vendorKey}`, 'Content-Type': 'application/json' }, body: JSON.stringify(outbounddetails) });

                // var inbound_PolicyNo_response_data = await inbound_Policynumber_response.json();


                // if (inbound_PolicyNo_response_data.message == 'success') {

                //   if (inbound_PolicyNo_response_data.result == "sanad reference number already exist") {
                //     alert("sanad reference number already exist")
                //     setLoading(false);
                //   } else {
                //     // SetPolicyNo(inbound_PolicyNo_response_data.result.policy_number);
                //     var get_policy_number = inbound_PolicyNo_response_data.result.policy_number;
                //     alert(inbound_PolicyNo_response_data.result.policy_number);
                //   }

                // } else {

                //   if (inbound_PolicyNo_response_data.result.message == 'The dob field is mandatory.') {
                //     window.alert('Please Select Date of Birth');
                //   }
                // }

                setOutBoundPolicyDetails(outbounddetails);
                setOutboundPage(false);
                setViewOutBoundSummary(true);
                // setPaymentLoading(true);
                window.scrollTo(0, 0);
                setPaymentLoading(false);


              }


            }}
          >
            Get Summary
          </button>
        )}

      </div>
    </div>
  </div>
</div>
</div>

        )}



        {notomanvalue && (<div>
          <h1 style={{ color: 'red', textAlign: 'center' }}>The outbound plans are available only for residents of Oman.</h1>
        </div>)}


        {viewoutboundsummary && (
          <div>
            <div style={styles.container}>
              <div style={styles.card}>
                <h4 className="px-0 py-2">Summary</h4>

                <div className="colmds-4 card m-0">

                  <Row className="py-2" style={{ direction: 'ltr' }}>

                    <Col md={6} className="px-4">

                      Travel Type<br />

                      <b>Outbound</b><br /><br />


                      Coverage Type<br />

                      <b>{selectedOutboundCoverageOption}</b><br /><br />


                      Travel Start Date<br />

                      <b>{(selectedOutboundStartDate) ? format(new Date(selectedOutboundStartDate), 'dd-MM-yyyy') : ''}</b><br /><br />




                    </Col>

                    <Col md={6} className="px-4">

                      Travel Duration<br />

                      <b>{selectedOutboundNumberOfDays}</b><br /><br />


                      Traveller Type<br />

                      <b>{selectedOutboundtravellers}</b><br /><br />

                      Plan<br />

                      <b>{selectedOutboundProductOption}</b><br /><br />

                    </Col>

                    <br /><br />

                  </Row>

                </div>

                <br />

                <b>Traveller Details</b><br />

                {outboundFormRows && outboundFormRows.map((prof, index) => (

                  <div style={{ overflowX: 'auto' }}>

                    {(outboundFormRows.length > 1) ? <b>Traveller - {index + 1}</b> : ''}

                    <table className="table table-bordered table-condenced">

                      <tbody>

                        <tr><td>First Name</td><td><b>{prof.first_name}</b></td>
                          <td>Last Name</td><td><b>{prof.last_name}</b></td></tr>

                        <tr><td>Email</td><td><b>{prof.email}</b></td>
                          <td>Mobile</td><td><b>{prof.mobile_number}</b></td></tr>

                        <tr><td>Passport</td><td><b>{prof.passport_number}</b></td>
                          <td>Relation</td><td><b>Principal</b></td></tr>

                        <tr><td>Gender</td><td><b>{prof.gender_value}</b></td>
                          <td>Marital Status</td><td><b>{prof.marital_status_value}</b></td></tr>

                        <tr><td>DOB</td><td><b>{prof.dob}</b></td>
                          <td>Nationality</td><td><b>{prof.nationality.split("#")[0]}</b></td></tr>

                      </tbody>

                    </table>

                  </div>

                ))}



                <br />

                <b>Premium Details</b>

                <table className="table table-bordered table-condenced" style={{ direction: 'ltr' }}>

                  <tbody>

                    <tr><td>
                      Basic premium</td><td><b>{outboundPlanDetails?.amount?.base_price} OMR</b></td></tr>

                    <tr><td>
                      Policy Fee</td><td><b>{outboundPlanDetails?.amount?.policy_fee} OMR</b></td></tr>

                    <tr><td>
                      Government Fee</td><td><b>{outboundPlanDetails?.amount?.government_fee} OMR</b></td></tr>

                    <tr><td>
                      Emergency Fund Fee</td><td><b>{outboundPlanDetails?.amount?.emergency_fund_fee} OMR</b></td></tr>

                    <tr><td>
                      VAT</td><td><b>{outboundPlanDetails?.amount?.vat} OMR</b></td></tr>

                    <tr><td>
                      Total</td><td><b>{outboundPlanDetails?.amount?.total} OMR</b></td></tr>


                  </tbody>

                </table>
              </div>


              <div className="d-flex  my-3">

                <button className="btn btn-primary" style={{ padding: '10px 30px', marginRight: '10px', backgroundColor: '#007bff' }} onClick={async () => {
                  setViewOutBoundSummary(false);
                  setOutboundPage(true);
                }}>Edit</button>

                <button className="btn btn-primary" style={{ padding: '10px 30px', backgroundColor: '#007bff' }} onClick={async () => {

                  setLoading(true);

                  // var travel_insurance_token_response = await fetch(`${base_url}/create_outbound_token`, { method: 'GET', headers: { 'Authorization': `Bearer ${vendorKey}` } });
                  // const travel_insurance_token_response_data = await travel_insurance_token_response.json();
                  // if (travel_insurance_token_response_data.message == 'success') {
                  //   var travel_insurance_token = travel_insurance_token_response_data?.result?.data?.token;
                  // }

debugger
                  var outbound_Policynumber_response = await fetch(`${base_url}/getsanad_outbound_addpolicy`, { method: 'POST', headers: { 'Authorization': `Bearer ${vendorKey}`, 'Content-Type': 'application/json' }, body: JSON.stringify(outboundpolicydetails) });

                  var outbound_PolicyNo_response_data = await outbound_Policynumber_response.json();


                  if (outbound_PolicyNo_response_data.message == 'success') {
                    if(outbound_PolicyNo_response_data.result == "sanad reference number already exist"){
                      alert("sanad reference number already exist")
                      setLoading(false);
                    } else{

                    SetOutBoundPolicyNo(outbound_PolicyNo_response_data.result.policy_number);
                    var get_outBound_policy_number = outbound_PolicyNo_response_data.result.policy_number;

                    const redirect_url = redirecturl + "/sanadtravel-outbound-downloadpolicy?policyno=" + get_outBound_policy_number;
                  // const getPaymenntData = [{ token: travelInsuranceToken }, { policy_number: get_outBound_policy_number }, { redirect_url: redirect_url }];
                  const getPaymenntData = {
                    "token": travelInsuranceToken,
                    "policy_no":get_outBound_policy_number,
                    "redirect_url": redirect_url
                  };
                  var outbound_Payment_response = await fetch(`${base_url}/getsanad_outbound_paymentlink`, { method: 'POST', headers: { 'Authorization': `Bearer ${vendorKey}`, 'Content-Type': 'application/json' }, body: JSON.stringify(getPaymenntData) });

                  var outboundbound_Payment_response_data = await outbound_Payment_response.json();

                  // setLoading(false);
                  if (outboundbound_Payment_response_data.message == 'success') {

                    setOutBoundPaymetLink(outboundbound_Payment_response_data.result?.data?.payment_url);
                    setLoading(false);
                    window.location.href = outboundbound_Payment_response_data.result?.data?.payment_url;
                  } else {

                    if (outboundbound_Payment_response_data.result.message == 'The dob field is mandatory.') {
                      window.alert('Please Select Date of Birth');
                    }
                  }
                }
                  } else {

                    if (outbound_PolicyNo_response_data.result.message == 'The dob field is mandatory.') {
                      window.alert('Please Select Date of Birth');
                    }
                  }

                  // payment Link
                
                  // const redirect_url = redirecturl + "/sanadtravel-outbound-downloadpolicy?policyno=" + outboundpolicy_no;
                  // // const getPaymenntData = [{ token: travelInsuranceToken }, { policy_number: get_outBound_policy_number }, { redirect_url: redirect_url }];
                  // const getPaymenntData = {
                  //   "token": travelInsuranceToken,
                  //   "policy_no":get_outBound_policy_number,
                  //   "redirect_url": redirect_url
                  // };
                  // var outbound_Payment_response = await fetch(`${base_url}/getsanad_outbound_paymentlink`, { method: 'POST', headers: { 'Authorization': `Bearer ${vendorKey}`, 'Content-Type': 'application/json' }, body: JSON.stringify(getPaymenntData) });

                  // var outboundbound_Payment_response_data = await outbound_Payment_response.json();

                  // // setLoading(false);
                  // if (outboundbound_Payment_response_data.message == 'success') {

                  //   setOutBoundPaymetLink(outboundbound_Payment_response_data.result?.data?.payment_url);
                  //   setLoading(false);
                  //   window.location.href = outboundbound_Payment_response_data.result?.data?.payment_url;
                  // } else {

                  //   if (outboundbound_Payment_response_data.result.message == 'The dob field is mandatory.') {
                  //     window.alert('Please Select Date of Birth');
                  //   }
                  // }

                }}   > Proceed to Payment</button>

              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <Fragment>
      <div className="header_section1">

        {/*
        <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">
            <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'Travel Insurance' : 'تأمين السفر\n'}</h3>
          </div>
        </div> */}


      </div>


      <div className="features_section" style={styles.section}>
        <div className="container" style={styles.container}>
          {/* {SelectTravelPlanOption()} */}

          <AnimatePresence>
            {/* Animate both entering and exiting when travelPlan changes */}
            <motion.div
              key={travelrequestid} // Using travelPlan as key to trigger animation when it changes
              style={styles.motionSection}
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }} // Adjust exit animation
              transition={{ duration: 0.5 }}
            >
              {travelrequestid === '108' ? InboundTravelPlan() : OutboundTravelPlan()}
            </motion.div>
          </AnimatePresence>
        </div>
      </div>

      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}

      {plan_detail_loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}

      {paymet_loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}

    </Fragment>
  );
}

export default SandTravelInsurance;
