import React, { Fragment, useState, useEffect } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { useTranslation } from 'react-i18next';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
const { config } = require('../api/api');


function AddBeneficiary() {
    const base_url = config.baseURL;

    const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';


    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [error , setErrorValue] = useState('');
    const [error1, setError1Valule] = useState('');

    const [dhofarData, setDhofarData] = useState(false);
    const [age, setAge] = useState(null);
    useEffect(() => {


        const storedDhofarString = Cookies.get('personalData');
        if (storedDhofarString !== undefined) {
            const storedDhofar = JSON.parse(storedDhofarString);
            setDhofarData(storedDhofar);
        }
        else {
            alert("token expired");
            window.location.href='/';
        }

    }, []);


    const [vendorKey, setVendorkey] = useState('');

    useEffect(() => {

        const postvData = { organization_name: "Superjet" };
        debugger
    
        if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {
    
    
          fetch( base_url + '/create_organization', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(postvData),
          })
            .then(response => response.json())
            .then(data => {
              setVendorkey(data.result[0].vendor_key);
            })
            .catch(error => {
              console.error('Error posting data:', error);
            });
    
    
        }
      }, [vendorKey]); // Dependency array


    const [buttonDisabled, setButtonDisabled] = useState(false);
    const postData = { token: dhofarData };
    // const today = new Date().toISOString().split('T')[0];
    const today = new Date();
    const maxDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate() + 1).toISOString().split('T')[0];


    const [goptions, setGender] = useState([]);
    const [relations, setRelation] = useState([]);

    useEffect(() => {
        if (vendorKey !== '') {

            fetch(base_url + '/get_personal_accident_relations', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${vendorKey}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(postData),
            })
                .then(response => response.json())
                .then(data => {

                    setRelation(data.result.data);
                    document.getElementsByName('name')[0].focus();

                })
                .catch(error => {
                    console.error('Error posting data:', error);
                });

        }
    }, [vendorKey]
    );


    useEffect(() => {
        debugger
        const beneficiaryjson = sessionStorage.getItem('personalaccidentbeneficiaries');
        if (beneficiaryjson != undefined) {
            const beneficiary = JSON.parse(beneficiaryjson);
            //   setSelectedGender(value.basicinfo_gender_detail);



        }

    }, []);


    const [isChecked, setIsChecked] = useState(false);
    const [benefity, setbeneficiarycheck] = useState(false);
    const [totpercentage, settotpercentage] = useState();

    const [name, setName] = useState('');

    const [selectedRelation, setSelectedRelation] = useState('');

    const handleCheckboxChange = (event) => {
        setName(event.target.value);
    };




    const handleInputChange = async (index, event, names, field) => {
        setbeneficiarycheck(false);
        // console.log(event);;
        // const { name, value } = event.target;
        const rows = [...formRows];

        rows[index][field] = event.target.value;

        debugger

        if (names === 'name') {
            rows[index]['name'] = event.target.value;
        }
        else if (names === 'relation') {
            rows[index]['relation'] = event.target.value;
        }
        else if (names === 'benefit_percentage') {
            debugger
            const currentTotal = rows.reduce((total, person, idx) => {
                if (idx !== index) {
                    return total + parseInt(person.benefit_percentage, 10);
                } else {
                    return total;
                }
            }, 0);

            if (currentTotal + parseInt(event.target.value, 10) > 100) {
                // alert("Total percentage is not valid");
                setError1Valule("Total percentage is not valid")


                rows[index]['benefit_percentage'] = '';

            } else {
                setError1Valule("")

                settotpercentage(currentTotal + parseInt(event.target.value, 10));

                rows[index]['benefit_percentage'] = event.target.value;
            }

        }

        setFormRows(rows);
    };

    debugger
    const [formRows, setFormRows] = useState([{


        name: '',
        relation: '',
        benefit_percentage: '',

    }]);

    const lastNameRef = React.createRef();
    const last1NameRef = React.createRef();
  
    const handleKeyDown = (e, nextInputRef) => {
      if (e.key === 'Enter') {
        e.preventDefault(); 
        if (nextInputRef.current) {
          nextInputRef.current.focus(); 
        }
      }
    };
  
    const handleKeyDown1 = (e, nextInputRef) => {
      if (e.key === 'Enter') {
        e.preventDefault(); 
        if (nextInputRef.current) {
          nextInputRef.current.focus(); 
        }
      }
    };

    const handleRemoveRow = (index) => {
        let totalBenefitPercentage = 0;
        const rows = [...formRows];
        rows.splice(index, 1);
        for (let i = 0; i < rows.length; i++) {
            if (rows[i].benefit_percentage !== '') {
                totalBenefitPercentage += parseInt(rows[i].benefit_percentage);
            }
        }
        settotpercentage(totalBenefitPercentage);
        console.log("Total Benefit Percentage:", totalBenefitPercentage);
        setFormRows(rows);
    };

    const handleAddRow = () => {
        setFormRows([...formRows, {
            name: '',
            relation: '',
            benefit_percentage: '',

        }]);

        formRows.forEach((row, index) => {
            if (!row.name || !row.relation || row.relation === 'Select Relation' || row.relation === 'relation'|| (formRows.length > 1 && (!row.benefit_percentage || row.benefit_percentage < 1 || row.benefit_percentage > 100))) {
                // isFormValid = false;

                // Focus on the first empty field
                const element = document.querySelector(`[name="${!row.name ? 'name' : (!row.relation || row.relation === 'relation'|| row.relation === 'Select Relation' ? 'relation' : 'benefit_percentage')}"][data-index="${index}"]`);
                if (element) {
                    element.focus();
                }
                return; // Exit the loop after finding the first invalid field
            }
        });
    };

    useEffect(() => {
        const beneficiariesData = sessionStorage.getItem('personalaccidentbeneficiaries');
        if (beneficiariesData) {
            const parsedData = JSON.parse(beneficiariesData);
            setFormRows(parsedData.beneficiaries);
        }
    }, []);








    return (
        <Fragment>

            <div className="header_section1">

                <Navbar />


                <div className="container-fluid bg-breadcrumb">
                    <div className="container text-center py-5">
                        <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'Add Beneficiary ' : 'إضافة مستفيد\n'}</h3>
                    </div>
                </div>


            </div>


            <div className="features_section">
                <div className="container">

                    <div className="row">

                        <Col md={3}></Col>

                        <Col md={6} className="myforms my-5">



                            <div>

                                <form  onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    e.preventDefault(); 
                }
            }}>
                                    {formRows.map((row, index) => (
                                        <div key={index}>
                                            <div  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}  >
                                            <h4>{(lnstr === 'en') ? 'Beneficiary ' : 'تفاصيل المسافر'}{index + 1}</h4>
                                            <p>{formRows.length !== 1 && (
        <button type="button"
            // disabled={formRows.name != ''}
            style={{
                backgroundColor: '#FF8488', border: 'none',padding: '0px 5px',borderRadius: '10px',cursor: 'pointer', marginTop: "10px" }}
            onClick={() => handleRemoveRow(index)}>
            {/* Remove */}<i   style={{ color: 'white' }}>X</i>
        </button>
    )}</p>
                                            </div>
                                            
                                            <div className="input-group mb-2 mt-2">
                                                <div className="input-group-prepend prepends">
                                                    <span className="input-group-text">{t('Name')} <span style={{color:'red', marginLeft: '5px'}}>*</span></span>
                                                </div>
                                                <input type="text" name="name" className="form-control" value={row.name} data-index={index}
                                                    onChange={(e) => handleInputChange(index, e, 'name')}   onInput={(e) => {
                                                        e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, '');
                                                      }} onKeyDown={(e) => handleKeyDown(e, lastNameRef)}
                                                    required />
                                            </div>





                                            {formRows.length !== 1 && (<div className="input-group mb-2 mt-2">
                                                <div className="input-group-prepend prepends">
                                                    <span className="input-group-text">{(lnstr == 'en') ? 'Benefit Percentage' : 'نسبة الاستفادة'}</span>
                                                </div>
                                                <input type="number" name="benefit_percentage" min={0} max={100} inputMode="numeric" className="form-control" value={row.benefit_percentage} onChange={(e) => handleInputChange(index, e, 'benefit_percentage')} onKeyDown={(e) => handleKeyDown1(e, last1NameRef)} ref={lastNameRef} data-index={index}
                                                    required />
                                            </div>)}

                                            <div className="input-group mb-2 mt-2">
                                                <div className="input-group-prepend prepends">
                                                    <span className="input-group-text">{t('Relation')} <span style={{color:'red', marginLeft: '5px'}}>*</span></span>
                                                </div>
                                                <select name="relations" className="form-control" data-index={index} style={{ direction: 'ltr' }} value={row.relation} 
                                                    onChange={(e) => handleInputChange(index, e, 'relation')} ref={last1NameRef}
                                                    required> 

                                                    <option value = ''>Select Relation</option>


                                                    {relations.map((relation, index) => (
                                                        <option key={index} value={relation}>
                                                            {relation.charAt(0).toUpperCase() + relation.slice(1)}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    ))}





                                </form>
                                {error && <span style={{color:"red"}}>{error}</span>}
                                {error1 && <span style={{color:"red"}}>{error1}</span> }
                                <div className="d-flex  my-3">
                                    <button type="button"
                                        className="btn btn-primary rounded-pill text-white my-3 py-1 px-4"
                                        onClick={handleAddRow}>
                                        Add Beneficiary
                                    </button>
                                    <button type="button"
                                        className="btn btn-primary rounded-pill text-white my-3 mx-2  py-1 px-4"
                                        // disabled={isDisabled}
                                        onClick={async () => {


                                            if (formRows.length == 1) {
                                                formRows[0].benefit_percentage = '100';
                                                setFormRows(formRows);
                                            }
                                            debugger


                                                // debugger
                                                // if ((formRows.length === 1 && formRows[0].name === '') || (formRows.length === 1 && formRows[0].relation === '')) {
                                                //     // setbeneficiarycheck(true);
                                                //     if (formRows[0].name === ''){
                                                //         document.getElementsByName('name')[0].focus();
                                                //     }else{
                                                //         document.getElementsByName('relations')[0].focus();
                                                //     }
                                                // }else if(formRows.length > 0) {
                                                //     if (formRows.length > 1) {
                                                //         for (let i = 0; i < formRows.length; i++) {
                                                //             if (formRows[i].name === '' || formRows[i].relation === '' || formRows[i].benefit_percentage === '') {
                                                //                 setbeneficiarycheck(true);
                                                //                 setErrorValue("Please enter the right benefit percentage")
                                                //             }else{
                                                //                 setErrorValue("")
                                                //             }};
                                                        
                                                //             formRows.forEach((row, index) => {
                                                //             if (!row.name || !row.relation || row.relation === 'Select Relation' || row.relation === 'relation'|| (formRows.length > 1 && (!row.benefit_percentage || row.benefit_percentage < 1 || row.benefit_percentage > 100))) {
                                                //                 // isFormValid = false;
                                                
                                                //                 // Focus on the first empty field
                                                //                 const element = document.querySelector(`[name="${!row.name ? 'name' : (!row.relation || row.relation === 'relation'|| row.relation === 'Select Relation' ? 'relations' : 'benefit_percentage')}"][data-index="${index}"]`);
                                                //                 if (element) {
                                                //                     element.focus();
                                                //                 }
                                                //                 return; // Exit the loop after finding the first invalid field
                                                //             }
                                                //         });

                                                        


                                                //     }else if ((totpercentage < 100 || totpercentage > 100) && formRows.length > 1) {
                                                //         // alert("total percentage is invalid");
                                                //         setError1Valule("Total benefit percentage is invalid")
                                                //         return;
                                                //     }else{
                                                //         debugger


                                                // var data = {
                                                //     'beneficiaries': formRows
                                                // }
    
                                                // debugger
    
                                                // sessionStorage.setItem("personalaccidentbeneficiaries", JSON.stringify(data))
    
    
                                                // window.location.href = '/personalinformation';
                                                //         setError1Valule("")
                                                //     }
                                                // }



                                                if ((formRows.length === 1 && !formRows[0].name) || (formRows.length === 1 && !formRows[0].relation)) {
                                                    if (!formRows[0].name) {
                                                        document.getElementsByName('name')[0].focus();
                                                    } else {
                                                        document.getElementsByName('relations')[0].focus();
                                                    }
                                                    return; // Exit the function after focusing on the first empty field
                                                }
                                            
                                                // Check for multiple rows
                                                var total = 0;
                                                if (formRows.length > 0) {
                                                    // Loop through form rows and check for invalid fields
                                                    for (let i = 0; i < formRows.length; i++) {
                                                        const row = formRows[i];
                                                        total = parseInt(total) + parseInt(row.benefit_percentage);
                                                        // If any field is invalid, set error and focus on the first invalid field
                                                        if (!row.name || !row.relation || row.relation === 'Select Relation' || row.relation === 'relation' || !row.benefit_percentage || row.benefit_percentage < 1 || row.benefit_percentage > 100) {
                                                            setbeneficiarycheck(true);
                                                            // setErrorValue("Please enter the right benefit percentage");
                                                            
                                                            // Focus on the first invalid field
                                                            const invalidField = document.querySelector(`[name="${!row.name ? 'name' : (!row.relation || row.relation === 'relation' || row.relation === 'Select Relation' ? 'relations' : 'benefit_percentage')}"][data-index="${i}"]`);
                                                            if (invalidField) {
                                                                invalidField.focus();
                                                            }
                                                            return; // Exit the function after focusing on the first invalid field
                                                        }
                                                    }
                                            
                                                    // Check if the total benefit percentage is 100
                                                    // const totpercentage = formRows.reduce((total, row) => total + (row.benefit_percentage || 0), 0);
                                                    if (total !== 100 && formRows.length > 1) {
                                                        setError1Valule("Total benefit percentage is invalid");
                                                        return; // Exit the function if total percentage is not 100
                                                    }
                                            
                                                    // If everything is valid, save data and redirect
                                                    const data = { 'beneficiaries': formRows };
                                                    sessionStorage.setItem("personalaccidentbeneficiaries", JSON.stringify(data));
                                                    window.location.href = '/personalinformation';
                                                    setError1Valule(""); // Clear any error for valid form
                                                }

                                            // else {

                                            //     debugger


                                            //     var data = {
                                            //         'beneficiaries': formRows
                                            //     }
    
                                            //     debugger
    
                                            //     sessionStorage.setItem("personalaccidentbeneficiaries", JSON.stringify(data))
    
    
                                            //     window.location.href = '/personalinformation';

                                            // }



                                           

                                        }}>

                                        Next
                                    </button>
                                </div>


                            </div>


                        </Col>

                        <Col md={3}></Col>

                    </div>

                </div>
            </div>

            <Footer />

            {loading && (
                <div className="overlay">
                    <div className="spinner"></div>
                </div>
            )}

        </Fragment>
    );
}

export default AddBeneficiary;
