import React, { Fragment, useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import myLoader from "./loaderred.gif";
import download from "./download.png";
import { format } from "date-fns";
import { Button, Dropdown } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
const { config } = require("../src/api/api");

const Myinsurance = ({ usrtok }) => {
  const base_url = config.baseURL;
  const redirectURL = config.redirectURL;
  const { t } = useTranslation();

  const [userData, setUserData] = useState(false);
  const [vendorKey, setVendorKey] = useState(null);
  const [insuranceDetails, setInsuranceDetails] = useState([]);
  const [show, setShow] = useState(false);
  const [insuranceData, setInsuranceData] = useState(null);

  if (!Cookies.get("userData")) {
    alert("Login Session Expired");
    window.location.href = "/";
  }

  useEffect(() => {
    // debugger;

    const storedUserString = Cookies.get("userData"); //sessionStorage.getItem('userData')
    if (storedUserString !== "undefined") {
      const storedUser = JSON.parse(storedUserString);
      setUserData(storedUser);
    }
  }, []);

  usrtok = userData.access_token;

  useEffect(() => {
    const postvData = { organization_name: "Superjet" };
    if (vendorKey === "" || vendorKey === null || vendorKey === undefined) {
      fetch(base_url + "/create_organization", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postvData),
      })
        .then((response) => response.json())
        .then((data) => {
          setVendorKey(data.result[0].vendor_key);
        })
        .catch((error) => {
          console.error("Error Getting Vendor Key:", error);
        });
    }
  }, [vendorKey]); // Dependency array

  useEffect(() => {
    // debugger
    if (
      (vendorKey !== "" &&
      vendorKey !== null &&
      vendorKey !== undefined )&&
      (usrtok !== "" &&
      usrtok !== null &&
      usrtok !== undefined)
    ) {
      const Data = { token: usrtok, user_type: userData?.agency_name?"travel_agent":"traveller" };
      fetch(base_url + "/get_user_insurance_data", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${vendorKey}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(Data),
      })
        .then((response) => response.json())
        .then((data) => {
          setInsuranceDetails(data.result);
        })
        .catch((error) => {
          console.error("Error Getting Insurance Data:", error);
        });
    }
  }, [vendorKey, usrtok]); // Dependency array

  const handleClose = () => {
    setShow(false);
  };

  const handleInsuranceData = async (e) => {
    // debugger
    try {
      const policy = { policy_no: e.target.value };
      const response = await fetch(
        `${base_url}/get_personal_accident_policy_details`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${vendorKey}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(policy),
        }
      );
      if (response.ok) {
        const data = await response.json();
        if (data) {
          setInsuranceData(data.result[0]);
        }
      }
    } catch (err) {
      console.log("Error : " + err);
    }
    setShow(true);
  };

  return (
    <>
      <div className="header_section1">
        <Navbar />

        <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">
            <h3 className="abt-txt my-5">
              {t("MY")} {t("INSURANCE")}
            </h3>
          </div>
        </div>
      </div>

      <div className="features_section layout_padding my-1">
        <div className="container">
          <div className="row g-4 justify-content-center">
            <div className="col-12 wow fadeInUp" data-wow-delay="0.2s" style={{ overflowX: 'auto' }}>

            <table className="table table-bordered mt-3">
              <thead>
                <tr>
                  <th>#</th>
                  <th>
                    {t("First")} {t("Name")}
                  </th>
                  <th>
                    {t("Last")} {t("Name")}
                  </th>
                  <th>{t("Email")}</th>
                  <th>
                    {t("Mobile")}
                    {t("No")}
                  </th>
                  <th>{t("Passport")}</th>
                  <th>
                    {t("Policy")}
                    {t("No")}
                  </th>
                  <th>{t("Status")}</th>
                  <th>{t("view")}</th>
                </tr>
              </thead>
              <tbody>
                {insuranceDetails?.map((insurance, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{insurance.first_name}</td>
                      <td>{insurance.last_name}</td>
                      <td>{insurance.email}</td>
                      <td>{insurance.mobile_no}</td>
                      <td>{insurance.passport}</td>
                      <td>{insurance.policy_no}</td>
                      <td>{insurance.status}</td>
                      <td>
                        <Button
                          variant="primary"
                          style={{ fontWeight: "normal" }}
                          className="btn btn-primary rounded-pill text-white py-1 px-2"
                          onClick={handleInsuranceData}
                          value={insurance.policy_no}
                        >
                          {t("View")}
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            </div>
          </div>
        </div>
      </div>

      <Footer />
      {show && (
        <div className="modal">
          <div
            className="modal-content"
            style={{ height: "525px", width: "700px" }}
          >
            <h2>
              <span>
                {t("Insurance")} {t("Status")}
              </span>
            </h2>
            <button type="button" className="close" onClick={handleClose}>
              &times;
            </button>
            <div style={{ height: "50px" }}></div>
            <div>
              <table style={{ marginLeft: "65px", width: "250" }}>
                <tr>
                  <td style={{ fontWeight: "bold", textAlign: "left" }}>
                    {t("First")} {t("Name")}
                  </td>
                  <td style={{ fontWeight: "bold", textAlign: "left" }}>&nbsp;:&nbsp;</td>
                  <td style={{ textAlign: "left" }}>
                    <b>{insuranceData?.first_name}</b>
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "bold", textAlign: "left" }}>
                    {t("Last")} {t("Name")}
                  </td>
                  <td style={{ fontWeight: "bold", textAlign: "left" }}>&nbsp;:&nbsp;</td>
                  <td style={{ textAlign: "left" }}>
                    <b>{insuranceData?.last_name}</b>
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "bold", textAlign: "left" }}>
                    {t("Email")}
                  </td>
                  <td style={{ fontWeight: "bold", textAlign: "left" }}>&nbsp;:&nbsp;</td>
                  <td style={{ textAlign: "left" }}>
                    <b>{insuranceData?.email}</b>
                  </td>
                </tr>
                {/* <tr>
              <td style={{ fontWeight: 'bold', textAlign:'left' }}>{t('Date Of Birth')} </td>
              <td style={{ fontWeight: 'bold', textAlign:'left'}}>:
                </td><td style={{textAlign:'left'}}><b>{insuranceData?.policy_data?.dob}</b></td>
                </tr>
                <tr>
              <td style={{ fontWeight: 'bold', textAlign:'left' }}>{t('Gender')} </td>
              <td style={{ fontWeight: 'bold', textAlign:'left'}}>:
                </td><td style={{textAlign:'left'}}><b>{insuranceData?.policy_data?.inbound_gender_detail}</b></td>
                </tr> */}
                <tr>
                  <td style={{ fontWeight: "bold", textAlign: "left" }}>
                    {t("Mobile")} {t("No")}
                  </td>
                  <td style={{ fontWeight: "bold", textAlign: "left" }}>&nbsp;:&nbsp;</td>
                  <td style={{ textAlign: "left" }}>
                    <b>{insuranceData?.mobile_no}</b>
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "bold", textAlign: "left" }}>
                    {t("Passport")} {t("No")}
                  </td>
                  <td style={{ fontWeight: "bold", textAlign: "left" }}>&nbsp;:&nbsp;</td>
                  <td style={{ textAlign: "left" }}>
                    <b>{insuranceData?.passport}</b>
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "bold", textAlign: "left" }}>
                    {t("Policy")} {t("No")}
                  </td>
                  <td style={{ fontWeight: "bold", textAlign: "left" }}>&nbsp;:&nbsp;</td>
                  <td style={{ textAlign: "left" }}>
                    <b>{insuranceData?.policy_no}</b>
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "bold", textAlign: "left" }}>
                    {" "}
                    {t("Status")}
                  </td>
                  <td style={{ fontWeight: "bold", textAlign: "left" }}>&nbsp;:&nbsp;</td>
                  <td style={{ textAlign: "left" }}>
                    <b>{insuranceData?.status}</b>
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "bold", textAlign: "left" }}>
                    {t("Fee")}{" "}
                  </td>
                  <td style={{ fontWeight: "bold", textAlign: "left" }}>&nbsp;:&nbsp;</td>
                  <td style={{ textAlign: "left" }}>
                    <b>
                      {insuranceData?.fee ? `${insuranceData?.fee} OMR` : ""}
                    </b>
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "bold", textAlign: "left" }}>
                    {t("Payment")} {t("Status")}
                  </td>
                  <td style={{ fontWeight: "bold", textAlign: "left" }}>&nbsp;:&nbsp;</td>
                  <td style={{ textAlign: "left" }}>
                    <b>{insuranceData?.payment}</b>
                  </td>
                </tr>

                <tr>
                  <td style={{ fontWeight: "bold", textAlign: "left" }}>
                    {t("Insurance")}{" "}
                  </td>
                  <td style={{ fontWeight: "bold", textAlign: "left" }}>&nbsp;:&nbsp;</td>
                  <td style={{ textAlign: "left" }}>
                    <b>{insuranceData?.insurance_type}</b>
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "bold", textAlign: "left" }}>
                    {t("Insurance")} {t("Type")}{" "}
                  </td>
                  <td style={{ fontWeight: "bold", textAlign: "left" }}>&nbsp;:&nbsp;</td>
                  <td style={{ textAlign: "left" }}>
                    <b>{insuranceData?.type}</b>
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: "bold", textAlign: "left" }}>
                    {t("Created Date")}{" "}
                  </td>
                  <td style={{ fontWeight: "bold", textAlign: "left" }}>&nbsp;:&nbsp;</td>
                  <td style={{ textAlign: "left" }}>
                    <b>{insuranceData?.created_at}</b>
                  </td>
                </tr>
              </table>
              <button
                type="button"
                class="btn btn-sm btn-close"
                aria-label="Close"
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Myinsurance;
