import React, { Fragment, useState, useEffect,useRef } from "react";
import Navbar from "../../Navbar";
import Footer from "../../Footer";
import { useTranslation } from 'react-i18next';
import travelimg from '../../tr.png';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
import SignatureCanvas from 'react-signature-canvas';
const { config } = require('../../api/api');


function TermPlanUploadFiles() {
      const base_url = config.baseURL;
      const redirect_url = config.redirectURL;
      const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';
      const [loading, setLoading] = useState(false);
    
      const { t } = useTranslation();
    
      const [vendorKey, setVendorkey] = useState('');
      const [dhofarData, setDhofarData] = useState(false);
      const [downloadpolicy, setDownloadValue] = useState('');
      const [policyLink, setPolicyLink] = useState(null);
      const [policy_no, setPolicyNo] = useState(null);
      const [age, setAge] = useState(null);
    
      const [payment_link, setPaymentValue] = useState('');
    
      const [passportFile, setPassportFile] = useState(null);
      const [questionnaireFile, setQuestionnaireFile] = useState(null);
      const [showUploadModal, setShowUploadModal] = useState(false);
      const [fileChoosen,setFileChoosen]=useState(true);


      

  const handleSubmit = async (event) => {

    event.preventDefault();
    setLoading(true);

    event.preventDefault();
    window.location.href='/';

  

  };
  useEffect(() => {
     const createOrganization = async () => {
       try {
         const postvData = { organization_name: 'Superjet' };
 
         const response = await fetch(base_url + '/create_organization', {
           method: 'POST',
           headers: { 'Content-Type': 'application/json' },
           body: JSON.stringify(postvData)
         });
         const data = await response.json();
         setVendorkey(data.result[0].vendor_key);
       } catch (error) {
         console.error('Error posting data:', error);
       }
     };
 
     if (!vendorKey) {
       createOrganization();
     }
   }, [vendorKey, base_url]);
 
   useEffect(() => {
     const createToken = async () => {
       if (vendorKey) {
         try {
           const response = await fetch(base_url + '/create_personal_accident_token', {
             method: 'GET',
             headers: {
               Authorization: `Bearer ${vendorKey}`
             }
           });
           const data = await response.json();
           const dhdata = data.result.data.token;
           setDhofarData(dhdata);
           const personalquestionjson = sessionStorage.getItem('paymentlink');
            const paymentlinkvalues = JSON.parse(personalquestionjson);
            setPolicyNo(paymentlinkvalues.policy_no);
            setPolicyLink(paymentlinkvalues.paymentlink);
         } catch (error) {
           console.error('Error getting token:', error);
         }
       }
     };
 
     createToken();
   }, [vendorKey, base_url]);
 
//    useEffect(() => {
//      const fetchPolicyDetails = async () => {
//        if (vendorKey && dhofarData && policy_no) {
//          try {
//            const quotation_data = {
//              token: dhofarData,
//              policy_no: policy_no
//            };
 
//            const response = await fetch(base_url + '/get_personal_accident_policy_details', {
//              method: 'POST',
//              headers: {
//                Authorization: `Bearer ${vendorKey}`,
//                'Content-Type': 'application/json'
//              },
//              body: JSON.stringify(quotation_data)
//            });
 
//            const data = await response.json();
//            setDownloadValue(data.result[0]);
 
//            const postData1 = { token: dhofarData, policy_no: policy_no };
//            const response2 = await fetch(base_url + '/download_personalaccident_policy', {
//              method: 'POST',
//              headers: {
//                Authorization: `Bearer ${vendorKey}`,
//                'Content-Type': 'application/json'
//              },
//              body: JSON.stringify(postData1)
//            });
//            const data2 = await response2.json();
 
//            if (data2.message === 'success') {
//              // debugger;
//              setPolicyLink(data2.result.data.policy_schedule_url);
//            } else {
//              setPolicyLink('');
//            }
//          } catch (error) {
//            console.error('Error fetching policy details:', error);
//          }
//        }
//      };
 
//      fetchPolicyDetails();
//    }, [vendorKey, dhofarData, policy_no, base_url]);
 
//    const handleQotation = async (event) => {
//      // debugger;
 
//      var quotation_data = {
//        token: dhofarData,
//        policy_no: policy_no,
//        redirect_url: redirect_url + '/personalinformation-downloadpolicy?policyno=' + policy_no
//      };
//      // debugger;
//      if (vendorKey !== '') {
//        // const fetchPolicy = async () => {
//        try {
//          const Policyresponce = await fetch(base_url + '/create_personal_accident_payment_link', {
//            method: 'POST',
//            headers: {
//              Authorization: `Bearer ${vendorKey}`,
//              'Content-Type': 'application/json'
//            },
//            body: JSON.stringify(quotation_data)
//          });
 
//          if (!Policyresponce.ok) {
//            throw new Error('Network response was not ok');
//          }
//          // debugger;
 
//          const data = await Policyresponce.json();
 
//          setPaymentValue(data.result.data.payment_url);
//          window.location.href = data.result.data.payment_url;
//          //   await handlePolicy(data.result.data.amount)
//        } catch (error) {
//          console.error('Error fetching occupations:', error);
//        }
//        // };
//      }
//    };
 
//    const handleSubmit = async (event) => {
//      event.preventDefault();
 
//      window.location.href = '/insurance';
//    };
 
//    const handlerepayment = async (event) => {
//      // event.preventDefault();
//      handleQotation();
//    };
 
//    const isValidUrl = (url) => {
//      return pattern.test(url);
//    };
 
   const handleUpload = async (e) => {
     debugger;
     e.preventDefault();
     const dhofar = dhofarData;
     const policy = policy_no;
     const formData = new FormData();
     formData.append('token', dhofar);
     formData.append('policy_number', policy);
 
     if (passportFile !== null) {
       formData.append('passport', passportFile);
     }
 
     if (questionnaireFile !== null) {
       formData.append('questionnaire', questionnaireFile);
     }
 
     const response = await fetch(base_url + '/term_plan_file_upload', {
       method: 'POST',
       headers: {
         Authorization: `Bearer ${vendorKey}`
       },
       body: formData
     });
     const data = await response.json();
     if (data.result.success) {
       alert(data.result.message);
       window.location.href=policyLink
     } else {
       alert('file is not uploaded try again');
     }
   };
 
   const handleFileChange = (e) => {
     // debugger;
     if (e.target.files.length === 1) {
       const file = e.target.files[0];
       const name = e.target.name;
       const fileType = file.type;
       const maxSize = 2 * 1024 * 1024; // 5MB in bytes
 
       // Check if the file exceeds the size limit
       if (file && file.size > maxSize) {
         alert('File size exceeds the 2MB limit.');
         return;
       }
 
       if (fileType.startsWith('image') || fileType === 'application/pdf') {
         if (name === 'passport' && passportFile == null) {
           setPassportFile(file);
         } else if (name === 'passport' && passportFile !== null && passportFile !== '' && passportFile !== undefined) {
           setPassportFile(null);
           setPassportFile(() => file);
         } else if (name === 'questionnaire' && questionnaireFile == null) {
           setQuestionnaireFile(file);
         } else if (name === 'questionnaire' && questionnaireFile !== null && questionnaireFile !== '' && questionnaireFile !== undefined) {
           setQuestionnaireFile(null);
           setQuestionnaireFile(() => file);
         }
       } else {
         alert('Please select a valid image or PDF file.');
       }
       document.getElementById('passport').value = '';
       document.getElementById('questionnaire').value = '';
     }
   };
   const handleFileDelete = (e) => {
     // debugger;
     const name = e.target.getAttribute('data-name');
     if (name === 'questionnaire') {
       setQuestionnaireFile(null);
       document.getElementById('questionnaire').value = '';
     } else if (name === 'passport') {
       setPassportFile(null);
       document.getElementById('passport').value = '';
     }
   };
 
   const handleClose = () => {
     setShowUploadModal(false);
   };
 
   const handleShow = () => {
     setShowUploadModal(true);
   };
   useEffect(()=>{
   if((passportFile!==null&& passportFile!==undefined && passportFile!=="")&&
   (questionnaireFile!==null&& questionnaireFile!==undefined && questionnaireFile!=="")){
     setFileChoosen(false);
   }
   else{
     setFileChoosen(true);
   }
 },[passportFile,questionnaireFile]);
 

   


   


  return (
    <Fragment>

      <div className="header_section1">

        <Navbar />


        <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">
            <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'Upload Documents' : 'تحميل المستندات\n'}</h3>
          </div>
        </div>


      </div>


      <div className="features_section">
        <div className="container">

          <div className="row">

            <Col md={3}></Col>

            <Col md={6} className="myforms my-5">

            <div>
              <div >
              </div>
              <div className="modal-body">
                <form onSubmit={(e) => handleUpload(e).then(handleClose)}>
                  <div className="form-group row">
                    <label className="col-sm-12 col-md-8 col-form-label text-left">
                      <h4><strong>{t('Passport copy ')}</strong>
                      <br /> <small>{t('(Attach the visa page or resident ID)')}</small></h4>
                    </label>
                    <div className="col-sm-12 col-md-4">
                      {passportFile === null ? (
                        <button className="btn btn-primary" type="button" onClick={() => document.getElementById('passport').click()}>
                          {t('Upload File')}
                        </button>
                      ) : (
                        <div className="d-flex border mt-2 align-items-center p-2 rounded">
                          <p className="mb-0" style={{ width: '80%' }}>
                            {passportFile.name.length > 5
                              ? `${passportFile.name.substring(0, 5)}...${passportFile.type.split('/')[1]}`
                              : passportFile.name}
                          </p>
                          <span
                            className="text-danger ms-auto"
                            onClick={handleFileDelete}
                            data-name="passport"
                            style={{ cursor: 'pointer', width: '20%' }}>
                            &times;
                          </span>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="form-group row mt-3">
                    <label className="col-sm-12 col-md-8 col-form-label text-left">
                      <h4><strong>{t('Filled questionnaire')}</strong></h4>
                    </label>
                    <div className="col-sm-12 col-md-4">
                      {questionnaireFile === null ? (
                        <button className="btn btn-primary" type="button" onClick={() => document.getElementById('questionnaire').click()}>
                          {t('Upload File')}
                        </button>
                      ) : (
                        <div className="d-flex border mt-2 align-items-center p-2 rounded">
                          <p className="mb-0" style={{ width: '80%' }}>
                            {questionnaireFile.name.length > 5
                              ? `${questionnaireFile.name.substring(0, 5)}...${questionnaireFile.type.split('/')[1]}`
                              : questionnaireFile.name}
                          </p>
                          <span
                            className="text-danger ms-auto"
                            onClick={handleFileDelete}
                            data-name="questionnaire"
                            style={{ cursor: 'pointer', width: '20%' }}>
                            &times;
                          </span>
                        </div>
                      )}
                    </div>
                  </div>

                  <input
                    type="file"
                    name="passport"
                    id="passport"
                    accept="application/pdf,image/*"
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                  />
                  <input
                    type="file"
                    name="questionnaire"
                    id="questionnaire"
                    accept="application/pdf,image/*"
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                  />

                  <div className="mt-4 text-center">
                    <button type="submit" disabled={fileChoosen} className="btn btn-primary">
                      {t('Submit')}
                    </button>
                  </div>
                </form>
              </div>
            </div>


            </Col>

            <Col md={3}></Col>

          </div>

        </div>
      </div>

      <Footer />

      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}

    </Fragment>
  );
}

export default TermPlanUploadFiles;
