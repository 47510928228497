import React, { Fragment , useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useTranslation } from 'react-i18next';
import travelimg from './tr.png';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Select2Wrapper from './Select2Wrapperins';
import Cookies from 'js-cookie';
import './selectstyle1.css';
const { config } = require('../src/api/api');

function Inboundtravellerinfo() {
  const base_url = config.baseURL;
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';

    const [dhofarData, setDhofarData] = useState(false);  
    useEffect(() => {
    const storedDhofarString = Cookies.get('dhofarData'); //sessionStorage.getItem('userData')
      if (storedDhofarString!==undefined) {
        const storedDhofar = JSON.parse(storedDhofarString);
        setDhofarData(storedDhofar);
      }
      else
      {
        // alert("token expired");
        // window.location.href='/';
      }
    }, []);



    const [travelDetails, setTraveldetails] = useState(false);  
    useEffect(() => {
    const storedtravelString = sessionStorage.getItem('inboundtraveldetails'); //sessionStorage.getItem('userData')
      if (storedtravelString!==undefined) {
        const storedtraveld = JSON.parse(storedtravelString);
        setTraveldetails(storedtraveld);
      }
    }, []);


    const [travelInfo, setTravelInfo] = useState(false);  
    useEffect(() => {
    const storedtravelinfoString = sessionStorage.getItem('inboundtravelinfo'); //sessionStorage.getItem('userData')
      if (storedtravelinfoString!==undefined) {
        const storedtravelin = JSON.parse(storedtravelinfoString);
        setTravelInfo(storedtravelin);
      }
    }, []);



  const [emailError, setEmailError] = useState('');

  const handleEmailBlur = (e) => {
    const { value } = e.target;

    // Regular expression to validate a basic email format
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // Check if the email matches the pattern
    if (!emailPattern.test(value)) {
      document.getElementsByName('email')[0].focus();
      setEmailError('Please enter a valid email address. Example: example@domain.com');
      setemailValue('');
    } else {
      setEmailError(''); // Clear the error if valid
    }
  };


  const postData = { token: dhofarData };


  const [vendorKey, setVendorKey] = useState('');
  
    useEffect(() => {

      const postvData = { organization_name: "Superjet" };

      if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {
        
        
        fetch(base_url+'/create_organization', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(postvData),
          })
          .then(response => response.json())
          .then(data => {
            setVendorKey(data.result[0].vendor_key);
          })
          .catch(error => {
              console.error('Error posting data:', error);
          });


      }
    }, [vendorKey]); // Dependency array


  //getcountries

      const [options, setCountries] = useState([]);

      // debugger
      useEffect(() => {

      if(options && options.length===0 && vendorKey!=='')
      {

      fetch(base_url+'/getCountries', {
      method: 'POST',
      headers: {
          'Authorization': `Bearer ${vendorKey}`,
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
      })
      .then(response => response.json())
      .then(data => {

        

        setCountries(data.result.data.response_data);
        
      })
      .catch(error => {
          console.error('Error posting data:', error);
      });

      }
    }, [vendorKey]);

      //getgender

      const [goptions, setGender] = useState([]);

      useEffect(() => {

      if(goptions && goptions.length===0 && vendorKey!=='')
      {

      fetch(base_url+'/getGender', {
      method: 'POST',
      headers: {
          'Authorization': `Bearer ${vendorKey}`,
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
      })
      .then(response => response.json())
      .then(data => {
        
        setGender(data.result.data.response_data);
        
      })
      .catch(error => {
          console.error('Error posting data:', error);
      });

      }
    }, [vendorKey]);

      //getmaritalstatus

      const [moptions, setMarital] = useState([]);

      useEffect(() => {

      if(moptions && moptions.length===0 && vendorKey!=='')
      {

      fetch(base_url+'/getMaritalStatus', {
      method: 'POST',
      headers: {
          'Authorization': `Bearer ${vendorKey}`,
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
      })
      .then(response => response.json())
      .then(data => {
        
        
        setMarital(data.result.data.response_data);
        document.getElementsByName('firstName')[0].focus();
        

      })
      .catch(error => {
          console.error('Error posting data:', error);
      });

      }
    }, [vendorKey]);


    const { t } = useTranslation();

    const [selectedmaritalOption, setSelectedmaritalOption] = useState(null);
    const [selectedmaritalOptionid, setSelectedmaritalOptionid] = useState(null);
    const handlemaritalChange = (event) => {
      setSelectedmaritalOptionid(event.target.options[event.target.selectedIndex].id);
      setSelectedmaritalOption(event.target.value);
    };

    const [selectedgenderOption, setSelectedgenderOption] = useState(null);
    const [selectedgenderOptionid, setSelectedgenderOptionid] = useState(null);
    const handlegenderChange = (event) => {
      setSelectedgenderOptionid(event.target.options[event.target.selectedIndex].id);
      setSelectedgenderOption(event.target.value);
    };

    const [phoneaValue, setphoneaValue] = useState('');

    const [selectednatinalityOption, setSelectednatinalityOption] = useState(null);
    const [selectednationalityOption, setSelectednationalityOption] = useState(null);
    const [selectednationalityOptionid, setSelectednationalityOptionid] = useState(null);
    const handlenationalityChange = async (value) => {

      const [description, id] = value.split("#");

      // setSelectednationalityOptionid(event.target.options[event.target.selectedIndex].id);
      setSelectednationalityOptionid(id);
      setSelectednationalityOption(description);
      setSelectednatinalityOption(value);
      const cnData = { country: description };

        await fetch(base_url+'/get_country_code', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(cnData),
        })
        .then(response => response.json())
        .then(data => {
          // debugger

          const cde = data.result.replace(/\+/g, '');

          setphoneaValue(cde);
          
        })
        .catch(error => {
            console.error('Error posting data:', error);
        });

    };


    const [fnameValue, setfnameValue] = useState('');
    const handlefnameChange = (event) => {
        setfnameValue(event.target.value);
    };

    const [lnameValue, setlnameValue] = useState('');
    const handlelnameChange = (event) => {
        setlnameValue(event.target.value);
    };

    const [emailValue, setemailValue] = useState('');
    const handleemailChange = (event) => {
        setemailValue(event.target.value);
    };

    const [dobValue, setdobValue] = useState('');
    const handledobChange = (event) => {
        setdobValue(event.target.value);
    };

    const [passportValue, setpassportValue] = useState('');
    const handlepassportChange = (event) => {
        setpassportValue(event.target.value);
    };

    
    const handlephoneaChange = (event) => {
        setphoneaValue(event.target.value);
    };
    
    const [phoneValue, setphoneValue] = useState('');
    const handlephoneChange = (event) => {
        setphoneValue(event.target.value);
    };


    //history edit

    // useEffect(() => {
    //   const storedTraveldata = sessionStorage.getItem('induranceapplicationData');
    //     if (storedTraveldata!=="undefined" && storedTraveldata!=null) {
    //       const estoredTraveldata = JSON.parse(storedTraveldata);
          
    //       const fname = estoredTraveldata.first_name!='' ? estoredTraveldata.first_name : '';
    //       const lname = estoredTraveldata.last_name!='' ? estoredTraveldata.last_name : '';
    //       const semail = estoredTraveldata.email!='' ? estoredTraveldata.email : '';
    //       const passport = estoredTraveldata.passport!='' ? estoredTraveldata.passport : '';

    //       setfnameValue(fname);
    //       setlnameValue(lname);
    //       setemailValue(semail);
    //       setpassportValue(passport);

    //     }
    // }, []);

    //



    const handleSubmit = (e) => {
      // debugger;
      e.preventDefault();

      if (!isChecked){
        alert('Please ensure the declare')
      }
      else{

        const fnum = phoneaValue+''+phoneValue;

      const desiredLength = 12;

      const padToTwelveDigits = (number) => {
        const zerosToAdd = desiredLength - number.length;
        
        if(zerosToAdd<0)
        {
          const pn = phoneValue;

          const zToAdd = desiredLength - pn.length;

          const paddedNumber = '0'.repeat(zToAdd) + pn;

          return paddedNumber;
        }
        else
        {
        const paddedNumber = '0'.repeat(zerosToAdd) + number;

        return paddedNumber;
        }

        

      };
    
      const twelveDigitNumber = padToTwelveDigits(fnum);

      // debugger

      // if(phoneValue.length!==12)
      // {
      //   alert("Enter phone 12 digits");
      //   return;
      // }
debugger
      var data   = {

        "nationality": selectednationalityOption,
        "nationalityCode": selectednationalityOptionid,
        "inbound_gender_id": selectedgenderOptionid,
        "inbound_gender_detail": selectedgenderOption,
        "inbound_marital_status_id": selectedmaritalOptionid,
        "inbound_marital_status_detail": selectedmaritalOption,
        "inbound_product_type_id": "2",
        "inbound_product_type_name": travelDetails.inbound_product_type_name,
        "inbound_product_id": travelDetails.inbound_product_id,
        "inbound_product_name": travelDetails.inbound_product_name,
        "inbound_coverage_id": travelDetails.inbound_coverage_id,
        "inbound_coverage_name": travelDetails.inbound_coverage_name,
        "inbound_country_id": travelInfo.inbound_country_id,
        "inbound_country_name": travelInfo.inbound_country_name,
        "inbound_period_type_id": 1,
        "inbound_period_value_id": 6,
        "cumulative_days": travelDetails.cumulative_days,
        "inbound_travel_start_date": travelInfo.inbound_travel_start_date,
        'first_name': fnameValue,
        'last_name': lnameValue,
        'email': emailValue,
        'mobileNumber': twelveDigitNumber,
        'passport': passportValue,
        'dob': dobValue


      }

      sessionStorage.setItem("induranceapplicationData", JSON.stringify(data));

      window.location.href = `/insurance-plan`;

      }

      

      // onSubmitForm(data, userData.access_token);
    };

    const today = new Date().toISOString().split('T')[0];



  return (
    <Fragment>

    <div className="header_section1">

    <Navbar />


    <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">            
              <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'Traveller Details' : 'تفاصيل المسافر'}</h3>
          </div>
      </div>


    </div>
       
 
        <div className="features_section">
        <div className="container">
            
            <div className="row">

                <Col md={3}></Col>

                    <Col md={6} className="myforms my-5"> 


                            <div>
                            

                            <form onSubmit={handleSubmit}>

    <div className="input-group mb-2 mt-2">
      <div className="input-group-prepend prepends">
        <span className="input-group-text">{t('First')} {t('Name')}<span style={{color:'red', marginLeft:'4px'}}>*</span></span>
      </div>
      <input type="text" name="firstName" className="form-control" 
       onChange={(e) => {
        const { value } = e.target;
        if (/^[a-zA-Z]*$/.test(value)) {
          handlefnameChange(e);
        }
    }}
      // onChange={handlefnameChange} 
      value={fnameValue} required />
    </div>

    <div className="input-group mb-2 mt-2">
      <div className="input-group-prepend prepends">
        <span className="input-group-text">{(lnstr === 'en') ? 'Last Name' : 'الأسم الأخير'}<span style={{color:'red', marginLeft:'4px'}}>*</span></span>
      </div>
      <input type="text" name="lastName" className="form-control" 
            onChange={(e) => {
              const { value } = e.target;
              if (/^[a-zA-Z]*$/.test(value)) {
                handlelnameChange(e);
              }
          }}
      // onChange={handlelnameChange}
       value={lnameValue} required />
    </div>
    
    <div className="input-group mb-2 mt-2">
      <div className="input-group-prepend prepends">
        <span className="input-group-text">{t('DOB')}<span style={{color:'red', marginLeft:'4px'}}>*</span></span>
      </div>
      <input type="date" name="dob" max={today} className="form-control" onChange={handledobChange} required />
    </div>
    

    <div className="input-group mb-2 mt-2">
      <div className="input-group-prepend prepends">
        <span className="input-group-text">{t('Email')}<span style={{color:'red', marginLeft:'4px'}}>*</span></span>
      </div>
      <input type="text" name="email" className="form-control"  onBlur={handleEmailBlur} onChange={handleemailChange} value={emailValue} required />
    </div>
    <div>{emailError && <p style={{ color: 'red' }}>{emailError}</p>}</div>
    
    <div className="input-group mb-2 mt-2">
      <div className="input-group-prepend prepends">
        <span className="input-group-text">{t('Nationality')}<span style={{color:'red', marginLeft:'4px'}}>*</span></span>
      </div>

      <div className="styledata">
        <Select2Wrapper name="nationality" value={setSelectednatinalityOption} options={options} className='form-control select2' onChange={handlenationalityChange} required  />
      </div>


      {/*<select name="nationality" className="form-control" onChange={handlenationalityChange} required>*/}
      {/*<option value="">-- {t('Select')} --</option>*/}
      {/*  {options && options.map((option) =>*/}
      {/*      <option key={option.id} id={option.id} value={option.description}>{t(option.description)}</option>*/}
      {/*  )}*/}
      {/*</select>*/}

    </div>

                  <div className="input-group mb-2 mt-2">
                    <div className="input-group-prepend prepends">
                      <span className="input-group-text">{t('Phone')}<span style={{color:'red', marginLeft:'4px'}}>*</span></span>
                    </div>
                    <input type="text" className="phonea"  autoComplete="off" style={{ width: 80, border: '1px solid #dee2e6', padding: '0 13px' }} value={phoneaValue} readOnly />
                    <input type="text" name="phone" maxLength={10} className="form-control"
                      onChange={handlephoneChange}
                      pattern="[0-9]*"
                      onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                      autoComplete="off" required />
                  </div>

                  <div className="input-group mb-2 mt-2">
      <div className="input-group-prepend prepends">
        <span className="input-group-text">{t('Gender')}<span style={{color:'red', marginLeft:'4px'}}>*</span></span>
      </div>
      <select name="gender" className="form-control" onChange={handlegenderChange} required>
        <option value="">{(lnstr === 'en') ? '-- Select --' : '--اختار--'}</option>
        {goptions && goptions.map((opt) =>
            <option key={opt.id} id={opt.id} value={opt.description}>{t(opt.description)}</option>
        )}
      </select>
    </div>

                              <div className="input-group mb-2 mt-2">
                                <div className="input-group-prepend prepends">
        <span className="input-group-text">{(lnstr === 'en') ? 'Marital Status' : 'الحالة الأجتماعية'}<span style={{color:'red', marginLeft:'4px'}}>*</span></span>
      </div>
                                <select name="maritalstatus" className="form-control" onChange={handlemaritalChange}
                                        required>
                                  <option value="">{(lnstr === 'en') ? '-- Select --' : '--اختار--'}</option>
                                  {moptions && moptions.map((mopt) =>
                                      <option key={mopt.id} id={mopt.id}
                                              value={mopt.description}>{t(mopt.description)}</option>
                                  )}
                                </select>
                              </div>

                              <div className="input-group mb-2 mt-2">
                                <div className="input-group-prepend prepends">
        <span className="input-group-text">{(lnstr === 'en') ? 'Passport Number' : 'رقم جواز السفر'}<span style={{color:'red', marginLeft:'4px'}}>*</span> </span>
      </div>
      <input type="text" name="passportNumber" className="form-control" onChange={handlepassportChange} value={passportValue} required />
    </div>

    <p className={lnstr}>
      {(lnstr === 'en') ? 'We use this to create your insurance and send you updates about your application'
          :
          'نستخدم هذه المعلومات لإنشاء تأمينك وإرسال تحديثات حول طلبك'}
      </p>

    <p className={lnstr}><input type="checkbox" checked={isChecked}
                    onChange={handleCheckboxChange} name="note" />
      {(lnstr === 'en') ? ' I want to receive insurance updates, product launches and personalized offers. I can opt out anytime.'
          :
          'أرغب في تلقي تحديثات التأمين، وإطلاقات المنتجات، والعروض الشخصية. يمكنني الانسحاب في أي وقت.'}
      </p>

    <button type="submit" className="btn btn-primary rounded-pill text-white my-3 py-1 px-4">{t('Submit')}</button>

    </form>

                            </div>




                    </Col>

<Col md={3}></Col>

</div>
            
            </div>
          </div>

    <Footer />

    

    </Fragment>
  );
}

export default Inboundtravellerinfo;
