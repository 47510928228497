import React, { Fragment, useState, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
import AOS from 'aos';
function SanadMotor() {

    const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';


    const { t } = useTranslation();

    const [formData, setFormData] = useState({
        insurancetype: '',
    });

    const [selected, setSelected] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showSection, setShowSection] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [isHovered1, setIsHovered1] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [showquote, setShowQuote] = useState(false);

    const [isEmailActive, setEmailActive] = useState(false);
    const [isMobilenoActive, setMoblienoActive] = useState(false);
    const [isWhatappActive, setWhatappActive] = useState(false);
    //   const [isHovered, setIsHovered] = useState(false);
    //   const [isHovered1, setIsHovered1] = useState(false);



    const handleOptionChange = (event) => {
        setSelected(event.target.value);
        setShowQuote(true);
        debugger
        setFormData({ ...formData, 'insurancetype': event.target.value });

        if (event.target.value === ' ') {
            setShowSection(true);
            setButtonDisabled(true);
        }
        else {
            setShowSection(false);
            setButtonDisabled(false);
        }

    };

    const popupStyle = {
        position: 'fixed',
        width: '50%',
        top: '50%',
        left: '48%',
        transform: 'translate(-50%, -50%)',
        padding: '20px',
        background: 'white',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        zIndex: 1000,
        borderRadius: '8px',
    };


    const togglePopup = () => {
        debugger
        setIsOpen(!isOpen);
    };
    const handleClosePopup = () => {
        setIsOpen(false);
    };

    const handleshow1 = async (event) => {
        debugger
        event.preventDefault();
        setShow1(true);
        setShow2(false);
    }

    const handleshow2 = async (event) => {
        debugger
        event.preventDefault();
        setShow1(false);
        setShow2(true);
    }

    const handlesuccess = async (event) => {
        event.preventDefault();
        window.location.href = '/dhofar-motor-success'
    };

    const Popup = ({ onClose }) => {

        return (
            // <div style={popupOverlayStyle} className="popup-overlay">

            <div style={popupStyle} className="popup-container p-3" data-aos='fade-up'>

                <button
                    type="button"
                    onClick={handleClosePopup}
                    style={{
                        position: 'absolute',
                        top: '1px',
                        right: '10px',
                        fontSize: '24px',
                        background: 'transparent',
                        border: 'none',
                        cursor: 'pointer',
                        color: 'black'
                    }}
                >
                    <span>&times;</span>
                </button>

                <form>
                    <div className="center">Comprehensive Insurance</div>

                    <div className="d-flex justify-content-between my-3 colmds-4 card m-0">
                        <div className="d-flex justify-content-between my-3">
                            <div>
                                <h4><strong>Plan</strong></h4>
                                <h6>Basic + Medex + Driver + Family (PAB) + UAE</h6>
                            </div>
                        </div>
                    </div>
                    <div>
                        <ul className="list-unstyled">
                            <li><h6>Coverage</h6></li>
                            <li><p>Basic</p></li>
                            <li><p>Medical Expenses</p></li>
                            <li><p>Personal Accident Benefit for Driver</p></li>
                            <li><p>Personal Accident Benefit for Family</p></li>
                            <li><p>UAE Geographical Cover</p></li>
                        </ul>
                    </div>
                </form>

            </div>

            // </div>  
        );
    }
    return (

        <Fragment>
            <form className="visa-form1">
                <div style={{ display: 'flex', paddingBottom: '20px' }}>
                    <button style={{ width: '100px', fontWeight: 'bold' }}>
                        Back
                    </button>
                </div>
                <div className="row mb-3">
                    <div className="col-md-6">
                        <div>
                            <div className="row mb-3">
                                <div className="col-md-6 text-start">
                                    <h2>Get Motor Insurance Offers</h2>
                                </div>
                                <div className="col-md-6 text-right">
                                    احصل على عروض التأمين على السيارات
                                </div>
                            </div>
                            <div className="inside_visa-form">
                                <div className="col-md">
                                    <div className="row">
                                        <div className="col-md-6 text-start">
                                            <label>Full Name<span style={{ color: 'red' }}>*</span></label>
                                        </div>
                                        <div className="col-md-6 text-right">
                                            <label>الاسم الكامل</label>
                                        </div>

                                        <input className="form-control" type="text" required></input>
                                    </div>
                                </div>
                                <div className="col-md">
                                    <div className="row">
                                        <div className="col-md-6 text-start">
                                            <label>Email<span style={{ color: 'red' }}>*</span></label>
                                        </div>
                                        <div className="col-md-6 text-right">
                                            <label>بريد إلكتروني</label>
                                        </div>
                                        <input className="form-control" type="email" required></input>
                                    </div>
                                    <div className="col-md">
                                        <div className="row">
                                            <div className="col-md-6 text-start">
                                                <label>Contact No<span style={{ color: 'red' }}></span></label>
                                            </div>
                                            <div className="col-md-6 text-right">
                                                <label>رقم الاتصال</label>
                                            </div>
                                            <input className="form-control" type="number"></input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 ">
                        <div>
                            <div className="row md-3">
                                <div className="col-md-6 text-start">
                                    <h2>Add Files</h2>
                                </div>
                                <div className="col-md-3 text-right">
                                    <h2>إضافة ملفات</h2>
                                </div>
                            </div>
                            <div className="inside_visa-form">
                                <div>
                                    <div className="col-md">
                                        <div className="row">
                                            <div className="col-md-6 text-start">
                                                <label>Driving License Front Side<span style={{ color: 'red' }}></span></label>
                                            </div>
                                            <div className="col-md-6 text-right">
                                                <label>رخصة القيادة على الجانب الأمامي</label>
                                            </div>
                                            <div class="file-upload-container">
                                                <label for="file-upload-1" class="file-upload-label" style={{ backgroundColor: '#66b0ff' }}>
                                                    Choose a file
                                                </label>
                                                <input type="file" id="file-upload-1" class="file-upload-input" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md">
                                        <div className="row">
                                            <div className="col-md-6 text-start">
                                                <label>Driving License Back Side<span style={{ color: 'red' }}>*</span></label>
                                            </div>
                                            <div className="col-md-6 text-right">
                                                <label>رخصة القيادة في الجانب الخلفي</label>
                                            </div>
                                            <div class="file-upload-container">
                                                <label for="file-upload-1" class="file-upload-label" style={{ backgroundColor: '#66b0ff' }}>
                                                    Choose a file
                                                </label>
                                                <input type="file" id="file-upload-1" class="file-upload-input" />
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="row">
                                                <div className="col-md-6 text-start">
                                                    <label>Mulkiya Front Side<span style={{ color: 'red' }}></span></label>
                                                </div>
                                                <div className="col-md-6 text-right">
                                                    <label>واجهة الملكية</label>
                                                </div>
                                                <div class="file-upload-container">
                                                    <label for="file-upload-1" class="file-upload-label" style={{ backgroundColor: '#66b0ff' }}>
                                                        Choose a file
                                                    </label>
                                                    <input type="file" id="file-upload-1" class="file-upload-input" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="row">
                                                <div className="col-md-6 text-start">
                                                    <label>Mulkiya Back Side<span style={{ color: 'red' }}></span></label>
                                                </div>
                                                <div className="col-md-6 text-right">
                                                    <label>الملكية الجهة الخلفية</label>
                                                </div>
                                                <div class="file-upload-container" >
                                                    <label for="file-upload-1" class="file-upload-label" style={{ backgroundColor: '#66b0ff' }}>
                                                        Choose a file
                                                    </label>
                                                    <input type="file" id="file-upload-1" class="file-upload-input" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="">
                    <div className="row mb-2 mt-4">
                        <div className="col-md-6 text-start">
                            <h2>Deatils</h2>
                        </div>
                        <div className="col-md-6 text-right">
                            <h2>تفاصيل</h2>
                        </div>
                    </div>
                    <div className="inside_visa-form">
                        <div>
                            <div className="col-md">
                                <div className="">
                                    <div className="">
                                        <h3><strong><u>Vehicle Details</u></strong></h3>
                                    </div>
                                    <div>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                                                <spam>Plate Number</spam>
                                                <div><h5><strong>5001</strong></h5></div>
                                            </div>
                                            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                                                <spam>Plate Code</spam>
                                                <div><h5><strong>LK</strong></h5></div>
                                            </div>
                                            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                                                <spam>Plate Type</spam>
                                                <div><h5><strong>Oman</strong></h5></div>
                                            </div>
                                            <div className="col-lg-2 col-md-12 col-sm-12 col-12">
                                                <spam>Expiry Date</spam>
                                                <div><h5><strong>05/26/2024</strong></h5></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="">
                                    <div className="">
                                        <h3><strong><u>Personal Details</u></strong></h3>
                                    </div>
                                    <div>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                                                <spam>Name</spam>
                                                <div><h5><strong>Amir bin Mustafa bin Mal Allah Al Lawati</strong></h5></div>
                                            </div>
                                            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                                                <spam>Mobile Number</spam>
                                                <div><h5><strong>9678 3415</strong></h5></div>
                                            </div>
                                            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                                                <spam>Expiry Date</spam>
                                                <div><h5><strong>06/20/2028</strong></h5></div>
                                            </div>
                                            {/* <div className="col-lg-2 col-md-12 col-sm-12 col-12">
                                                <spam>Expiry Date</spam>
                                                <div><h5><strong>05/26/2024</strong></h5></div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <button onClick={handleshow1}>Third Party</button>
                            <button style={{ marginLeft: '10px' }} onClick={handleshow2}>Comprehensive</button>
                        </div>
                    </div>

                    <div className="row mb-3">

                        {show1 && (

                            <div className="col-md-6">

                                <div className="">
                                    <div className="row mb-2 mt-4">
                                        <div className="col-md-6 text-start">
                                            <h2>Third Party</h2>
                                        </div>
                                        <div className="col-md-6 text-right">
                                            <h2>طرف ثالث</h2>
                                        </div>
                                    </div>
                                    <div className="inside_visa-form">
                                        <h4 className="px-0 py-2">{(lnstr === 'en') ? 'Select plan' : 'اختر الخطة\n'}</h4>

                                        <form>
                                            <div class="align-items-center travel-selection-option">
                                                {(lnstr === 'en') ? 'Basic + Driver + Family (PAB)' : 'أساسي + سائق + عائلة (PAB)\n'}
                                                <input type="radio" name="personalaccident" value="Basic + Driver + Family (PAB)" className="d-inline-block option-circle ml-auto" onChange={handleOptionChange} />
                                            </div>

                                            <div class="align-items-center travel-selection-option my-3">
                                                {(lnstr === 'en') ? 'Basic + Driver + Family (PAB) + UAE' : 'أساسي + سائق + عائلة (PAB) + الإمارات العربية المتحدة\n'}<input type="radio" name="personalaccident" value="Basic + Driver + Family (PAB) + UAE" className="d-inline-block option-circle ml-auto" onChange={handleOptionChange} />
                                            </div>
                                            <div class="align-items-center travel-selection-option my-3">
                                                {(lnstr === 'en') ? 'Basic + Driver + Family (PAB) + RSA' : 'أساسي + سائق + عائلة (PAB) + RSA\n'}
                                                <input type="radio" name="personalaccident" value="Basic + Driver + Family (PAB) + RSA" className="d-inline-block option-circle ml-auto" onChange={handleOptionChange} />
                                            </div>
                                            <div class="align-items-center travel-selection-option my-3">
                                                {(lnstr === 'en') ? 'Basic + Driver + Family (PAB) + UAE + RSA' : 'أساسي + سائق + عائلأساسي + سائق + عائلة (PAB) + الإمارات العربية المتحدة + RSA\n'}
                                                <input type="radio" name="personalaccident" value="Basic + Driver + Family (PAB) + UAE + RSA" className="d-inline-block option-circle ml-auto" onChange={handleOptionChange} />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                        )}




                        {show2 && (
                            <div className="col-md-6">

                                <div className="">
                                    <div className="row mb-2 mt-4">
                                        <div className="col-md-6 text-start">
                                            <h2>Comprehensive</h2>
                                        </div>
                                        <div className="col-md-6 text-right">
                                            <h2>شامل</h2>
                                        </div>
                                    </div>
                                    <div className="inside_visa-form">
                                        <h4 className="px-0 py-2">{(lnstr === 'en') ? 'Select plan' : 'اختر الخطة\n'}</h4>

                                        <form>
                                            <div class="align-items-center travel-selection-option" onClick={togglePopup}>
                                                {(lnstr === 'en') ? 'Basic + Medex + Driver + Family (PAB) + UAE' : 'أساسي + ميدكس + سائق + عائلة (PAB) + الإمارات العربية المتحدة\n'}
                                                <input type="radio" name="personalaccident" value="Basic + Medex + Driver + Family (PAB) + UAE" className="d-inline-block option-circle ml-auto" onChange={handleOptionChange} />
                                            </div>

                                            <div class="align-items-center travel-selection-option my-3" onClick={togglePopup}>
                                                {(lnstr === 'en') ? 'Basic + Medex + Driver + Family (PAB)' : 'أساسي + ميديكس + سائق + عائلة (PAB)\n'}
                                                <input type="radio" name="personalaccident" value="Basic + Medex + Driver + Family (PAB)" className="d-inline-block option-circle ml-auto" onChange={handleOptionChange} />
                                            </div>
                                            <div class="align-items-center travel-selection-option my-3" onClick={togglePopup}>
                                                {(lnstr === 'en') ? 'Basic + Medex + Driver + Family (PAB) + RSA' : 'أساسي + ميديكس + سائق + عائلة (PAB) + RSA\n'}
                                                <input type="radio" name="personalaccident" value="Basic + Medex + Driver + Family (PAB) + RSA" className="d-inline-block option-circle ml-auto" onChange={handleOptionChange} />
                                            </div>
                                            <div class="align-items-center travel-selection-option my-3" onClick={togglePopup}>
                                                {(lnstr === 'en') ? 'Basic + Medex + Driver + Family (PAB) + UAE + RSA' : 'أساسي + ميديكس + سائق + عائلة (PAB) + الإمارات العربية المتحدة + RSA\n'}
                                                <input type="radio" name="personalaccident" value="Basic + Medex + Driver + Family (PAB) + UAE + RSA" className="d-inline-block option-circle ml-auto" onChange={handleOptionChange} />
                                            </div>
                                            {isOpen && <Popup onClose={togglePopup} />}
                                        </form>
                                    </div>
                                </div>
                            </div>

                        )}

                        {showquote && (

                            <div className="col-md-6">
                                <div className="">
                                    <div className="row mb-2 mt-4">
                                        <div className="col-md-6 text-start">
                                            <h3>Quote Details</h3>
                                        </div>
                                        <div className="col-md-6 text-right">
                                            <label>تفاصيل الاقتباس</label>
                                        </div>
                                    </div>
                                    <div className="inside_visa-form">
                                        <h4 style={{ textAlign: "center" }}><strong>Vehicle Details</strong></h4><hr />
                                        <div>

                                            <div className="d-flex justify-content-between my-3 colmds-4 card m-0">
                                                <div className="d-flex justify-content-between my-1">
                                                    <div>
                                                        <h3><strong>Harley Davison</strong></h3>
                                                        <p><strong>2018</strong></p>
                                                    </div>
                                                    <div>
                                                        <button>99999 AA</button>
                                                    </div>

                                                </div>

                                            </div>

                                        </div>

                                        <div className="row">
                                            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                                                <spam>Chassis Number</spam>
                                                <div><h6><strong>21HD1YCJ37JC073193</strong></h6></div>
                                            </div>
                                            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                                                <spam>Body Type</spam>
                                                <div><h6><strong>Motor Cycle</strong></h6></div>
                                            </div>
                                            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                                                <spam>Usage Type</spam>
                                                <div><h6><strong>Private</strong></h6></div>
                                            </div>
                                            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                                                <spam>Vechicle Value</spam>
                                                <div><h6><strong>RO 15,000</strong></h6></div>
                                            </div>
                                        </div>


                                        <hr />
                                        <h4 style={{ textAlign: 'center' }}><strong>Insured</strong></h4><hr />
                                        <div className="row">
                                            <div className="col-lg-5" >
                                                <h5>Insured Name</h5>
                                                <div><h6><strong>Amir bin Mustafa bin Mal Allah Al Lawati</strong></h6></div>
                                            </div>
                                            <div className="col-lg-4">
                                                <h5>Civil Id</h5>
                                                <div><h6><strong>8078236</strong></h6></div>
                                            </div>
                                            <div className="col-lg-3">
                                                <h5>Age</h5>
                                                <div><h6><strong>27</strong></h6></div>
                                            </div>
                                        </div>


                                        <hr />
                                        <h4 style={{ textAlign: 'center' }}><strong>Communication Preferences</strong></h4><hr />

                                        <div class="d-flex justify-content-between">
                                            <div className="row">
                                                <div className="col-12 col-md-4 mb-3">
                                                    <input type="checkbox" checked={isMobilenoActive} style={{ marginRight: '8px' }} onChange={(e) => setMoblienoActive(e.target.checked)} />Mobile No        <div><input type='num' readOnly placeholder="+968 9678 3415"
                                                        disabled={!isMobilenoActive}
                                                        style={{
                                                            padding: "0.5rem",
                                                            border: "1px solid #ccc",
                                                            borderRadius: "4px",
                                                            backgroundColor: !isMobilenoActive ? "#f5f5f5" : "#fff",
                                                            filter: !isMobilenoActive ? "blur(0.5px)" : "none",
                                                            pointerEvents: !isMobilenoActive ? "none" : "auto",
                                                        }}
                                                    ></input></div>
                                                </div>
                                                <div className="col-12 col-md-4 mb-3">
                                                    <input
                                                        type="checkbox"
                                                        checked={isWhatappActive}
                                                        style={{ marginRight: '8px' }}
                                                        onChange={(e) => setWhatappActive(e.target.checked)}
                                                    />Whatapp Number
                                                    <div>  <input
                                                        type="num"
                                                        placeholder="+968 9678 3415"
                                                        disabled={!isWhatappActive}
                                                        readOnly
                                                        style={{
                                                            padding: "0.5rem",
                                                            border: "2px solid #ccc",
                                                            borderRadius: "4px",
                                                            backgroundColor: !isWhatappActive ? "#f5f5f5" : "#fff",
                                                            filter: !isWhatappActive ? "blur(0.5px)" : "none",
                                                            pointerEvents: !isWhatappActive ? "none" : "auto",
                                                        }}
                                                    /></div>
                                                </div>
                                                <div className="col-12 col-md-4 mb-3">
                                                    <input
                                                        type="checkbox"
                                                        checked={isEmailActive}
                                                        style={{ marginRight: "8px" }}
                                                        onChange={(e) => setEmailActive(e.target.checked)}
                                                    />Email
                                                    <div>
                                                        <input
                                                            type="email"
                                                            placeholder="mail@gmail.com"
                                                            disabled={!isEmailActive}
                                                            readOnly
                                                            style={{
                                                                padding: "0.5rem",
                                                                border: "2px solid #ccc",
                                                                borderRadius: "4px",
                                                                backgroundColor: !isEmailActive ? "#f5f5f5" : "#fff",
                                                                filter: !isEmailActive ? "blur(0.5px)" : "none",
                                                                pointerEvents: !isEmailActive ? "none" : "auto",
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <h4 style={{ textAlign: 'center' }}><strong>Quote</strong></h4><hr />

                                        <div className="row">
                                            <div className="col-lg-4">
                                                <spam>Quote No</spam>
                                                <div><h6><strong>503/0501/2024/P/000999/R01</strong></h6></div>
                                            </div>
                                            <div className="col-lg-4">
                                                <spam>Cover From Date</spam>
                                                <div><h6><strong>10-Oct-2024 11:36</strong></h6></div>
                                            </div>
                                            <div className="col-lg-4">
                                                <spam>Cover To Date</spam>
                                                <div><h6><strong>09-Oct-2024 23:59</strong></h6></div>
                                            </div>
                                            <div className="col-lg-5" >
                                                <spam>Product</spam>
                                                <div><h6><strong>Third Party Insurance</strong></h6></div>
                                            </div>
                                            <div className="col-lg-5">
                                                <spam>Plan</spam>
                                                <div><h6><strong>Basic + Driver + Family (PAB)</strong></h6></div>
                                            </div>
                                        </div>


                                        <div className="d-flex justify-content-between my-3 colmds-4 card m-0">
                                            <div className="d-flex justify-content-between my-1">
                                                <div>
                                                    <h6><strong>NET PREMIUM (Including VAT & all other taxes)</strong></h6>
                                                    <h3><strong>OMR 53.000</strong></h3>
                                                </div>
                                                <div>
                                                    <button type='submit' onClick={handlesuccess} style={{
                                                        transition: 'background-color 0.3s ease',
                                                    }} onMouseEnter={() => setIsHovered1(true)} onMouseLeave={() => setIsHovered1(false)} >PAY PREMIUM</button>
                                                </div>

                                            </div>

                                        </div>
                                    </div>



                                </div>
                            </div>

                        )}




                    </div>
                </div>
            </form>

        </Fragment>

    );
}

export default SanadMotor;