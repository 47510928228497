import axios from 'axios';

export const config = {
  //LIVE
  // baseURL: 'https://api.superjetom.com',
  // redirectURL: 'https://superjetom.com'

  //UAT
  baseURL: 'https://stg-api.superjetom.com',
  redirectURL: 'https://uat.superjetom.com'

  //LOCAL
  // baseURL: 'http://127.0.0.1:3000',
  // redirectURL:'http://localhost:3001',
  // redirectURL:'http://192.168.29.115:3001'
};

export const endPoints = {
  signUp: '/create_traveller',
  signUpAgency: '/create_travel_agency',
  loginEndpoint: '/login_traveller',
  loginAgencyEndpoint: '/login_travel_agency',
  countryList: '/country',
  testApplication: '/create_order_existing_traveller',
  visaPrograms: '/get_visa_programs',
  confirmpassword: '/',
  };

// export const Keys = {
//   gdprApiToken: 'J6WDf0ttQKGfYhQkRCjwraBS11JYuIDx',
//   apiPlatformValueiOS: '3',
//   apiPlatformValueAndroid: '2',
// };

export const axiosClient = axios.create({
  baseURL: config.baseURL,
});

export const getApiInterfaceWithToken = token => {
  return axios.create({
    baseURL: config.baseURL,

    // timeout: 10000,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getApiInterface = () => {
  axiosClient.defaults.baseURL = config.baseURL;
  return axiosClient;
};

axiosClient.defaults.timeout = 10000;