import React, { Fragment, useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useTranslation } from 'react-i18next';
import travelimg from './tr.png';
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import Cookies from 'js-cookie';
const { config } = require('./api/api');

function Travelinsurance() {
  const base_url = config.baseURL;
  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';


  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    insurancetype: '',
  });

  const [selectedOption, setSelectedOption] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showSection, setShowSection] = useState(false);
  const [error, setError] =  useState('')

  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setFormData({ ...formData, 'insurancetype': event.target.value });

    if (event.target.value === 'Outbound Travel') {
      setShowSection(true);
      setButtonDisabled(true);
    }
    else {
      setShowSection(false);
      setButtonDisabled(false);
    }

  };



  // const [selectedqOption, setSelectedqOption] = useState(null);

  const handleqOptionChange = (event) => {

    if (event.target.value === 'No') {
      // alert("The outbound plans are available only for residents of Oman.");
      setError('The outbound plans are available only for residents of Oman.')
      setButtonDisabled(true);
      return;
    }
    else {
      setButtonDisabled(false);
      setError('')
    }

  };


  const [vendorKey, setVendorKey] = useState('');

  useEffect(() => {

    const postvData = { organization_name: "Superjet" };

    if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {


      fetch(base_url+'/create_organization', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postvData),
      })
        .then(response => response.json())
        .then(data => {
          setVendorKey(data.result[0].vendor_key);
        })
        .catch(error => {
          console.error('Error posting data:', error);
        });


    }
  }, [vendorKey]); // Dependency array


  useEffect(()=>{
    if (selectedOption != null){
      const planvalue = sessionStorage.getItem('chooseplan');
    if(planvalue != undefined) {
      const plan = JSON.parse(planvalue)
      setSelectedOption(plan.plan)
    }
    }else{
      setSelectedOption(null)
    }
    
  },[])






  const handleSubmit = async (event) => {

    event.preventDefault();
    debugger

    if (selectedOption === null) {
      alert('Please choose plan');
      return false;
    }
    else {
      setLoading(true);

      event.preventDefault();


      var insurancetypedata = {
        'insurancetype': formData.insurancetype
      }

      sessionStorage.setItem("insurancetypeData", JSON.stringify(insurancetypedata));

      // debugger

      var vkData = { vendor_key: vendorKey };

      if (selectedOption === 'Inbound Travel') {

        await fetch(base_url+'/create_inbound_token', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${vendorKey}`,
          }
        })
          .then(response => response.json())
          .then(data => {

            debugger

            const dhdata = data.result.data.token;

            const expiryDate = new Date();
            expiryDate.setTime(expiryDate.getTime() + (1 * 60 * 60 * 1000));

            Cookies.set('dhofarData', JSON.stringify(dhdata), { expires: expiryDate });

            var data ={
              'plan':selectedOption
            }

            sessionStorage.setItem('chooseplan', JSON.stringify(data));

            window.location.href = `/inbound-travel-details`;

          })
          .catch(error => {
            console.error('Error posting data:', error);
          });

      }
      else {

        fetch(base_url+'/create_outbound_token', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${vendorKey}`,
          }
        })
          .then(response => response.json())
          .then(data => {

            const dhdata = data.result.data.token;

            const expiryDate = new Date();
            expiryDate.setTime(expiryDate.getTime() + (1 * 60 * 60 * 1000));

            Cookies.set('dhofarDataoutbound', JSON.stringify(dhdata), { expires: expiryDate });

            var data ={
              'plan':selectedOption
            }

            sessionStorage.setItem('chooseplan', JSON.stringify(data));

            window.location.href = `/outbound-travel-details`;

          })
          .catch(error => {
            console.error('Error posting data:', error);
          });

      }





    }

  };


  return (
    <Fragment>

      <div className="header_section1">

        <Navbar />


        <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">
            <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'Travel Insurance' : 'تأمين السفر\n'}</h3>
          </div>
        </div>


      </div>


      <div className="features_section">
        <div className="container">

          <div className="row">

            <Col md={3}></Col>

            <Col md={6} className="myforms my-5">



              <h4 className="px-0 py-2">{(lnstr === 'en') ? 'Choose Travel Plan' : 'حدد خطة رحلتك\n'}</h4>

              <form onSubmit={handleSubmit}>

                <div class="align-items-center travel-selection-option">
                  {(lnstr === 'en') ? 'Inbound Travel' : 'السفر الداخلي\n'}
                  <input type="radio" name="travelplan" value="Inbound Travel" className="d-inline-block option-circle ml-auto" onChange={handleOptionChange} />
                </div>

                <div class="align-items-center travel-selection-option my-3">
                  {(lnstr === 'en') ? 'Outbound Travel' : 'السفر الدولي\n'}
                  <input type="radio" name="travelplan" value="Outbound Travel" className="d-inline-block option-circle ml-auto" onChange={handleOptionChange} />
                </div>


                {showSection && (
                  <div>
                    <p className={lnstr}>{(lnstr === 'en') ? 'Are you a resident of Oman ?' : 'هل أنت مقيم في عمان؟'}</p>
                    <Row>
                      <Col md={4}>
                        <div class="align-items-center travel-selection-option">

                          {(lnstr === 'en') ? 'YES' : 'نعم'}
                          <input type="radio" name="residentans" value="Yes" onChange={handleqOptionChange} className="d-inline-block option-circle ml-auto" />
                        </div>
                      </Col>

                      <Col md={4}>
                        <div class="align-items-center travel-selection-option">

                          {(lnstr === 'en') ? 'NO' : 'لا\n'}
                          <input type="radio" name="residentans" value="No" onChange={handleqOptionChange} className="d-inline-block option-circle ml-auto" />
                        </div>
                      </Col>
                    </Row><br />
                  </div>
                )}
                {error && <div><span style={{color:'red'}}>{error}</span></div>}



                <button type="submit" disabled={buttonDisabled} className="btn btn-primary rounded-pill text-white my-1 py-1 px-4">{t('Submit')}</button>

              </form>


            </Col>

            <Col md={3}></Col>

          </div>

        </div>
      </div>

      <Footer />

      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}

    </Fragment>
  );
}

export default Travelinsurance;
