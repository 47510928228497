import React, { useState, useEffect, Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import i18n from '../i18n';
import Navbar from "../Navbar";
import Footer from "../Footer";
import 'aos/dist/aos.css';
import Aos from "aos";

function DhofarMotorinsuranceComprehensiveQuote() {

    const { t } = useTranslation();

    const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';
    const [loading, setLoading] = useState(false);

  const changeLanguage = (lng) => {

    const expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime() + (1 * 60 * 60 * 1000));

    Cookies.set('langData', lng, { expires: expiryDate });

    i18n.changeLanguage(lng);
  };

  const [isEmailActive, setEmailActive] = useState(false);
  const [isMobilenoActive , setMoblienoActive] = useState(false);
  const [isWhatappActive , setWhatappActive] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
const [isHovered1, setIsHovered1] = useState(false); 

    
    const [insData, setData] = useState(false);
    
    useEffect(() => {
        // debugger
      const storeddata = sessionStorage.getItem('motorinsurancePlan');
        if (storeddata!==undefined && storeddata!==null) {
          const storeddt = JSON.parse(storeddata);
          setData(storeddt);
        }
        else
        {
            window.location.href = '/motor-insurance';
        }
    }, []);

    
  // Function to update HTML dir attribute based on language
  const updateHtmlDirAttribute = () => {
    document.documentElement.dir = i18n.dir(); // Set document direction
  };

  // Update HTML dir attribute when component mounts and language changes
  useEffect(() => {
    updateHtmlDirAttribute();
  }, [i18n.language]);  

  useEffect(()=>{
    Aos.init({duration:1000});
  },[]);


  const handleback = async (event) => {
    event.preventDefault();

    window.location.href = '/dhofar-motor-comperhensive'
  };

  const handlesuccess = async (event) =>{
    event.preventDefault();
    window.location.href = '/dhofar-motor-success'
  };
  const styles = {
    column: {
      flex: '1',
      padding: '0 10px',
    }
  };

  return (
    <Fragment>

      <div className="header_section1">

        <Navbar />


        <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">
            <h5 className="abt-txt my-3">{(lnstr === 'en') ? 'Details Page': 'تحميل السياسة\n'}</h5>
          </div>
        </div>


      </div>


      <div className="features_section">
        <div className="container">

          <div className="row">

            <Col md={2}></Col>

            <Col md={8} className="myforms my-5" data-aos='fade-up'>
            <div class="card">
  <div class="card-body">
    <h4 style={{textAlign: "center"}}><strong>Vehicle Details</strong></h4><hr/>
    <div>

    <div className="d-flex justify-content-between my-3 colmds-4 card m-0">
<div className="d-flex justify-content-between my-1">
  <div>
  <h3><strong>Harley Davison</strong></h3>
  <p><strong>2018</strong></p>
  </div>
  <div>
<button>99999 AA</button>
</div>

</div>

</div>
      
  </div>
    <div className="row">
    <div className="col-lg-3 col-md-12 col-sm-12 col-12">
      <spam>Chassis Number</spam>
      <div><h6><strong>21HD1YCJ37JC073193</strong></h6></div>
    </div>
    <div className="col-lg-3 col-md-12 col-sm-12 col-12">
      <spam>Body Type</spam>
      <div><h6><strong>Motor Cycle</strong></h6></div>
    </div>
    <div className="col-lg-3 col-md-12 col-sm-12 col-12">
      <spam>Usage Type</spam>
      <div><h6><strong>Private</strong></h6></div>
    </div>
    <div className="col-lg-3 col-md-12 col-sm-12 col-12">
      <spam>Vechicle Value</spam>
      <div><h6><strong>RO 15,000</strong></h6></div>
    </div>
  </div>
  <hr/>
  <h4 style={{textAlign:'center'}}><strong>Insured</strong></h4><hr/>
    <div className="row">
    <div className="col-lg-5" >
      <h5>Insured Name</h5>
      <div><h6><strong>Amir bin Mustafa bin Mal Allah Al Lawati</strong></h6></div>
    </div>
    <div className="col-lg-4">
      <h5>Civil Id</h5>
      <div><h6><strong>8078236</strong></h6></div>
    </div>
    <div className="col-lg-3">
      <h5>Age</h5>
      <div><h6><strong>27</strong></h6></div>
    </div>
    </div>
  <hr/>
  <h4 style={{textAlign:'center'}}><strong>Communication Preferences</strong></h4><hr/>

  <div class="d-flex justify-content-between">
    <div className="row">
    <div className="col-12 col-md-4 mb-3">
      <input type="checkbox" checked={isMobilenoActive} style={{marginRight: '8px'}} onChange={(e) => setMoblienoActive(e.target.checked)}/>Mobile No        <div><input type ='num' readOnly placeholder="+968 9678 3415" 
      disabled = {!isMobilenoActive}
      style={{
        padding: "0.5rem",
        border: "1px solid #ccc",
        borderRadius: "4px",
        backgroundColor: !isMobilenoActive ? "#f5f5f5" : "#fff",
        filter: !isMobilenoActive ? "blur(0.5px)" : "none",
        pointerEvents: !isMobilenoActive ? "none" : "auto",
      }}
      ></input></div>
    </div>
    <div className="col-12 col-md-4 mb-3">
      <input
        type="checkbox"
        checked={isWhatappActive}
        style={{marginRight:'8px'}}
        onChange={(e) => setWhatappActive(e.target.checked)}
      />Whatapp Number
      <div>  <input
        type="num"
        placeholder="+968 9678 3415"
        disabled={ !isWhatappActive}
        readOnly
        style={{
          padding: "0.5rem",
          border: "2px solid #ccc",
          borderRadius: "4px",
          backgroundColor: !isWhatappActive ? "#f5f5f5" : "#fff",
          filter: !isWhatappActive ? "blur(0.5px)" : "none",
          pointerEvents: !isWhatappActive ? "none" : "auto",
        }}
      /></div>
    </div>
    <div className="col-12 col-md-4 mb-3">
      <input
        type="checkbox"
        checked={isEmailActive}
        style={{marginRight : "8px"}}
        onChange={(e) => setEmailActive(e.target.checked)}
      />Email
      <div>
      <input
        type="email"
        placeholder="mail@gmail.com"
        disabled={!isEmailActive}
        readOnly
        style={{
          padding: "0.5rem",
          border: "2px solid #ccc",
          borderRadius: "4px",
          backgroundColor: !isEmailActive ? "#f5f5f5" : "#fff",
          filter: !isEmailActive ? "blur(0.5px)" : "none",
          pointerEvents: !isEmailActive ? "none" : "auto",
        }}
      />
      </div>
    </div>
    </div>
  </div>
  <hr/>
  <h4 style={{textAlign:'center'}}><strong>Quote</strong></h4><hr/>
    
    <div className="row">
    <div className="col-lg-4">
      <spam>Quote No</spam>
      <div><h6><strong>503/0501/2024/P/000999/R01</strong></h6></div>
    </div>
    <div className="col-lg-4">
      <spam>Cover From Date</spam>
      <div><h6><strong>10-Oct-2024 11:36</strong></h6></div>
    </div>
    <div className="col-lg-4">
      <spam>Cover To Date</spam>
      <div><h6><strong>09-Oct-2024 23:59</strong></h6></div>
    </div>
    <div className="col-lg-5" >
      <spam>Product</spam>
      <div><h6><strong>Third Party Insurance</strong></h6></div>
    </div>
    <div className="col-lg-5">
      <spam>Plan</spam>
      <div><h6><strong>Basic + Driver + Family (PAB)</strong></h6></div>
    </div>
  </div>

      <div className="d-flex justify-content-between my-3 colmds-4 card m-0">
<div className="d-flex justify-content-between my-1">
  <div>
  <h6><strong>NET PREMIUM (Including VAT & all other taxes)</strong></h6>
  <h3><strong>OMR 53.000</strong></h3>
  </div>
  <div>
<button type = 'submit' onClick={handlesuccess}  style={{
            backgroundColor: isHovered1 ? '#0b8ba8' : '#33d0f2',
            transition: 'background-color 0.3s ease',
          }}  onMouseEnter={() => setIsHovered1(true)} onMouseLeave={() => setIsHovered1(false)} >PAY PREMIUM</button>
</div>

</div>

</div>
    
  </div>
</div>

<button type='button' onClick={handleback} className="rounded-pill text-white my-1 py-1 px-4" style={{
            backgroundColor: isHovered ? '#0b8ba8' : '#33d0f2',
            transition: 'background-color 0.3s ease',
            position: 'absolute', 
            right: '20px',
            marginTop: '10px'
          }}  onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>BACK</button>


          
        </Col>

            <Col md={2}></Col>

          </div>


        </div>
      </div>

      <Footer />

      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}

    </Fragment>
  );
}

export default DhofarMotorinsuranceComprehensiveQuote;