import React, { useState, useEffect, useRef } from "react";
import { Container, Form, Row, Col, Card, Button, CardBody } from "react-bootstrap";
import SignatureCanvas from 'react-signature-canvas';
import { Fragment } from 'react';
import { compareDesc, format } from 'date-fns';
import Selectoption from "../Selectoption";
import Selectoptionocc from "../Selectoptionocc";
import Cookies from 'js-cookie';
import $ from 'jquery';
import { useTranslation } from 'react-i18next';
import Armedforcesquestionnaire from './Armed-forces-questionnaire.docx';
// import { useLocation } from "react-router-dom";
// import ReactGA from "react-ga4";

const { config } = require('../../api/api');
const SanadTermPlan = () => {

    // const location = useLocation();
    const { t } = useTranslation();
    const base_url = config.baseURL;
    const redirecturl = config.redirectURL;
    const vendor_key = 'c3VwZXJqZXRfc2FuYWRfcGVyc29uYWxfYWNjaWRlbnRfdmVuZG9yX2tleQ==';
    const api_headers = {
        'Authorization': 'Bearer ' + vendor_key,
        'Content-Type': 'application/json' // Ensure you're sending JSON
    }
    const signatureRef = useRef();
    const [genderList, setGenderList] = useState(["Male", "Female"]);
    const [maritalStatusList, setMaritalStatusList] = useState(["Yes", "No"]);
    const [relationList, setRelationList] = useState([]);
    const [occupationList, setOccupationList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [questionList, setQuestionList] = useState([]);
    const [age, setAge] = useState(null);
    const [done, setDone] = useState(false);
    const [basicPremium, setBasicPremium] = useState(null);
    const [selectedPremium, setSelectedPremium] = useState(null);

    const [selectedGender, setSelectedGender] = useState('Select Gender');
    const [selectedMaritalStatus, setSelectedMaritalStatus] = useState('Select Status');
    const [selectedDateofBirth, setSelectedDateofBirth] = useState(null);
    const [quotationData, setQuotationData] = useState(null);
    const [termPlanPolicyData, setTermPlamPolicyData] = useState(null);
    const [termplanPolicyPaymentLink, setTermPlanPolicyPaymentLink] = useState(null);
    const [selectedOccupationCode, setSelectedOccupationCode] = useState('');
    const [selectednationality, setSelectednationality] = useState('');
    const [genderError, setGenderError] = useState("");
    const [marriedError, setMarriedError] = useState("");
    const [dobError, setDobError] = useState("");
    const [occupationError, setOccupationError] = useState("");
    const [nationalityError, setNationalityError] = useState("");
    const [totpercentage, settotpercentage] = useState();
    const [selectedCode, setSelectedCode] = useState('');// New state to store occupation CODE
    const [bmi, setBmi] = useState('0');

    const [sanadRequesteeName, setSanadRequesteename] = useState('');
    const [sanadUserName, setSanadUserName] = useState('');
    const [sanadUserMobileNumber, setSanadUserMobileNumber] = useState('');
    const [sanadReferenceNumber, setSanadReferenceNumber] = useState('');
    const [sanadCivilIDNumber, setSanadCivilIDNumber] = useState('');
    const [sanadServiceRequestId, setSanadServiceRequestId] = useState('');
    const [sanadTransDate, setSanadTransDate] = useState('');
    const [sanadReturnUrl, setSanadReturnUrl] = useState('');
    const [sanadErrorUrl, setSanadErrorUrl] = useState('');
    const [sanadBackUrl, setSanadBackUrl] = useState('');
    const [sanadLanguage, setSanadLanguage] = useState('');
    const [isSignatureEmpty, setIsSignatureEmpty] = useState(true);
    const [beneficiarycheck, setbeneficiarycheck] = useState(false);
    const vatin_regex = /^[a-zA-Z0-9]{0,15}$/;
    const selectOpt1 = useRef(null);
    const selectnationalty = useRef(null);
    const [selectedoccupation, setSelectedoccupation] = useState('');
    const [emailError, setEmailError] = useState('');
    const [mobileError, setMobileError] = useState('');
    const [civilIDError, setCivilIDError] = useState('');
    const [loading, setLoading] = useState(false);
    const [nameError, setNameError] = useState('');
    const [weightError, setWeightError] = useState('');
    const [heightError, setHeightError] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [accessTokenKey, setAccessTokenKey] = useState('');
    const [isHidePage, setIsHidePage] = useState('');
    const [locationError, setLocationError] = useState('');
    const [vatError, setVatError] = useState('');
    const [form_download, setDownload] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [modalMessageno, setModalMessageNO] = useState("");
    const [modalMessagepolicy, setModalMessagePolicy] = useState("");

    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        mobileNumber: '',
        dob: '',
        civilIDno: '',
        passportNumber: '',
        height: '',
        weight: '',
        resident_location: '',
        nationality: '',
        vat_in: '',
        gender: '',
        is_married: '',
        expiryDateof: ''

    });

    //reset value
    const resetForm = () => {
        setIsChecked(false);
        setAge(null);
        setBasicPremium(null);
        setSelectedGender('Select Gender');
        setSelectedMaritalStatus('Select Status');
        setSelectedDateofBirth(null);
        setQuotationData(null);
        setTermPlamPolicyData(null);
        // setsetTermPlamPolicyDataPolicyPaymentLinkuseState(null);
        setFormData({
            fullName: '',
            email: '',
            mobileNumber: '',
            dob: '',
            civilIDno: '',
            passportNumber: '',
            height: '',
            weight: '',
            occupation: '',
            resident_location: '',
            nationality: '',
            vat_in: '',
            gender: '',
            is_married: '',
            expiryDateof: ''
        });
    };


  const [passportFile, setPassportFile] = useState(null);
  const [questionnaireFile, setQuestionnaireFile] = useState(null);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [passportFileError, setPassportFileError] = useState('');
  const [questionFileError, setquestionFileError] = useState('');
 
    // new add code
    // useEffect(() => {
    //     debugger
    //     ReactGA.send({ hitType: "Navbar Screen", page: location.pathname });
    //   }, [location]);

    const handleEmailBlur = (e) => {
        const { value } = e.target;

        // Regular expression to validate email format
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Check if the entered email is valid
        if (!emailPattern.test(value)) {
            setEmailError('Please enter valid e-mail address');
        } else {
            setEmailError('');
        }
    };


    const handleNameBlur = (e) => {
        const { value } = e.target;

        // Regular expression for English letters and spaces
        const englishLetterPattern = /^[a-zA-Z\s]*$/;

        // Check if the name is empty or contains invalid characters
        if (value === '') {
            setNameError('Please enter Full Name');
        } else if (!englishLetterPattern.test(value)) {
            setNameError('Please enter Full Name, accept english letters only.');
        } else {
            setNameError('');
        }
    };

    const handleMobileBlur = (e) => {
        const { value } = e.target;

        // Check if the mobile number has exactly 8 digits
        if (value.length !== 8 || !/^\d+$/.test(value)) {
            setMobileError('Please enter 8 digit phone number');
        } else {
            setMobileError('');
        }
    };


    const handleCivilIDBlur = (e) => {
        const { value } = e.target;

        // Regular expression to allow only numbers and check length between 4 to 9 digits
        const civilIDPattern = /^\d{4,9}$/;

        // Check if the Civil ID matches the pattern
        if (!civilIDPattern.test(value)) {
            setCivilIDError('Please enter valid civil id number, it accept numbers only, 4 to 9 digit.');
        } else {
            setCivilIDError('');
        }
    };

    const [passportError, setPassportError] = useState('');

    const handlePassportBlur = (e) => {
        const { value } = e.target;

        // Regular expression to allow only letters and numbers
        const passportPattern = /^[a-zA-Z0-9]*$/;

        // Check if the Passport Number matches the pattern
        if (!passportPattern.test(value) || value == '') {
            setPassportError('Please enter valid passport number, it accept numbers & letters only.');
        } else {
            setPassportError('');
        }
    };


    const handleHeightBlur = (e) => {
        const { value } = e.target;

        // Convert the value to a number and check if it's a valid number and within the range
        const heightValue = Number(value);

        if (isNaN(heightValue) || heightValue < 0 || heightValue > 999 || value == '') {
            setHeightError('Please enter valid height, accept max 3 digits.');
        } else {
            setHeightError('');
        }
    };


    const handleWeightBlur = (e) => {
        const { value } = e.target;

        // Convert the value to a number and check if it's a valid number and within the range
        const weightValue = Number(value);

        if (isNaN(weightValue) || weightValue < 0 || weightValue > 999 || value == '') {
            setWeightError('Please enter valid weight, accept max 3 digits.');
        } else {
            setWeightError('');
        }
    };


    const handleLocationBlur = (e) => {
        const { value } = e.target;

        // Optional: Regular expression to allow only letters, spaces, commas, and hyphens
        const locationPattern = /^[a-zA-Z\s,-]+$/;

        // Check if the Residential Location is empty or invalid
        if (value === '') {
            setLocationError('Please enter your Residential Location');
        } else if (!locationPattern.test(value)) {
            setLocationError('Please enter a valid Residential Location (letters, spaces, commas, hyphens only)');
        } else {
            setLocationError('');
        }
    };


    const handleVatBlur = (e) => {
        const { value } = e.target;

        // Regular expression to allow only letters and numbers
        const vatPattern = /^[a-zA-Z0-9]{15}$/;

        // Check if the VATIN is exactly 15 characters long and matches the pattern
        if (!vatPattern.test(value)) {
            setVatError('Please enter a valid VATIN (15 characters, letters, and numbers only)');
        } else {
            setVatError('');
        }
    };
    const [expriydateofError, setExpriydateError] = useState('');

    const handleExpriydateBlur = (e) => {
        const { value } = e.target;


        // Check if the Residential Location is empty or invalid
        if (value === '') {
            setExpriydateError('Please Select a Expiry Date of Civil ID / Passport');
        } else {
            const selectedDate = new Date(value);
        const currentDate = new Date();

        if (selectedDate < currentDate) {
            document.getElementsByName("expiryDateof")[0].focus();
            setExpriydateError('Please Select a Valid Expiry Date of Civil ID / Passport');
        } else if (isNaN(selectedDate)) {
            document.getElementsByName("expiryDateof")[0].focus();
            setExpriydateError('Invalid Expiry Date');
        } else {
            setExpriydateError('');
        }
        }
    };

    const handleDOBBlur = (e) => {
        const { value } = e.target;
        const formattedDate = e.target.value.replace(/\//g, '-');
        const [year, month, day] = formattedDate.split("-");
        // const finalformattedDate = `${day}-${month}-${year}`;
        const currentMonth = new Date().getMonth() + 1;
        if (age < 18) {
            setDobError("Age must be within 18 to 54 years.");
        } else if (age > 54) {
            setDobError("Age must be within 18 to 54 years.");
        } else if (age === 54 && parseInt(month, 10) < currentMonth) {
            setDobError("Age must be within 18 to 54 years, and for age 54, the month must be the current month or later.");
        } else {
            setDobError("");
        }
    
       
    };



    const isPremiumVisible = selectedGender !== 'Select Gender' && selectedMaritalStatus !== 'Select Status' && selectedDateofBirth !== null;
    // const isProceedVisible = formData.fullName !== '' && formData.email !== '' && formData.mobileNumber !== '' && formData.dob !== '' && formData.civilIDno !== '' && formData.passportNumber !== '' && formData.height !== '' && formData.weight !== '' && formData.occupation !== '' && formData.resident_location !== '' && formData.nationality !== '' && formData.gender !== '' && formData.is_married !== '';
    var isProceedVisible = false;

    const handleChange = (e) => {

        setFormData({ ...formData, [e.target.name]: e.target.value });
        console.log(formData)

    };

    const handleChange1 = (name, value) => {

        setFormData({ ...formData, [name]: value });
        console.log(formData);
    };
    const handleChange2 = (name, englishValue, codeValue) => {
        debugger
        // setFormData({ ...formData, [name]: englishValue });  // Store the English value in formData
        // setSelectedCode(codeValue);  // Store the CODE in a separate variable
        // console.log('Form Data:', formData);
        // console.log('Selected Code:', codeValue);

        setFormData({ ...formData, [name]: englishValue });  // Store the English value in formData
        setSelectedCode(codeValue);
    };
    //   const handleChange2 = (e) => {

    //     setFormData({ ...formData, [e.target.name]:formatDate( e.target.value )});
    //     console.log(formData)

    // };
    function getMinDateForAge54() {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1; // Months are zero-based
        const minYear = currentYear - 54;
        const minMonth = currentMonth.toString().padStart(2, "0"); // Ensure two digits for the month
        return `${minYear}-${minMonth}-01`; // Use the 1st of the calculated month
      }
    const formatDate = (date) => {
        if (!date) return '';
        const d = new Date(date);
        let day = d.getDate().toString().padStart(2, '0');
        let month = (d.getMonth() + 1).toString().padStart(2, '0');
        let year = d.getFullYear();
        return `${day}-${month}-${year}`;
    };
    const calculateBmi = (weight, height) => {
        const w = parseFloat(weight);
        const h = parseFloat(height) / 100; // Convert height from cm to meters
    
        if (isNaN(w) || isNaN(h) || h <= 0 || w <= 0) {
        //   setError('Please enter valid height and weight.');
          setBmi('');
          return false;
        }
    
        const bmiValue = (w / (h ** 2)).toFixed(2);
    
        // Adjust the conditions to properly check the BMI ranges
        debugger
        if (bmiValue > 19 && bmiValue < 30) {
          setBmi(bmiValue);
        //   setError('');
        return true
        } else {
            setBmi(bmiValue);
            return false
        //   setError('BMI is within the normal range (19-30).');
          
        }
      };
    // const handleChange = (e) => {
    //     const { name, value } = e.target;

    //     // Regular expression to allow only English letters and spaces
    //     const englishLetterPattern = /^[a-zA-Z\s]*$/;
    //     // Email validation pattern
    //     const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    //     // Apply the pattern only for the 'fullName' field
    //     if (name === 'fullName' && !englishLetterPattern.test(value)) {
    //         // If invalid, return early without updating the formData
    //         return;
    //     }
    //     if (name === 'email' && !emailPattern.test(value)) {

    //         console.log("Invalid email format");
    //         // Optionally, set an error state here if you want to show a message to the user
    //     }

    //     // Update formData for valid input
    //     setFormData({ ...formData, [name]: value });
    // };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
    };
    const today = new Date();
    const maxDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate() + 1).toISOString().split('T')[0];


    const [dobValue, setdobValue] = useState('');

    const handledobChange = (event) => {
        const selectedDob = event.target.value;
        setdobValue(event.target.value);

        const dobDate = new Date(selectedDob);
        const today = new Date();
        let calculatedAge = today.getFullYear() - dobDate.getFullYear();
        const monthDiff = today.getMonth() - dobDate.getMonth();

        // Adjust age if the current date is before the user's birthday in the current year
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dobDate.getDate())) {
            calculatedAge--;
        }

        setAge(calculatedAge);
    };

    const [formRows, setFormRows] = useState([{
        first_name: '',
        last_name: '',

    }]);
    const [beneficiaryData, setBeneficiaryData] = useState([{
        name: '',
        relation: '',
        benefit_percentage: '',
    }]);
    //new add code
    const handleAddRownew = () => {
        let allFieldsFilled = true;

        // Loop through the beneficiaryData to check if all required fields are filled
        for (let i = 0; i < beneficiaryData.length; i++) {
            const { name, relation, benefit_percentage } = beneficiaryData[i];

            // Check if the name or relation is empty
            if (!name || !relation) {
                allFieldsFilled = false;
                alert(`Please fill all fields for Beneficiary ${i + 1}`);
                break;
            }

            // If benefit_percentage field exists, check if it is empty
            if (beneficiaryData.length > 1 && !benefit_percentage) {
                allFieldsFilled = false;
                alert(`Please fill the Benefit Percentage for Beneficiary ${i + 1}`);
                break;
            }
        }

        // If all fields are filled, allow adding a new beneficiary
        if (allFieldsFilled) {
            // Logic to add a new row
            const newBeneficiary = { name: '', relation: '', benefit_percentage: '' };
            setBeneficiaryData([...beneficiaryData, newBeneficiary]);
        }
    };

    const [questionsData, setQuestionsData] = useState([{
        english: '',
        arabic: '',
        key: '',
        accepted_answer: '',
        isChecked: '',
        isChecked_answer: '',
        details: ''
    }]);
    const handleRemoveRow = (index) => {
        // const rows = [...formRows];
        // rows.splice(index, 1);
        // setFormRows(rows);
        const rows = [...beneficiaryData];
        rows.splice(index, 1);
        setBeneficiaryData(rows);
    };

    const handleAddRow = () => {
        // setFormRows([...formRows, {
        //     first_name: '',
        //     last_name: '',

        // }]);
        setBeneficiaryData([...beneficiaryData, {
            name: '',
            relation: '',
            benefit_percentage: '',

        }]);
    };
    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const [selectedValue, setSelectedValue] = useState('Yes');

    const handleChanges = (e) => {
        setSelectedValue(e.target.value);
    };
    function handleRadioChange(index, isChecked) {
        const updatedQuestionsData = [...questionsData];
        updatedQuestionsData[index] = {
            ...updatedQuestionsData[index],
            isChecked,
            isChecked_answer: isChecked ? "Yes" : "No"
        };
        if (index == 3 && !isChecked) {
            setDownload(true);
        } else if (index == 3 && isChecked) {
            setDownload(false);
        }
        setQuestionsData(updatedQuestionsData);
    }

    function handleDetailsChange(index, details) {

        const updatedQuestionsData = [...questionsData];
        updatedQuestionsData[index] = {
            ...updatedQuestionsData[index],
            details
        };
        setQuestionsData(updatedQuestionsData);
    }
    const handleInputChange = async (index, event, names) => {
        setbeneficiarycheck(false);
        // console.log(event);;
        const { name, value } = event.target;
        const rows = [...beneficiaryData];

        if (names === 'name') {
            rows[index][name] = event.target.value;
        }
        else if (names === 'relation') {
            rows[index]['relation'] = event.target.value;
        }
        else if (names === 'benefit_percentage') {

            // const currentTotal = rows.reduce((total, person, idx) => {
            //     if (idx !== index) {
            //         return total + parseInt(person.benefit_percentage, 10);
            //     } else {
            //         return total;
            //     }
            // }, 0);

            // if (currentTotal + parseInt(event.target.value, 10) > 100) {
            //     alert("Total percentage cannot exceed 100.");

            //     event.preventDefault();
            // } else {
            //     rows[index]['benefit_percentage'] = event.target.value;
            // }
            debugger
            const currentTotal = rows.reduce((total, person, idx) => {
                if (idx !== index) {
                    return total + parseInt(person.benefit_percentage, 10);
                } else {
                    return total;
                }
            }, 0);

            if (currentTotal + parseInt(event.target.value, 10) > 100) {
                alert("Total percentage is not valid");


                rows[index]['benefit_percentage'] = '';

            } else {

                settotpercentage(currentTotal + parseInt(event.target.value, 10));

                rows[index]['benefit_percentage'] = event.target.value;
            }

        }

        setBeneficiaryData(rows);

       
    };
    useEffect(async () => {
        // update sanad data in superjet
        const urlSearchString = window.location.search;
        const uparams = new URLSearchParams(urlSearchString);
        const sanadUserName = uparams.get('SANADUSERNAME');
        const sanadRequesteeName = uparams.get('REQUESTEENAME');
        const sanadUserMobileNumber = uparams.get('REQUESTEEMOBILENO');
        const sanadReferenceNumber = uparams.get('SANADREFNUMBER');
        const sanadCivilIDNumber = uparams.get('REQUESTEECIVILNO');
        const sanadAccessToken = uparams.get('SANADACCESSTOKEN');
        const sanad_ServiceRequestId = uparams.get('SERVICEREQUESTID');
        const sanad_TransDate = uparams.get('TRANSDATE');
        const sanad_ReturnUrl = uparams.get('RETURNURL');
        const sanad_ErrorUrl = uparams.get('ERRORURL');
        const sanad_BackUrl = uparams.get('BACKURL');
        const sanad_Language = uparams.get('LANGUAGE');
        setSanadUserName(sanadUserName);
        setSanadRequesteename(sanadRequesteeName); 
        setSanadUserMobileNumber(sanadUserMobileNumber);
        setSanadReferenceNumber(sanadReferenceNumber);
        setSanadCivilIDNumber(sanadCivilIDNumber);
        setSanadServiceRequestId(sanad_ServiceRequestId);
        setSanadTransDate(sanad_TransDate);
        setSanadReturnUrl(sanad_ReturnUrl);
        setSanadErrorUrl(sanad_ErrorUrl);
        setSanadBackUrl(sanad_BackUrl);
        setSanadLanguage(sanad_Language);
        // const response = await fetch(base_url + '/verify_sanad_access_token', {
        //     method: 'POST',
        //     headers: api_headers,
        //     body: JSON.stringify({
        //         sanad_access_token: sanadAccessToken // Serializing the body as JSON
        //     })
        // });
        // const data = await response.ok ? await response.json() : Promise.reject('Network response was not ok');

        // debugger
        // if (data.message === 'success') {
        //     setIsHidePage('success');
        // } else {
        //     setIsHidePage('fail');
        // }

        if (sanadAccessToken === "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImFkbWluQHNhbmFkLmNvbSIsInBhc3N3b3JkIjoiMTIzNCIsImlhdCI6MTcyODA1MjA0NywiZXhwIjoxNzI4MDUyOTQ3fQ.ucyIy6q6ovSDstXZL1zO-9swTAGYFeypV0uo8_rQJJc") {
            setIsHidePage('success');
        } else {
            setIsHidePage('fail');
        }
        var sanad_urls_data = {
            'back_url': sanad_BackUrl,
            'return_url': sanad_ReturnUrl,
            'error_url': sanad_ErrorUrl,
        }
        // debugger
        //    sessionStorage.setItem("sanadUrlData", JSON.stringify(sanad_urls_data));
        // const expiryDate = new Date();
        // expiryDate.setTime(expiryDate.getTime() + (1 * 60 * 60 * 1000));
        // Cookies.set('sanadUrlData', JSON.stringify(sanad_urls_data), { expires: expiryDate });
        setFormData({
            fullName: sanadRequesteeName,
            email: '',
            mobileNumber: sanadUserMobileNumber,
            dob: '',
            civilIDno: sanadCivilIDNumber,
            passportNumber: '',
            height: '',
            weight: '',
            resident_location: '',
            nationality: '',
            vat_in: '',
            gender: '',
            is_married: '',
            expiryDateof: ''
        });
    }, []);

    useEffect(() => {
        //debugger
        // Initialize Select2
        $(selectOpt1.current).select2();

        // Handle Select2 change event
        $(selectOpt1.current).on('select2:select', (e) => {
            //debugger
            // Find the selected option by matching the code (value)
            const selectedOption = occupationList.find(option => option.CODE === e.params.data.id);
            // debugger

            setSelectedCode(e.params?.data?.id?.toString());
            setSelectedoccupation(e.params?.data?.text?.split('-')[0]?.trim());
            // setFormData({ ...formData, ['occupation']: e.params?.data?.text?.split('-')[0]?.trim()});
            // setFormData({ ...formData, [name]: value });

            // Call onChange with both the English name and the code
            // if (selectedOption) {
            //   //debugger
            //   onChange(name, selectedOption.ENGLISH, selectedOption.CODE);  // Pass both ENGLISH and CODE
            // }
        });

        // Clean up Select2 instance on unmount
        // return () => {
        //     $(selectOpt1.current).select2('destroy');
        // };
    }, [occupationList]);
    useEffect(() => {
        //debugger
        // Initialize Select2
        $(selectnationalty.current).select2();

        // Handle Select2 change event
        $(selectnationalty.current).on('select2:select', (e) => {
            //debugger
            // Find the selected option by matching the code (value)
            const selectedOption_nat = countryList.find(option => option===e.params.data.id);
            // debugger
         setNationalityError("");
            
        setFormData(prevFormData => ({
            ...prevFormData,
            nationality: selectedOption_nat,
        }));
            
        });
    
    }, [countryList]);
    const parseDate = (dateString) => {
        const [day, month, year] = dateString.split('-').map(Number);
        return new Date(year, month - 1, day); // month is 0-indexed
    };
    const calculateAge = (dob) => {
        const today = new Date();
        const birthDate = parseDate(dob);

        // Check if birthDate is valid
        if (isNaN(birthDate.getTime())) {
            throw new Error('Invalid date');
        }

        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();

        // Adjust age if the birth date hasn't occurred yet this year
        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }

        return age;
    };
    useEffect(async () => {

        const fetchData = async () => {
            try {
                var accessTokenData = await createAccessToken();
                var accessToken = accessTokenData?.result?.data?.token;
                if (accessToken != null && accessToken != undefined) {
                    document.getElementsByName("gender_value")[0].focus();
                    setAccessTokenKey(accessToken);
                    await getRelationLists(accessToken);
                    await getBasicPremiumPlan(accessToken);
                    await getOccupationList(accessToken);
                    await getCountryList(accessToken);
                    await getQuestionList(accessToken);
                    // debugger
                    // console.log('accessTokenData', accessTokenData)
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchData();
    }, []);


    const createAccessToken = async () => {
        try {
            const response = await fetch(base_url + '/create_sanad_term_plan_token', {
                method: 'GET',
                headers: api_headers
            });
            const data = await response.ok ? await response.json() : Promise.reject('Network response was not ok');
            if (data.message === 'success') {
                return data;
            } else {
                return null;
            }
        } catch (error) {
            return null;
        }
    };
    const getRelationLists = async (accessToken) => {
        try {
            // debugger
            const response = await fetch(base_url + '/get_sanad_term_plan_relations', {
                method: 'POST',
                headers: api_headers,
                body: JSON.stringify({
                    token: accessToken // Serializing the body as JSON
                })
            });
            const data = await response.ok ? await response.json() : Promise.reject('Network response was not ok');
            if (data.message === 'success') {
                setRelationList(data?.result?.data);
            } else {
                setRelationList([]);
            }
        } catch (error) {
            setRelationList([]);
            // return null;
        }
    };
    const getBasicPremiumPlan = async (accessToken) => {
        try {
            const response = await fetch(base_url + '/get_sanad_term_plan_premium', {
                method: 'POST',
                headers: api_headers,
                body: JSON.stringify({
                    token: accessToken // Serializing the body as JSON
                })
            });
            const data = await response.ok ? await response.json() : Promise.reject('Network response was not ok');
            if (data.message === 'success') {
                setBasicPremium(data?.result?.data?.prices);
                // setRelationList(data?.result?.data);
            } else {
                setBasicPremium(null);
            }
        } catch (error) {
            setBasicPremium(null);
            // return null;
        }
    };
    const getOccupationList = async (accessToken) => {
        try {

            const response = await fetch(base_url + '/get_sanad_term_plan_occupations', {
                method: 'POST',
                headers: api_headers,
                body: JSON.stringify({
                    token: accessToken // Serializing the body as JSON
                })
            });
            const data = await response.ok ? await response.json() : Promise.reject('Network response was not ok');
            if (data.message === 'success') {
                setOccupationList(data.result.data);
                // setRelationList(data?.result?.data);
            } else {
                setBasicPremium(null);
            }
        } catch (error) {
            setOccupationList(null);
            // return null;
        }
    };
    const getCountryList = async (accessToken) => {
        try {
            const response = await fetch(base_url + '/get_sanad_term_plan_countries', {
                method: 'POST',
                headers: api_headers,
                body: JSON.stringify({
                    token: accessToken // Serializing the body as JSON
                })
            });
            const data = await response.ok ? await response.json() : Promise.reject('Network response was not ok');
            if (data.message === 'success') {
                setCountryList(data.result.data);
                // setRelationList(data?.result?.data);
            } else {
                setCountryList(null);
            }
        } catch (error) {
            setCountryList(null);
            // return null;
        }
    };
    const getQuestionList = async (accessToken) => {
        try {
            const response = await fetch(base_url + '/get_sanad_term_plan_questions', {
                method: 'POST',
                headers: api_headers,
                body: JSON.stringify({
                    token: accessToken // Serializing the body as JSON
                })
            });
            const data = await response.ok ? await response.json() : Promise.reject('Network response was not ok');
            if (data.message === 'success') {
                setQuestionList(data.result.data);
                var questionsLists = data.result.data;
                questionsLists = questionsLists.map(question => ({
                    english: question.English,
                    arabic: question.Arabic,
                    key: question.key,
                    accepted_answer: question.accepted_answer,
                    isChecked: '',
                    details: '',
                    isChecked_answer: ''
                }));
                setQuestionsData(questionsLists);
                // setRelationList(data?.result?.data);
            } else {
                setQuestionList(null);
            }
        } catch (error) {
            setQuestionList(null);
            // return null;
        }
    };
    const getQuotation = async (accessToken) => {
        try {
           
            if (beneficiaryData.length == 1) {
                beneficiaryData[0].benefit_percentage = '100';
                setBeneficiaryData(beneficiaryData);
            }

            var quotation_data =
            {
                "token": accessToken,
                "cover_type": "Term Plan",
                "application_type": "self",
                "plan": "Term Plan",
                "nationality": formData.nationality,
                "civil_id": formData.civilIDno,
                "sum_assured": selectedPremium.sum_assured,
                "personalInformation": {
                    "fullName": formData.fullName,
                    "email": formData.email,
                    "mobileNumber": formData.mobileNumber,
                    "dob": formData.dob,
                    "resident_location": formData.resident_location,
                    "gender": formData.gender.toLowerCase(),
                    "is_married": formData.is_married.toLowerCase(),
                    "height": formData.height.toString(),
                    "weight": formData.weight.toString(),
                    "occupation": selectedoccupation,
                    "passport": formData.passportNumber,
                    "vat_in": formData.vat_in,
                },
                "beneficiaries": beneficiaryData
            };
            const response = await fetch(base_url + '/get_sanad_term_plan_quotation', {
                method: 'POST',
                headers: api_headers,
                body: JSON.stringify(quotation_data)
            });
            const data = await response.ok ? await response.json() : Promise.reject('Network response was not ok');
            if (data.message === 'success') {
                setQuotationData(data?.result?.data?.amount);
                await addTermPlanPolicy(accessToken, data?.result?.data?.amount);
                // setCountryList(data.result.data);
                // setRelationList(data?.result?.data);
            } else {
                setQuotationData(null);
            }
        } catch (error) {
            setQuotationData(null);
            // return null;
        }
    };
    const addTermPlanPolicy = async (accessToken, amount_details) => {
        try {
            var policy_data =
            {
                "token": accessToken,
                "sanad_username": sanadUserName,
                "sanad_requesteename": sanadRequesteeName,
                "sanad_reference_no": sanadReferenceNumber,
                "sanad_civil_no": sanadCivilIDNumber,
                "sanad_passport_no": formData.passportNumber,
                "sanad_service_requestid": sanadServiceRequestId,
                "sanad_data": {
                    "sanadUserName":sanadUserName,
                    "sanad_requesteename": sanadRequesteeName,
                    "sanadUserMobileNumber": sanadUserMobileNumber,
                    "sanadReferenceNumber": sanadReferenceNumber,
                    "sanadCivilIDNumber": sanadCivilIDNumber,
                    "sanad_ServiceRequestId": sanadServiceRequestId,
                    "sanad_TransDate": sanadTransDate,
                    "sanad_ReturnUrl": sanadReturnUrl,
                    "sanad_ErrorUrl":sanadErrorUrl,
                    "sanad_BackUrl":sanadBackUrl,
                    "sanad_Language": sanadLanguage,
                    "sanad_passport_no": formData.passportNumber
                },
                "policy_data": {
                    "cover_type": "Term Plan",
                    "application_type": "self",
                    "plan": "Term Plan",
                    "nationality": formData.nationality,
                    "civil_id": formData.civilIDno,
                    "sum_assured": selectedPremium.sum_assured,
                    "personalInformation": {
                        "fullName": formData.fullName,
                        "email": formData.email,
                        "mobileNumber": formData.mobileNumber,
                        "dob": formData.dob,
                        "resident_location": formData.resident_location,
                        "gender": formData.gender.toLowerCase(),
                        "is_married": formData.is_married.toLowerCase(),
                        "height": formData.height.toString(),
                        "weight": formData.weight.toString(),
                        "occupation": selectedoccupation,
                        "occupation_code": selectedCode,
                        "id_expiry_date": formatDate(formData.expiryDateof),
                        // "id_expiry_date":formData.expiryDateof,
                        "passport": formData.passportNumber,
                        "vat_in": formData.vat_in,
                    },
                    "beneficiaries": beneficiaryData,
                    "amount": amount_details,
                    "on_medication": questionsData[0].isChecked_answer,
                    "have_travelled": questionsData[1].isChecked_answer,
                    "in_good_health": questionsData[2].isChecked_answer,
                    "is_armed_member": questionsData[3].isChecked_answer,
                    "signature": signatureRef.current.toDataURL()
                }
            };
            // debugger
            const response = await fetch(base_url + '/get_sanad_term_plan_add_policy', {
                method: 'POST',
                headers: api_headers,
                body: JSON.stringify(policy_data)
            });
            const data = await response.ok ? await response.json() : Promise.reject('Network response was not ok');
            if(data.message === 'success'&&data.result==='sanad reference number already exist'){
                setLoading(false);
                alert('Sanad reference number already exists. Please create a new reference number.\nالرقم المرجعي لسند موجود بالفعل. الرجاء إنشاء رقم مرجعي جديد.');
            }else if (data.message === 'success') {
                setLoading(false);
                if (data?.result?.policy_number != null && data?.result?.policy_number != undefined) {
                    setDone(true);
                    setTermPlamPolicyData(data?.result?.policy_number);
                    await createPolicyPaymentLink(accessToken, data?.result?.policy_number);
                } else {
                    if (data?.result?.data?.decision_reasons != undefined && data?.result?.data?.decision_reasons[0] != undefined) {
                        // alert(data?.result?.data?.decision_reasons[0]);
                        setDone(true);
                        setModalMessage(data?.result?.data?.decision_reasons[0]);
                        setModalMessageNO(data?.result?.data?.referal_quote_id);
                        setModalMessagePolicy(data?.result?.message);
                         setIsModalOpen(true);
                    }

                }

                // setCountryList(data.result.data);
                // setRelationList(data?.result?.data);
            } else {
                setTermPlamPolicyData(null);
            }
            // if (data.message === 'success') {
            //     setLoading(false);
            //     if (data?.result?.policy_number != null && data?.result?.policy_number != undefined) {
            //         setTermPlamPolicyData(data?.result?.policy_number);
            //         await createPolicyPaymentLink(accessToken, data?.result?.policy_number);
            //     } else {
            //         if (data?.result?.data?.decision_reasons != undefined && data?.result?.data?.decision_reasons[0] != undefined) {
            //             alert(data?.result?.data?.decision_reasons[0]);
            //         }

            //     }

            //     // setCountryList(data.result.data);
            //     // setRelationList(data?.result?.data);
            // } else {
            //     setTermPlamPolicyData(null);
            // }
        } catch (error) {
            setTermPlamPolicyData(null);
            // return null;
        }
    };
    const createPolicyPaymentLink = async (accessToken, policy_no) => {
        try {
            var policy_data =
            {
                "token": accessToken,
                "policy_no": policy_no,
                "redirect_url": redirecturl + "/download-sanad-termpolicy?policyno=" + policy_no

            }
            const response = await fetch(base_url + '/create_sanad_term_plan_payment_link', {
                method: 'POST',
                headers: api_headers,
                body: JSON.stringify(policy_data)
            });
            const data = await response.ok ? await response.json() : Promise.reject('Network response was not ok');
            if (data.message === 'success') {
                setTermPlanPolicyPaymentLink(data?.result?.data.payment_url);
                // setCountryList(data.result.data);
                // setRelationList(data?.result?.data);
            } else {
                setTermPlanPolicyPaymentLink(null);
            }
        } catch (error) {
            setTermPlanPolicyPaymentLink(null);
            // return null;
        }
    };
    const clearSignature = () => {
        signatureRef.current.clear();
        setIsSignatureEmpty(true); // Disable button after clearing
    };
    const handleSignature = () => {
        if (!signatureRef.current.isEmpty()) {
            setIsSignatureEmpty(false); // Enable button when signature is drawn
        }
    };
    const handleDownload = () => {
        setDownload(true);
    };


    const handleUpload = async (e) => {

        debugger;
        e.preventDefault();
        setquestionFileError('');
        setPassportFileError('');
        const dhofar = accessTokenKey;
        const policy = termPlanPolicyData;
        const formData = new FormData();
        formData.append('token', dhofar);
        formData.append('policy_number', policy);
        debugger
        if(passportFile == null){
          setPassportFileError('Please select Passport file ');
        }else if(questionnaireFile== null){
          setquestionFileError('Please select Questionnaire file ');
        }else{
          if (passportFile !== null) {
            formData.append('passport', passportFile);
          }
      
          if (questionnaireFile !== null) {
            formData.append('questionnaire', questionnaireFile);
          }
      
          const response = await fetch(base_url + '/term_plan_file_upload', {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${vendor_key}`
            },
            body: formData
          });
          const data = await response.json();
          if (data.result.success) {
            alert(data.result.message);
            handleClose();
          } else {
            alert('file is not uploaded try again');
            handleClose();
          }
    
        }
    
       
      };
    
      const handleFileChange = (e) => {
        debugger;
        if (e.target.files.length === 1) {
          const file = e.target.files[0];
          const name = e.target.name;
          const fileType = file.type;
          const maxSize = 2 * 1024 * 1024; // 5MB in bytes
    
          // Check if the file exceeds the size limit
          if (file && file.size > maxSize) {
            alert('File size exceeds the 2MB limit.');
            return;
          }
    
          if (fileType.startsWith('image') || fileType === 'application/pdf') {
            if (name === 'passport' && passportFile == null) {
              setPassportFile(file);
            } else if (name === 'passport' && passportFile !== null && passportFile !== '' && passportFile !== undefined) {
              setPassportFile(null);
              setPassportFile(() => file);
            } else if (name === 'questionnaire' && questionnaireFile == null) {
              setQuestionnaireFile(file);
            } else if (name === 'questionnaire' && questionnaireFile !== null && questionnaireFile !== '' && questionnaireFile !== undefined) {
              setQuestionnaireFile(null);
              setQuestionnaireFile(() => file);
            }
          } else {
            alert('Please select a valid image or PDF file.');
          }
          document.getElementById('passport').value = '';
          document.getElementById('questionnaire').value = '';
        }
      };
      const handleFileDelete = (e) => {
        // debugger;
        const name = e.target.getAttribute('data-name');
        if (name === 'questionnaire') {
          setQuestionnaireFile(null);
          document.getElementById('questionnaire').value = '';
        } else if (name === 'passport') {
          setPassportFile(null);
          document.getElementById('passport').value = '';
        }
      };
    
      const handleClose = () => {
        setShowUploadModal(false);
      };
    
      const handleShow = () => {
        setShowUploadModal(true);
      };

      

    return (
        isHidePage === 'success' ? <Fragment>
            <form onSubmit={handleSubmit} onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    e.preventDefault(); // Prevent Enter key from submitting the form
                }
            }} className="visa-form1">
                <div>
                    <div style={{ display: 'flex', paddingBottom: '20px' }}>
                        <button style={{ width: '100px', fontWeight: 'bold' }} onClick={() => {
                            const regex = /^(ftp|http|https):\/\/[^ "]+$/;
                            var isValidUrl = regex.test(sanadBackUrl);
                            if (isValidUrl) {
                                window.location.href = sanadBackUrl;
                            } else {
                                window.location.reload();
                            }

                        }} >Back</button>
                    </div>
                    <div className="row mb-3">


                        <div className="col-md-6">
                            <div>
                                <div className='row mb-3'>
                                    <div className='col-md-6 text-start'>
                                        <h2>Insured Details</h2>
                                    </div>
                                    <div className='col-md-6 text-right'>
                                        <h2>تفاصيل المؤمن عليه</h2>
                                    </div>
                                </div>
                                <div className='inside_visa-form'>
                                    <div className='col-md'>
                                        <div className='row'>
                                            <div className='col-md-6 text-start'>
                                            <label>Gender <span style={{ color: 'red' }}>*</span> </label>
                                            </div>
                                            <div className='col-md-6 text-right'>
                                                <label>جنس</label>
                                            </div>
                                        </div>


                                        <select name="gender_value" className="form-control " style={{ direction: 'ltr' }}
                                            onChange={(e) => {
                                                setGenderError("");
                                                setSelectedGender(e.target.value);
                                                setFormData({ ...formData, ['gender']: e.target.value });

                                            }}
                                            value={selectedGender}
                                            required>
                                            <option value="">Select Gender</option>
                                            {genderList.map((gender, index) => (
                                                <option key={index} value={gender}>
                                                    {gender.charAt(0).toUpperCase() + gender.slice(1)}
                                                </option>
                                            ))}

                                        </select>
                                        {genderError && <span style={{ color: "red" }}>{genderError}</span>}
                                    </div>
                                    <div className='col-md'>
                                        <div className='row'>
                                            <div className='col-md-6 text-start'>
                                            <label>Are you Married? <span style={{ color: 'red' }}>*</span> </label>
                                            </div>
                                            <div className='col-md-6 text-right'>
                                                <label>هل أنت متزوج ؟</label>
                                            </div>
                                        </div>


                                        <select name="marital_status" className="form-control " style={{ direction: 'ltr' }}
                                            onChange={(e) => {
                                                setMarriedError("");
                                                setSelectedMaritalStatus(e.target.value);
                                                setFormData({ ...formData, ['is_married']: e.target.value });
                                            }}
                                            value={selectedMaritalStatus}
                                            required>
                                            <option value="">Select Status</option>
                                            {maritalStatusList.map((maritalStatus, index) => (
                                                <option key={index} value={maritalStatus}>
                                                    {maritalStatus.charAt(0).toUpperCase() + maritalStatus.slice(1)}
                                                </option>
                                            ))}

                                        </select>
                                        {marriedError && <span style={{ color: "red" }}>{marriedError}</span>}
                                    </div>
                                    <div className='col-md'>
                                        <div className='row'>
                                            <div className='col-md-6 text-start'>
                                            <label>Date Of Birth <span style={{ color: 'red' }}>*</span></label>
                                            </div>
                                            <div className='col-md-6 text-right'>
                                                <label>تاريخ الميلاد</label>
                                            </div>
                                        </div>


                                        {/* <input type="date" name="dob" max={maxDate} min="1964-01-01" value={selectedDateofBirth} className="form-control " onChange={(e) => {
                                            setDobError("");
                                            setSelectedDateofBirth(e.target.value);
                                            const formattedDate = e.target.value.replace(/\//g, '-');
                                            const [year, month, day] = formattedDate.split("-");
                                            const finalformattedDate = `${day}-${month}-${year}`;
                                            setFormData({ ...formData, ['dob']: finalformattedDate });

                                            // const finalAgeFormattedDate = `${day}-${month}-${year}`;
                                            debugger
                                            // Calculate and set the age
                                            const age = calculateAge(finalformattedDate);
                                            setAge(age);
                                            if(age<18)
                                            {
                                                setDobError('Your Age is invalid');
                                                // alert("Age is less than 18");
                                                // setSelectedDateofBirth("");
                                            }
                                            if(age>=18)
                                            {
                                                setDobError('');

                                                // alert("Age is less than 18");
                                                // setSelectedDateofBirth("");
                                            }


                                        }} onBlur={handleDOBBlur} required /> */}
                                         <input type="date" name="dob" 
                                         max={maxDate} 
                                         min={getMinDateForAge54()} 
                                         value={selectedDateofBirth} 
                                         className="form-control " 
                                         onChange={(e) => {
                                            setDobError("");
                                            setSelectedDateofBirth(e.target.value);
                                            const formattedDate = e.target.value.replace(/\//g, '-');
                                            const [year, month, day] = formattedDate.split("-");
                                            const finalformattedDate = `${day}-${month}-${year}`;
                                            setFormData({ ...formData, ['dob']: finalformattedDate });

                                            // const finalAgeFormattedDate = `${day}-${month}-${year}`;
                                            debugger
                                            // Calculate and set the age
                                            const age = calculateAge(finalformattedDate);
                                            setAge(age);
                                            const currentMonth = new Date().getMonth() + 1;
                                            debugger
                                            // Validation logic
                                                if (age < 18) {
                                                    setDobError("Age must be within 18 to 54 years.");
                                                } else if (age > 54) {
                                                    setDobError("Age must be within 18 to 54 years.");
                                                } else if (age === 54 && parseInt(month, 10) < currentMonth) {
                                                    setDobError("Age must be within 18 to 54 years, and for age 54, the month must be the current month or later.");
                                                } else {
                                                    setDobError("");
                                                }
                                           
                                        }} onBlur={handleDOBBlur}  required />
                                        {/* {age !== null && <p>Age: {age}</p>} */}
                                        {age !== null && <p>Your age is {age} year(s).</p>}
                                        {dobError && <span style={{ color: "red" }}>{dobError}</span>}
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div className="col-md-6">
                            <div>
                                <div className='row mb-3'>
                                    <div className='col-md-6 text-start'>
                                        <h2>Term Plan Accident Cover</h2>
                                    </div>
                                    <div className='col-md-6 text-right'>
                                        <h2>تغطية الحوادث الشخصية</h2>
                                    </div>
                                </div>
                                <div className="inside_visa-form m-0 p-2">
                                    <div className="row">
                                        {isPremiumVisible && selectedPremium != null ?
                                            <div className="col-4 mb-2">
                                                <div className="d-flex justify-content-between align-items-center border p-2">
                                                    <div>
                                                        <span style={{ fontSize: '14x', fontWeight: 'bold' }}>Premium: {selectedPremium.premium} OMR</span><br />
                                                        <span style={{ fontSize: '10px', fontWeight: 'bold' }}>Sum Assured: {selectedPremium.sum_assured}</span>
                                                    </div>
                                                    <button className="btn btn-primary rounded-circle p-2" style={{ width: '40px', height: '40px' }} onClick={() => {
                                                        setSelectedPremium(null);
                                                    }}>
                                                        <i className="fas fa-times"style={{ color: 'red' }}></i> {/* Font Awesome X Icon */}
                                                    </button>
                                                </div></div> :

                                            isPremiumVisible && Array.isArray(basicPremium) && basicPremium.length > 0 && (
                                                basicPremium.slice(0, 6).map((plan_item, plan_index) => (  // Limit to 6 items
                                                    <div key={plan_index} className="col-4 mb-2">
                                                        <div className="d-flex justify-content-between align-items-center border p-2">
                                                            <div>
                                                                <span style={{ fontSize: '14x', fontWeight: 'bold' }}>Premium: {plan_item.premium} OMR</span><br />
                                                                <span style={{ fontSize: '10px', fontWeight: 'bold' }}>Sum Assured: {plan_item.sum_assured}</span>
                                                            </div>
                                                            <button className="btn btn-primary rounded-circle p-2" style={{ width: '40px', height: '40px' }} onClick={() => {
                                                                setSelectedPremium(plan_item);
                                                            }}>
                                                                <i className="fas fa-plus"></i> {/* Font Awesome Plus Icon */}
                                                            </button>
                                                        </div>
                                                    </div>
                                                ))
                                            )}
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>

                </div>


                <div className="">
                    <div className='row mb-2'>
                        <div className='col-md-6 text-start'>
                            <h2>Add Beneficiary</h2>
                        </div>
                        <div className='col-md-6 text-right'>
                            <h2>إضافة مستفيد</h2>
                        </div>
                    </div>

                    <div className='inside_visa-form'>
                    <form>
                            {
                                beneficiaryData.map((row, index) => (
                                    <div key={index} className='beneficiary-row'>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label>Beneficiary {index + 1}</label>
                                            </div>
                                        </div>
                                        <div className='row mb-3'>
                                            <div className='col-md-4'>
                                                <div className='row'>
                                                    <div className='col-md-6 text-start'>
                                                        <label>Name <span style={{ color: 'red' }}>*</span> </label>
                                                    </div>
                                                    <div className='col-md-6 text-right'>
                                                        <label>اسم</label>
                                                    </div>
                                                </div>


                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    value={row.name}
                                                    name="name"
                                                    data-index={index}
                                                    onChange={(e) => handleInputChange(index, e, 'name')}
                                                    onInput={(e) => {
                                                        e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, '');
                                                      }} // Validate on blur (when user leaves the field)
                                                />
                                            </div>

                                            {beneficiaryData.length > 1 && <div className='col-md-4'>
                                                <div className='row'>
                                                    <div className='col-md-6 text-start'>
                                                        <label>Benefit Percentage <span style={{ color: 'red' }}>*</span> </label>
                                                    </div>
                                                    <div className='col-md-6 text-right'>
                                                        <label>نسبة الاستفادة</label>
                                                    </div>
                                                </div>

                                                <input
                                                    type='number'
                                                    className='form-control'
                                                    name="benefit_percentage"
                                                    value={row.benefit_percentage}
                                                    min="1"
                                                    max="100"
                                                    data-index={index}
                                                    onChange={(e) => handleInputChange(index, e, 'benefit_percentage')}
                                                />
                                            </div>}
                                            {/* )} */}
                                            <div className='col-md-4'>
                                                <div className='row'>
                                                    <div className='col-md-6 text-start'>
                                                    <label>Relation <span style={{ color: 'red' }}>*</span></label>
                                                    </div>
                                                    <div className='col-md-6 text-center'>
                                                        <label>علاقة</label>
                                                    </div>
                                                </div>
                                                {/* <label>Relation</label> */}
                                                <div className='row'>
                                                    <div className='col-md-10'>
                                                        <select
                                                            name='relation'
                                                            className='form-control'
                                                            data-index={index}
                                                            style={{ direction: 'ltr' }}
                                                            onChange={(e) => handleInputChange(index, e, 'relation')}
                                                            required
                                                        >
                                                            <option value='relation'>Select Relation</option>


                                                            {relationList.map((relation, idx) => (
                                                                <option key={idx} value={relation}>
                                                                    {relation.charAt(0).toUpperCase() + relation.slice(1)}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className='col-md-2'>
                                                        {beneficiaryData.length !== 1 && (
                                                            <button
                                                                type='button'
                                                                className='btn btn-danger rounded-pill text-white'
                                                                onClick={() => handleRemoveRow(index)}
                                                            >
                                                                X
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>

                                            </div>


                                        </div>

                                    </div>
                                ))
                            }
                            <div className='row'>
                                <div className='col-md-2'>


                                    <button
                                        type='button'
                                        className='rounded-pill text-white'
                                        style={{ backgroundColor: beneficiaryData.length > 0 && beneficiaryData[0].name != '' && beneficiaryData[0].relation != '' ? '#1D3561' : 'grey', borderColor: '#1D3561' }}
                                        onClick={handleAddRow}
                                        disabled={beneficiaryData.length > 0 && beneficiaryData[0].name != '' && beneficiaryData[0].relation != ''&& beneficiaryData[0].relation != 'Select Relation' ? false : true}
                                    >
                                        Add Beneficiary
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>


                <div className="">
                    {/* <h2>Personal Details</h2> */}
                    <div className='row mb-3 mt-4'>
                        <div className='col-md-6 text-start'>
                            <h2>Personal Details</h2>
                        </div>
                        <div className='col-md-6 text-right'>
                            <h2>التفاصيل الشخصية    </h2>
                        </div>
                    </div>

                    <div className='inside_visa-form'>
                        {/* Row 1: Full Name, Email ID, Mobile Number, and Civil ID Number */}
                        <div className='row mb-3'>
                            <div className='col-md-3'>
                                {/* <label>Full Name:</label> */}
                                <label style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <span>
                                        Full Name <span style={{ color: "red" }}>*</span>
                                    </span>
                                    <span style={{ textAlign: "right", direction: "rtl" }}>
                                        الاسم الكامل
                                    </span>
                                </label>
                                <input
                                    type='text'
                                    className='form-control'
                                    value={formData.fullName}
                                    name='fullName'
                                    onChange={handleChange}
                                    onBlur={handleNameBlur} // Validate on blur (when user leaves the field)
                                    onInput={(e) => {
                                        // e.target.value = e.target.value.replace(/[^\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFFA-Za-z\s]/g, '');
                                        e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, '');
                                      }} 
                                />
                                {nameError && <p style={{ color: 'red' }}>{nameError}</p>} {/* Show error message */}
                            </div>
                            {/* <div className='col-md-3'>
                            <label>Full Name:</label>
                            <input
                                type='text'
                                className='form-control'
                                value={formData.fullName}
                                name='fullName'
                                onChange={handleChange}
                            />
                        </div> */}
                            <div className='col-md-3'>
                                {/* <label>Email ID:</label> */}
                                <label style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <span>
                                    Email ID <span style={{ color: "red" }}>*</span>
                                    </span>
                                    <span style={{ textAlign: "right", direction: "rtl" }}>
                                    معرف البريد الإلكتروني
                                    </span>
                                </label>
                                <input
                                    type='email'
                                    className='form-control'
                                    name='email'
                                    value={formData.email}
                                    onChange={handleChange}
                                    onBlur={handleEmailBlur} // Validate on blur (when user leaves the field)
                                />
                                {emailError && <p style={{ color: 'red' }}>{emailError}</p>} {/* Show error message */}
                            </div>

                            <div className='col-md-3'>
                            <label style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <span>
                                    Mobile Number <span style={{ color: "red" }}>*</span>
                                    </span>
                                    <span style={{ textAlign: "right", direction: "rtl" }}>
                                    رقم الهاتف المحمول
                                    </span>
                                </label>
                                {/* <label>Mobile Number</label> */}
                                <input
                                    type='tel'
                                    className='form-control'
                                    name='mobileNumber'
                                    value={formData.mobileNumber}
                                    onChange={handleChange}
                                    maxLength={8} // Enforce max length in the UI
                                    onBlur={handleMobileBlur} // Validate on blur (when user leaves the field)
                                    onInput={(e) => {
                                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                      }}
                                />
                                {mobileError && <p style={{ color: 'red' }}>{mobileError}</p>} {/* Show error message */}
                            </div>

                            <div className='col-md-3'>
                                {/* <label>Civil ID Number:</label> */}
                                <label style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <span>
                                    Civil ID Number <span style={{ color: "red" }}>*</span>
                                    </span>
                                    <span style={{ textAlign: "right", direction: "rtl" }}>
                                    البطاقة المدنية
                                    </span>
                                </label>
                                <input
                                    type='text'
                                    className='form-control'
                                    name='civilIDno'
                                    value={formData.civilIDno}
                                    onChange={handleChange}
                                    onBlur={handleCivilIDBlur} // Validate on blur (when user leaves the field)
                                    onInput={(e) => {
                                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                      }}
                                />
                                {civilIDError && <p style={{ color: 'red' }}>{civilIDError}</p>} {/* Show error message */}
                            </div>
                        </div>

                        {/* Row 2: Passport Number, Height, Weight, and Nationality */}
                        <div className='row mb-3'>

                            <div className='col-md-3'>
                                {/* <label>Passport Number:</label> */}
                                <label style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <span>
                                    Passport Number <span style={{ color: "red" }}>*</span>
                                    </span>
                                    <span style={{ textAlign: "right", direction: "rtl" }}>
                                    رقم جواز السفر
                                    </span>
                                </label>
                                <input
                                    type='text'
                                    className='form-control'
                                    name='passportNumber'
                                    value={formData.passportNumber}
                                    onChange={handleChange}
                                    onBlur={handlePassportBlur} // Validate on blur (when user leaves the field)
                                    onInput={(e) => {
                                        e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, '');
                                      }}
                                />
                                {passportError && <p style={{ color: 'red' }}>{passportError}</p>} {/* Show error message */}
                            </div>

                            <div className='col-md-3'>
                                {/* <label>Height (in cms):</label> */}
                                <label style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <span>
                                    Height (in cms) <span style={{ color: "red" }}>*</span>
                                    </span>
                                    <span style={{ textAlign: "right", direction: "rtl" }}>
                                    ارتفاع
                                    </span>
                                </label>
                                <input
                                    type='number'
                                    className='form-control'
                                    name='height'
                                    value={formData.height}
                                    // onChange={handleChange}
                                    onChange={(e)=>{
                                        debugger
                                        if(e.target.value.length < 4){
                                            setFormData({ ...formData, [e.target.name]: e.target.value });
                                            calculateBmi ( formData.weight, e.target.value)
                                        }
                                    }}
                                    onBlur={handleHeightBlur} // Validate on blur (when user leaves the field)
                                    min="0" // Ensure that the minimum value is 0
                                    max="999" // Ensure that the maximum value is 999

                                />
                                {heightError && <p style={{ color: 'red' }}>{heightError}</p>} {/* Show error message */}
                                {bmi != 0 && <p style={{
                                    color:'#3fd2f2',
                                }}>Your Body Mass Index is {bmi}</p>}
                            </div>

                            <div className='col-md-3'>
                                {/* <label>Weight (in kgs):</label> */}
                                <label style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <span>
                                    Weight (in kgs) <span style={{ color: "red" }}>*</span>
                                    </span>
                                    <span style={{ textAlign: "right", direction: "rtl" }}>
                                    وزن
                                    </span>
                                </label>
                                <input
                                    type='number'
                                    className='form-control'
                                    name='weight'
                                    value={formData.weight}

                                    // onChange={handleChange}
                                    onChange={(e)=>{
                                        debugger
                                        if(e.target.value.length < 4){
                                            setFormData({ ...formData, [e.target.name]: e.target.value });
                                            calculateBmi (e.target.value, formData.height)
                                        }
                                    }}
                                    onBlur={handleWeightBlur} // Validate on blur (when user leaves the field)
                                    min="0" // Ensure the minimum value is 0
                                    max="999" // Ensure the maximum value is 999
                                />
                                {weightError && <p style={{ color: 'red' }}>{weightError}</p>} {/* Show error message */}
                            </div>

                            <div className='col-md-3'>
                                {/* <label>Nationality:</label> */}
                                <label style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <span>
                                    Nationality <span style={{ color: "red" }}>*</span>
                                    </span>
                                    <span style={{ textAlign: "right", direction: "rtl" }}>
                                    جنسية
                                    </span>
                                </label>
                                {/* <Selectoption name="nationality" options={countryList} value={formData.nationality} onChange={handleChange1} className='form-control ' required /> */}
                                <select
                                    ref={selectnationalty}
                                    className="form-control"
                                    style={{ borderRadius: '5px' }}
                                // onChange={handleChange2}
                                // Add your desired height and border-radius here
                                >
                                    <option>{`${t('Select Nationality')}`}</option>
                                    {
                                          countryList.map((option) => (
                                            <option key={option} value={option}>
                                              {option.charAt(0).toUpperCase() + option.slice(1)}
                                            </option>
                                          ))
                                    }

                                </select>
                                {nationalityError && <p style={{ color: 'red' }}>{nationalityError}</p>} {/* Show error message */}


                            </div>
                        </div>

                        {/* Row 3: Residential Location, Expiry Date, VATIN, and an Empty Placeholder */}
                        <div className='row mb-3'>
                            <div className='col-md-3'>
                                {/* <label>Occupation:</label> */}
                                <label style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <span>
                                    Occupation <span style={{ color: "red" }}>*</span>
                                    </span>
                                    <span style={{ textAlign: "right", direction: "rtl" }}>
                                    إشغال
                                    </span>
                                </label>
                                {/* <div className="styledata"> */}
                                <select
                                    ref={selectOpt1}
                                    className="form-control"
                                    style={{ borderRadius: '5px' }}
                                // onChange={handleChange2}
                                // Add your desired height and border-radius here
                                >
                                    <option>{`${t('Select Occupation')}`}</option>
                                    {occupationList.map((option) => (
                                        <option key={option.CODE} value={option.CODE}>
                                            {option.ENGLISH} - {option.ARABIC}
                                        </option>
                                    ))}

                                </select>
                                {/* </div> */}
                                {occupationError && <p style={{ color: 'red' }}>{occupationError}</p>}
                                


                            </div>

                            <div className='col-md-3'>
                                {/* <label>Residential Location:</label> */}
                                <label style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <span>
                                    Residential Location <span style={{ color: "red" }}>*</span>
                                    </span>
                                    <span style={{ textAlign: "right", direction: "rtl" }}>
                                    سكني
                                    </span>
                                </label>
                                <input
                                    type='text'
                                    className='form-control'
                                    name='resident_location'
                                    value={formData.resident_location}
                                    onChange={handleChange}
                                    onBlur={handleLocationBlur} // Validate on blur (when user leaves the field)
                                />
                                {locationError && <p style={{ color: 'red' }}>{locationError}</p>} {/* Show error message */}
                            </div>
                            <div className='col-md-3'>
                            <label>Expiry Date of Civil ID / Passport <span style={{ color: 'red' }}>*</span></label>
                                <input
                                    type='date'
                                    className='form-control'
                                    name='expiryDateof'
                                    value={formData.expiryDateof}
                                    min={new Date().toISOString().split('T')[0]} // This sets the minimum date to today's date
                                    onChange={handleChange}
                                    onBlur={handleExpriydateBlur} 
                                    //  required
                                // placeholder="dd-mm-yyyy"
                                />
                                
                                {expriydateofError && <p style={{ color: 'red' }}>{expriydateofError}</p>} {/* Show error message */}
                            </div>
                            {/* <div className='col-md-3'>
                            <label>VATIN (optional):</label>
                            <input
                                type='text'
                                className='form-control'
                                name='vat_in'
                                value={formData.vat_in}
                                onChange={handleChange}
                                maxLength={15}
                            />
                        </div> */}
                            <div className='col-md-3'>
                                {/* <label>VATIN (optional):</label> */}
                                <label style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <span>
                                    VATIN (optional)
                                    </span>
                                    <span style={{ textAlign: "right", direction: "rtl" }}>
                                    فاتين
                                    </span>
                                </label>
                                <input
                                    type='text'
                                    className='form-control'
                                    name='vat_in'
                                    value={formData.vat_in}
                                    onChange={handleChange}
                                    maxLength={15} // Limit the input to 15 characters in the UI
                                    onBlur={handleVatBlur} // Validate on blur (when user leaves the field)
                                />
                                {vatError && <p style={{ color: 'red' }}>{vatError}</p>} {/* Show error message */}
                            </div>

                            <div className='col-md-3'>
                                {/* Empty placeholder for alignment */}
                            </div>
                        </div>
                    </div>
                </div>




                <div className="">
                    {/* <h2>Please answer the following</h2> */}
                    <div className='row mb-3 mt-4'>
                        <div className='col-md-6 text-start'>
                            <h2>Please answer the following</h2>
                        </div>
                        <div className='col-md-6 text-right'>
                            <h2>الرجاء الإجابة على ما يلي</h2>
                        </div>
                    </div>

                    <div class='inside_visa-form'>


                        {
                            questionsData.map((question, index) => (
                                <div key={index}>
                                    <p>{index+1}.{question.english}</p>
                                    <div className="custom-radio">
                                        <input
                                            type="radio"
                                            id={`yes-${index}`}
                                            name={`option-${index}`}
                                            value="Yes"
                                            checked={question.isChecked === true}
                                            onChange={() => handleRadioChange(index, true)}
                                        />
                                        {index == 3 ? <label htmlFor={`yes-${index}`}>Yes</label> : <label htmlFor={`yes-${index}`}>I Agree</label>}

                                        <input
                                            type="radio"
                                            id={`no-${index}`}
                                            name={`option-${index}`}
                                            value="No"
                                            checked={question.isChecked === false}
                                            onChange={() => handleRadioChange(index, false)}
                                        />
                                        {index == 3 ? <label htmlFor={`no-${index}`}>No</label> : <label htmlFor={`no-${index}`}>I Disagree</label>}

                                    </div>
                                    <br />
                                    {index === 3 && question.isChecked_answer === 'Yes' ? (
                                        <>
                                            Please download this  <a href={Armedforcesquestionnaire} download="ArmedForcesQuestionnaire.docx" onClick={handleDownload}>
                                                <u>questionnaire</u>

                                            </a> and fill it. You will be asked to upload the filled document after the payment.

                                        </>
                                    ) : ''}
                                    {index != 3 && question.isChecked_answer != '' && question.accepted_answer !== question.isChecked_answer && (
                                        <div className='col-md-6'>
                                            <textarea
                                                style={{
                                                    height: '100px',
                                                    width: '40%'
                                                }}
                                                value={question.details || ''} // Ensure there's a default value
                                                onChange={(e) => handleDetailsChange(index, e.target.value)}
                                                placeholder="Please provide more details"
                                            />
                                        </div>
                                    )}
                                </div>
                            ))}

                        <Col md={12}>
                            <br />

                            <b>Please put your signature here</b><br />

                            <SignatureCanvas
                                ref={signatureRef}
                                penColor="black"
                                canvasProps={{ width: 340, height: 200, className: 'sigCanvas' }}
                                style={{ border: '1px solid #ccc', backgroundColor: 'white' }} // Ensure background is white
                                onEnd={handleSignature} // Detect when drawing ends
                            />
                            <div>
                                <button type="button"
                                    style={{ backgroundColor: !isSignatureEmpty ? '#1D3561' : 'grey', borderColor: '#1D3561' }}
                                    className="rounded-pill text-white my-3 py-1 " onClick={async () => {
                                        clearSignature();
                                    }}
                                    disabled={isSignatureEmpty}
                                >
                                    Clear Signature
                                </button>
                            </div>

                        </Col>


                        <p ><input type="checkbox" checked={isChecked}
                            onChange={handleCheckboxChange} name="note" />
                            {" I declare that the above answers are true to the best of my knowledge and belief, that I have disclosed all particulars affecting the assessment of the risk and that I am and always have been of temperate habits. I agree that this proposal and declaration shall be the basis of the contract between me and the Insurers."}
                        </p>
                        <div className='col-md-2'>
                        {!done&&( <button type="button"
                                style={{ backgroundColor: isChecked ? '#1D3561' : "grey", borderColor: '#1D3561' }}
                                className="rounded-pill text-white my-3 py-1 "
                                // disabled={isChecked ? false : true}
                                onClick={async () => {

                                    // let beneficiarydel= false;
                                    // const englishLetterPattern = /^[a-zA-Z\s]*$/;
                                    // const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                    // const civilIDPattern = /^\d{4,9}$/;
                                    // const passportPattern = /^[a-zA-Z0-9]*$/;
                                    // const locationPattern = /^[a-zA-Z\s,-]+$/;
                                    // const heightValue = Number(formData.height);
                                    // const weightValue = Number(formData.weight);
                                    // setGenderError("");
                                    // setMarriedError("");
                                    // setExpriydateError("");
                                    // setDobError("");
                                    // setOccupationError(""); // Clear the error if valid
                                    // setNationalityError("");
                                    // if (beneficiaryData.length > 0) {
                                    //     if ((beneficiaryData.length === 1 && beneficiaryData[0].name === '') || (beneficiaryData.length === 1 && beneficiaryData[0].relation === '')) {
                                    //         setbeneficiarycheck(true);


                                    //     } else if (beneficiaryData.length > 1) {
                                    //         for (let i = 0; i < beneficiaryData.length; i++) {
                                    //             if (beneficiaryData[i].name === '' || beneficiaryData[i].relation === '' || beneficiaryData[i].benefit_percentage === '') {
                                    //                 setbeneficiarycheck(true);


                                    //             }

                                    //         }

                                    //     }


                                    // }
                                    const [day, month, year] = formData.dob.split("-");
                                    const currentMonth = new Date().getMonth() + 1;
                                    let beneficiarydel= false;
                                    const englishLetterPattern = /^[a-zA-Z\s]*$/;
                                    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                    const civilIDPattern = /^\d{4,9}$/;
                                    const passportPattern = /^[a-zA-Z0-9]*$/;
                                    const locationPattern = /^[a-zA-Z\s,-]+$/;
                                    const heightValue = Number(formData.height);
                                    const weightValue = Number(formData.weight);
                                    setGenderError("");
                                    setMarriedError("");
                                    setExpriydateError("");
                                    setDobError("");
                                    setOccupationError(""); // Clear the error if valid
                                    setNationalityError("");
                                    setExpriydateError('');
                                    setLocationError('');
                                    setWeightError('');
                                    setHeightError('');
                                    setPassportError('');
                                    setCivilIDError('');
                                    setMobileError('');
                                    setEmailError('');
                                    setNameError('');
                                    setVatError('');
                                    if (beneficiaryData.length > 0) {
                                        debugger
                                        if ((beneficiaryData.length === 1 && beneficiaryData[0].name === '') || (beneficiaryData.length === 1 && beneficiaryData[0].relation === '')||(beneficiaryData.length === 1 && beneficiaryData[0].relation === 'relation')) {
                                            setbeneficiarycheck(true);
                                            beneficiarydel= true;
                                            // alert('Please ensure the Beneficiary details');

                                        } else if (beneficiaryData.length > 1) {
                                            if ((totpercentage < 100 || totpercentage > 100) && beneficiaryData.length > 1) {
                                                debugger
                                                alert(" Beneficiary Benefit Percentage total is invalid");
                                                return
                                            }
                                            for (let i = 0; i < beneficiaryData.length; i++) {
                                                if (beneficiaryData[i].name === '' || beneficiaryData[i].relation === '' || beneficiaryData[i].benefit_percentage === ''||beneficiaryData[i].relation === 'relation') {
                                                    setbeneficiarycheck(true);
                                                    beneficiarydel= true;
                                                    // alert('Please ensure the Beneficiary details');

                                                }
                                                

                                            }

                                        }


                                    }
                                    const bmi= calculateBmi(formData.weight,formData.height)

                                    var question_valid = !questionsData.some(q => q.isChecked_answer === '');
                                    var termplan_details_valid = formData.fullName !== '' && formData.email !== '' && formData.mobileNumber !== '' && formData.dob !== '' && formData.civilIDno !== '' && formData.passportNumber !== '' && formData.height !== '' && formData.weight !== '' && formData.resident_location !== '' && formData.nationality !== ''&& formData.nationality !== undefined && selectedoccupation !== '' && selectedoccupation !== "Select Occupation"&& formData.gender !== '' && formData.is_married !== ''&&formData.expiryDateof!=='';
                                    debugger
                                    // debugger
                                    // var question_valid = !questionsData.some(q => q.isChecked_answer === '');
                                    // var termplan_details_valid = formData.fullName !== '' && formData.email !== '' && formData.mobileNumber !== '' && formData.dob !== '' && formData.civilIDno !== '' && formData.passportNumber !== '' && formData.height !== '' && formData.weight !== '' && selectedoccupation !== '' && formData.resident_location !== '' && formData.nationality !== '' && formData.gender !== '' && formData.is_married !== '';
                                    if(formData.gender === ''){
                                        setGenderError("Please select a Gender.");
                                        alert('Please select the Gender\n الرجاء تحديد الجنس');
                                        document.getElementsByName("gender_value")[0].focus();

                                    }else if(formData.is_married===''){
                                        alert('Please select the Married status\nالرجاء تحديد الحالة متزوج');
                                        setMarriedError("Please select a Married status.");
                                        document.getElementsByName("marital_status")[0].focus();
                                     }else if(formData.dob ===''|| age < 18 ||age > 54 ||age === 54 && parseInt(month, 10) < currentMonth){
                                        if (age < 18) {
                                            setDobError("Age must be within 18 to 54 years.");
                                        } else if (age > 54) {
                                            setDobError("Age must be within 18 to 54 years.");
                                        } else if (age === 54 && parseInt(month, 10) < currentMonth) {
                                            setDobError("Age must be within 18 to 54 years, and for age 54, the month must be the current month or later.");
                                        } else {
                                            alert('Please select the Date of Birth.\nالرجاء تحديد تاريخ الميلاد.');
                                        setDobError("Please select a Date of Birth."); 
                                        }
                                       
                                        document.getElementsByName("dob")[0].focus();
                                    } else if (selectedPremium == null) {
                                        alert('Please Select Term Plan Accident Cover\n الرجاء تحديد خطة تغطية الحوادث لمدة محددة');
                                    }else if (beneficiarydel) {
                                        let isFormValid = true;

                                        beneficiaryData.forEach((row, index) => {
                                            if (!row.name || !row.relation || row.relation === 'Select Relation' || row.relation === 'relation'|| (beneficiaryData.length > 1 && (!row.benefit_percentage || row.benefit_percentage < 1 || row.benefit_percentage > 100))) {
                                                isFormValid = false;

                                                // Focus on the first empty field
                                                const element = document.querySelector(`[name="${!row.name ? 'name' : (!row.relation || row.relation === 'relation'|| row.relation === 'Select Relation' ? 'relation' : 'benefit_percentage')}"][data-index="${index}"]`);
                                                if (element) {
                                                    element.focus();
                                                }
                                                return; // Exit the loop after finding the first invalid field
                                            }
                                        });
                                        alert('Please ensure the Add beneficiary Details\nيرجى التأكد من تفاصيل إضافة المستفيد');
                                   
                                      }else if (formData.fullName === '' || !englishLetterPattern.test(formData.fullName)) {
                                            document.getElementsByName("fullName")[0].focus();
                                           
                                            if (formData.fullName === '') {
                                                setNameError('Please enter Full Name');
                                                alert('Please enter Full Name\n الرجاء إدخال الاسم الكامل');
                                            } else if (!englishLetterPattern.test(formData.fullName)) {
                                                setNameError('Please enter Full Name, accept english letters only.');
                                                alert('Please enter Full Name, accept english letters only.\n الرجاء إدخال الاسم الكامل، وقبول الحروف الإنجليزية فقط.');
                                            }

                                        } else if (formData.email === '' || !emailPattern.test(formData.email)) {
                                           

                                            document.getElementsByName("email")[0].focus();
                                            // Check if the entered email is valid
                                            if (!emailPattern.test(formData.email)) {
                                                setEmailError('Please enter valid e-mail address');
                                                alert('Please enter a valid e-mail address\nيرجى إدخال عنوان بريد إلكتروني صالح');
                                            } else {
                                                setEmailError('Please enter e-mail address');
                                                alert('Please enter e-mail address\n الرجاء إدخال عنوان البريد الإلكتروني');
                                            }
                                        } else if (formData.mobileNumber === '' || formData.mobileNumber.length !== 8 || !/^\d+$/.test(formData.mobileNumber)) {
                                            // Check if the mobile number has exactly 8 digits
                                            document.getElementsByName("mobileNumber")[0].focus();
                                            setMobileError('Please enter 8 digit phone number');
                                            alert('Please enter 8 digit phone number\n الرجاء إدخال رقم الهاتف المكون من 8 أرقام ');

                                        } else if (formData.civilIDno === '' || !civilIDPattern.test(formData.civilIDno)) {

                                            document.getElementsByName("civilIDno")[0].focus();
                                            // Check if the Civil ID matches the pattern
                                            if (!civilIDPattern.test(formData.civilIDno)) {
                                                setCivilIDError('Please enter valid civil id number, it accept numbers only, 4 to 9 digit.');
                                                alert('Please enter valid civil id number, it accept numbers only, 4 to 9 digit.\nالرجاء إدخال رقم الهوية المدنية الصحيح، ويقبل الأرقام فقط، المكونة من 4 إلى 9 أرقام.');
                                            } else {
                                                setCivilIDError('Please enter civil id number.');
                                                alert('Please enter civil id number.\n الرجاء إدخال رقم البطاقة المدنية');
                                            }
                                        } else if (formData.passportNumber === '' || !passportPattern.test(formData.passportNumber)) {

                                            document.getElementsByName("passportNumber")[0].focus();
                                            // Check if the Passport Number matches the pattern
                                            if (!passportPattern.test(formData.passportNumber)) {
                                                setPassportError('Please enter valid passport number, it accept numbers & letters only.');
                                                alert('Please enter valid passport number, it accept numbers & letters only.\nالرجاء إدخال رقم جواز السفر الصحيح، فهو يقبل الأرقام والحروف فقط.');
                                            } else {
                                                alert('Please enter passport number.\n الرجاء إدخال رقم جواز السفر.');
                                                setPassportError('Please enter passport number.');
                                            }
                                        } else if (isNaN(heightValue) || heightValue < 0 || heightValue > 999 || formData.height === '') {

                                            document.getElementsByName("height")[0].focus();
                                            if (isNaN(heightValue) || heightValue < 0 || heightValue > 999) {
                                                setHeightError('Please enter valid height, accept max 3 digits.');
                                                alert('Please enter valid height, accept max 3 digits.\nالرجاء إدخال ارتفاع صحيح، قبول 3 أرقام كحد أقصى.');
                                            } else {
                                                setHeightError('Please enter valid height.');
                                                alert('Please enter valid height.\n الرجاء إدخال الارتفاع الصحيح.');
                                            }
                                        } else if (isNaN(weightValue) || weightValue < 0 || weightValue > 999 || formData.weight === '') {
                                            // Convert the value to a number and check if it's a valid number and within the range

                                            document.getElementsByName("weight")[0].focus();
                                            if (isNaN(weightValue) || weightValue < 0 || weightValue > 999) {
                                                setWeightError('Please enter valid weight, accept max 3 digits.');
                                                alert('Please enter valid weight, accept max 3 digits.\nالرجاء إدخال وزن صحيح، يرجى قبول 3 أرقام كحد أقصى.');
                                            } else {
                                                setWeightError('Please enter weight.');
                                                alert('Please enter weight.\nالرجاء إدخال الوزن.');
                                            }
                                         
                                        // } else if (!bmi) { 
                                        //     alert("BMI is outside the normal range (19-30). Please enter valid Height and Weight.\n مؤشر كتلة الجسم خارج النطاق الطبيعي (19-30). الرجاء إدخال الطول والوزن الصحيحين.");

                                         }else if(formData.nationality ===''|| formData.nationality=== undefined){
                                            setNationalityError('Please Select a Nationality')
                                            alert('Please Select the Nationality\n الرجاء تحديد الجنسية');
                                            selectnationalty.current.focus();
                                            // document.getElementsByName("expiryDateof")[0].focus();
                                            // setExpriydateError('Please Select a Expiry Date of Civil ID / Passport')
                                        }else if(selectedoccupation ===''||selectedoccupation === "Select Occupation"){
                                            setOccupationError('Please Select a Occupation')
                                            alert('Please Select a Occupation\n الرجاء تحديد المهنة');
                                            selectOpt1.current.focus();
                                            // document.getElementsByName("expiryDateof")[0].focus();
                                            // setExpriydateError('Please Select a Expiry Date of Civil ID / Passport')
                                       }else if (formData.resident_location === '' || !locationPattern.test(formData.resident_location)) {
                                            
                                            document.getElementsByName("resident_location")[0].focus();
                                            // Check if the Residential Location is empty or invalid
                                            if (formData.resident_location === '') {
                                                setLocationError('Please enter your Residential Location');
                                                alert('Please enter your Residential Location\n الرجاء إدخال موقع السكن الخاص بك');
                                            } else if (!locationPattern.test(formData.resident_location)) {
                                                setLocationError('Please enter a valid Residential Location (letters, spaces, commas, hyphens only)');
                                                alert('Please enter a valid Residential Location (letters, spaces, commas, hyphens only)\n الرجاء إدخال موقع سكن صالح (الحروف والمسافات والفواصل والواصلات فقط)');
                                            } else {

                                            }
                                       }else if(formData.expiryDateof===''){
                                            document.getElementsByName("expiryDateof")[0].focus();
                                            setExpriydateError('Please Select a Expiry Date of Civil ID / Passport')
                                            alert('Please Select a Expiry Date of Civil ID / Passport\n الرجاء تحديد تاريخ انتهاء البطاقة المدنية / جواز السفر')
                                    } else if (!question_valid) {
                                        alert('Please ensure the questions and answer\n يرجى التأكد من الأسئلة والإجابة');
                                    }else if ( questionsData[0].isChecked_answer =='No'){
                                        alert(`Please select the Answer I Agree in question 1`)
                                      }else if (questionsData[1].isChecked_answer =='No'){
                                        alert(`Please select the Answer I Agree in question 2`)
                                      }else if (questionsData[2].isChecked_answer =='No'){
                                        alert(`Please select the Answer I Agree in question 3`)
                                      }else if (questionsData[3].isChecked_answer !='No' && form_download != true){
                                        alert("Please download the Questionnaire document")
                                      } else if (signatureRef.current.isEmpty()) {
                                        alert('Please put the signature\n من فضلك ضع التوقيع');
                                    } else if (!isChecked) {

                                        alert('Please ensure the declaration \nيرجى التأكد من الإعلان');
                                    } else {
                                        setLoading(true);
                                        setTermPlamPolicyData(null);
                                        setTermPlanPolicyPaymentLink(null);
                                        var accessTokenData = await createAccessToken();
                                        var accessToken = accessTokenData?.result?.data?.token;
                                        if (accessToken != null && accessToken != undefined) {
                                            await getQuotation(accessToken);

                                        }
                                        
                                    }
                                }}
                            >
                                Proceed
                            </button>)}
                        </div>



                    </div>

                </div>
                {isModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={() => setIsModalOpen(false)}>&times;</span>
                        <h1>Thank you for contacting</h1>
                        <h1>Dhofar Insurance Company</h1>
                        <p></p>
                        {/* {bmi != 0 && <p style={{
                                    color:  '#3fd2f2',
                                }}>Your Body Mass Index is {bmi}</p>} */}
                        {/* <p>BMI is outside the normal range (19-30).</p> */}
                       <p>We have received your request for </p>
                       <p>{modalMessageno}</p>
                        <p>Please call us @ 8000 7777 for further assistance.</p>
                    </div>
                </div>
            )}

                {/* <div className="">
                    
                    <div className='row mb-3 mt-4'>
                        <div className='col-md-6 text-start'>
                            <h2>File Uploads </h2>
                        </div>
                        <div className='col-md-6 text-right'>
                            <h2>تفاصيل الدفع</h2>
                        </div>
                    </div>

                    <div class='inside_visa-form'>

                        <div className="pl-1">
                    <div className="form-group row">
                      <label className="col-sm-12 col-md-8 col-form-label text-left">
                        <strong>{t('Passport copy ')}</strong>
                        <br /> <small>{t('(Attach the visa page or resident ID)')}</small>
                      </label>
                      <div className="col-sm-12 col-md-4">
                        {passportFile === null ? (
                          <button  onClick={() => document.getElementById('passport').click()}>
                            {t('Upload File')}
                          </button>
                        ) : (
                          <div className="d-flex border mt-2 align-items-center p-2 rounded">
                            <p className="mb-0" style={{ width: '80%' }}>
                              {passportFile.name.length > 5
                                ? `${passportFile.name.substring(0, 5)}...${passportFile.type.split('/')[1]}`
                                : passportFile.name}
                            </p>
                            <span
                              className="text-danger ms-auto"
                              onClick={handleFileDelete}
                              data-name="passport"
                              style={{ cursor: 'pointer', width: '20%' }}>
                              &times;
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    {passportFileError && <p style={{ color: 'red' }}>{passportFileError}</p>} 
  
                    <div className="form-group row mt-3">
                      <label className="col-sm-12 col-md-8 col-form-label text-left">
                        <strong>{t('Filled questionnaire')}</strong>
                      </label>
                      <div className="col-sm-12 col-md-4">
                        {questionnaireFile === null ? (
                          <button  onClick={() =>
                          document.getElementById('questionnaire').click()}>
                            {t('Upload File')}
                          </button>
                        ) : (
                          <div className="d-flex border mt-2 align-items-center p-2 rounded">
                            <p className="mb-0" style={{ width: '80%' }}>
                              {questionnaireFile.name.length > 5
                                ? `${questionnaireFile.name.substring(0, 5)}...${questionnaireFile.type.split('/')[1]}`
                                : questionnaireFile.name}
                            </p>
                            <span
                              className="text-danger ms-auto"
                              onClick={handleFileDelete}
                              data-name="questionnaire"
                              style={{ cursor: 'pointer', width: '20%' }}>
                              &times;
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    {questionFileError && <p style={{ color: 'red' }}>{questionFileError}</p>} 
  
                    <input
                      type="file"
                      name="passport"
                      id="passport"
                      accept="application/pdf,image/*"
                      style={{ display: 'none' }}
                    
                      onChange={handleFileChange}
                    />
                    <input
                      type="file"
                      name="questionnaire"
                      id="questionnaire"
                      accept="application/pdf,image/*,application/docx"
                      style={{ display: 'none' }}
                   
                      onChange={handleFileChange}
                    />
  
                    <div className="mt-4 text-center">
                      <button type="submit" >
                        {t('  Submit File ')}
                      </button>
                    </div>
                        


                        </div>



                    </div>

                </div> */}
                <div className="">
                    {/* <h2>Payment Details</h2> */}
                    <div className='row mb-3 mt-4'>
                        <div className='col-md-6 text-start'>
                            <h2>Payment Details</h2>
                        </div>
                        <div className='col-md-6 text-right'>
                            <h2>تفاصيل الدفع</h2>
                        </div>
                    </div>

                    <div class='inside_visa-form'>

                        <div className="pl-1">
                            <ul className="list-unstyled">
                                <li><strong>Basic premium : </strong> {quotationData != null && quotationData != undefined ? quotationData.base_price + " OMR" : ''}</li>
                                <li><strong>Policy Fee : </strong> {quotationData != null && quotationData != undefined ? quotationData.policy_fee + " OMR" : ''}</li>
                                <li><strong>Death Cover : </strong> {quotationData != null && quotationData != undefined ? quotationData.death_cover + " OMR" : ''}</li>
                                <li><strong>Government Fee : </strong> {quotationData != null && quotationData != undefined ? quotationData.government_fee + " OMR" : ''}</li>
                                <li><strong>Emergency Fund Fee : </strong>{quotationData != null && quotationData != undefined ? quotationData.emergency_fund_fee + " OMR" : ''}</li>
                                <li><strong>Total : </strong>{quotationData != null && quotationData != undefined ? quotationData.total + " OMR" : ''}</li><br />
                                <li><h2>Reference Number : </h2>{termPlanPolicyData != null && termPlanPolicyData != undefined ? termPlanPolicyData : ''}</li>

                            </ul>


                        </div>



                    </div>

                </div>

                {/* <button type="submit">Submit</button> */}
                <div className='row'>
                    <div className='col-md-2 text-center'>
                        <button type="button"
                            style={{ backgroundColor: termplanPolicyPaymentLink != null ? '#1D3561' : 'grey', borderColor: '#1D3561' }}
                            className="rounded-pill text-white my-3 py-1 "
                            onClick={() => {
                                window.location.href = termplanPolicyPaymentLink;
                                // resetForm();
                                // window.location.href = termplanPolicyPaymentLink;
                                // window.open(termplanPolicyPaymentLink, '_blank')
                                // Open link in a new tab
                                // window.open(termplanPolicyPaymentLink, '_blank')

                                // Reload the current page (form tab)
                                // window.location.reload();
                            }}
                            disabled={termplanPolicyPaymentLink != null ? false : true}
                        >
                            Make Payment
                        </button>
                    </div>
                    <div className='col-md-4'></div>
                    <div className='col-md-4'></div>

                </div>
            </form>
            {loading && (
                <div className="overlay">
                    <div className="spinner"></div>
                </div>
            )}

        </Fragment> : <div style={{
            width: '100vw',
            height: '100vh',
            border: 'none',
            textAlign: 'center',
            marginTop: '25%',
            fontSize: '20px',
            fontWeight: 'bold'
        }}>  {isHidePage == '' ? "Loading" : "Token Invalid"}</div>

    );
};
export default SanadTermPlan;
