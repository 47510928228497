import React, { Fragment, useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import myLoader from "./loaderred.gif";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import ReactSelect from "react-select";
import Logout from "./Logout";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { data } from "jquery";
import axios from "axios";

const { config } = require("./api/api");

const AgentProfile = ({ usrtok }) => {
  const base_url = config.baseURL;
  const { t } = useTranslation();
  const lnstr = Cookies.get("langData") ? Cookies.get("langData") : "en";

  const tabListStyle = {
    display: "flex",
    justifyContent: "space-between",
    width: "80%",
    flexWrap: "no-wrap",
  };

  const tabItemStyle = {
    flex: "1 1 33%",
    textAlign: "center",
    cursor: "pointer",
    transition: "all 0.6s ease",
  };

  const activeTabStyle = {
    backgroundColor: "#33d0f2",
    color: "#FFFFFF",
    fontWeight: "bold",
    transform: "scale(1)",
    fontSize: 18,
  };

  const inactiveTabStyle = {
    backgroundColor: "#f8f9fa",
    color: "#495057",
    transform: "scale(1)",
    fontWeight: "bold",
    fontSize: 15,
  };

  const tabHoverStyle = {
    backgroundColor: "#e9ecef",
    transform: "scale(1)",
  };

  const [vendorKey, setVendorKey] = useState("");
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const [phoneValue, setPhoneValue] = useState("");
  const [country, setCountry] = useState("");
  const [profile, setProfile] = useState(null);
  const [error, setError] = useState(null);
  const [agencyName, setAgencyName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState([]);
  const [maritalStatus, setMaritalStatus] = useState([]);
  const [token, setToken] = useState(null);
  const [tabIndex, setTabIndex] = useState(0); // Track active tab
  // const [dobChange,setDobChange]=useState(false);
  const [countryList, setCountries] = useState([]);
  const [crDocumentFile, setCRDocumentFile] = useState(null);

  debugger;
  useEffect(() => {
    debugger;
    const storedUserString = Cookies.get("userData"); //sessionStorage.getItem('userData')
    if (storedUserString !== undefined && storedUserString !== "undefined") {
      const storedUser = JSON.parse(storedUserString);
      setUserData(storedUser);
    }
  }, []);

  usrtok = userData.access_token;

  useEffect(() => {
    const postvData = { organization_name: "Superjet" };

    if (vendorKey === "" || vendorKey === null || vendorKey === undefined) {
      fetch(base_url + "/create_organization", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postvData),
      })
        .then((response) => response.json())
        .then((data) => {
          setVendorKey(data.result[0].vendor_key);
        })
        .catch((error) => {
          console.error("Error posting data:", error);
        });
    }
  }, [vendorKey]); // Dependency array

  useEffect(() => {
    if (!Cookies.get("userData")) {
      alert("Login Session Expired");
      window.location.href = "/";
    } else if (vendorKey !== "") {
      setProfile(true);
      //  var vkData = { vendor_key: vendorKey };
      // fetch(base_url+'/each_traveller_details', {
      //   method: 'POST',
      //   headers: {
      //       'Authorization': `Bearer ${usrtok}`,
      //       'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify(vkData),
      //   })
      //   .then(response => response.json())
      //   .then(data => {
      //     debugger
      //     setProfile(data.result[0]);
      //   })
      //   .catch(error => {
      //     console.error('Error fetching countries:', error);
      //   });
    }
  }, [usrtok, vendorKey]);

  useEffect(() => {
    // debugger

    if (profile != null && profile?.country) {
      const cnData = { country: profile?.country };
      fetch(base_url + "/get_country_code", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(cnData),
      })
        .then((response) => response.json())
        .then((data) => {
          const cde = data.result.replace(/\+/g, "");
          setPhoneValue(cde);
          setProfile((prevpro) => ({ ...prevpro, country_code: cde }));
        })
        .catch((error) => {
          console.error("Error posting data:", error);
        });
    }
  }, [profile?.country]);

  useEffect(() => {
    if (vendorKey !== "" && countryList.length === 0) {
      var vkData = { vendor_key: vendorKey };
      fetch(base_url + "/country", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(vkData),
      })
        .then((response) => response.json())
        .then((data) => {
          // debugger
          setCountries(data.result);
        })
        .catch((error) => {
          console.error("Error fetching countries:", error);
        });
    }
  }, [vendorKey]);

  // useEffect(() => {
  //   if (vendorKey !== "") {
  //     fetch(base_url + "/create_inbound_token", {
  //       method: "GET",
  //       headers: {
  //         "Authorization": `Bearer ${vendorKey}`
  //       }
  //     }).then((response) => {
  //       return response.json()
  //     }).then((data) => {
  //       setToken(data.result.data);
  //     }).catch((err) => {
  //       console.error("Error fetching token:", err);
  //     });

  //   }
  // }, [vendorKey])

  // useEffect(() => {
  //   if (vendorKey !== '' && gender.length === 0) {
  //     fetch(base_url + "/getGender", {
  //       method: "POST",
  //       headers: {
  //         "Authorization": `Bearer ${vendorKey}`,
  //         "Content-Type": 'application/json'
  //       },
  //       body: JSON.stringify(token)
  //     }).then((response) => {
  //       return response.json()
  //     }).then((data) => {
  //       setGender(data.result.data.response_data)
  //     }).catch((err) => {
  //       console.log("getting gender " + err);;
  //     });
  //   }
  //   if (vendorKey !== '' && maritalStatus.length === 0) {
  //     fetch(base_url + "/getMaritalStatus", {
  //       method: "POST",
  //       headers: {
  //         "Authorization": `Bearer ${vendorKey}`,
  //         "Content-Type": 'application/json'
  //       },
  //       body: JSON.stringify(token)
  //     }).then((response) => {
  //       return response.json()
  //     }).then((data) => {
  //       setMaritalStatus(data.result.data.response_data);
  //     }).catch((err) => {
  //       console.log("getting marital status " + err);;
  //     });
  //   }
  // }, [token]);

  // useEffect(() => {
  //   const fetchProfile = async () => {
  //     try {
  //       // Fetch profile data using token
  //       const response = await fetch('https://api.superjetom.com/each_traveller_details', {
  //         method: 'GET',
  //         headers: {
  //           'Authorization': `Bearer ${usrtok}` // <-- Using 'usrtok' here
  //           //'Content-Type': 'application/json' // Adjust content type as needed
  //         }
  //       });

  //       if (response.ok) {
  //         const profileData = await response.json();
  //         setProfile(profileData);
  //       }

  //     } catch (error) {
  //       setError(error.message);
  //     }
  //   };

  //   fetchProfile();

  //   // Clean up function to prevent memory leaks
  //   return () => {
  //     setProfile(null);
  //     setError(null);
  //   };
  // }, [usrtok]); // <-- Add 'usrtok' to the dependency array

  const handleInputChange = (e, field) => {
    debugger;
    const value = e.target.value;
    if (field !== "agency_name" && field !== "phone") {
      setProfile((prevProfile) => ({
        ...prevProfile,
        [field]: value,
      }));
    }
    if (field === "phone" && value.length <= 12) {
      const cleanedValue = value.replace(/[^0-9]/g, "");
      setProfile((prevProfile) => ({ ...prevProfile, [field]: cleanedValue }));
    }
    if (field === "country") {
      setCountry(value);
    }
    if (field === "agency_name") {
      setProfile((prevProfile) => ({
        ...prevProfile,
        [field]: value.replace(/[^A-Za-z\s]/g, ""),
      }));
    }
    if (field === "fileupload") {
      const file = e.target.files[0]; // File objects are in the `files` array

      const maxSize = 2 * 1024 * 1024;
      if (file && file.size > maxSize) {
        alert("File size must be less than 2MB");
        e.target.value = ""; // Clear the input
      } else {
        setCRDocumentFile(e.target.files[0]);

        // await uploadCrDocument(e.target.files[0]);
      }
    }

    // if (field === 'dob') {
    //   const localDate = new Date(value);
    //   const utcDate = new Date(localDate.getUTCFullYear(), localDate.getUTCMonth(), localDate.getUTCDate()+1);
    //   setProfile((prevProfile) => ({ ...prevProfile, [field]: utcDate.toISOString() }));
    // }
  };
  const handleSave = async () => {
    debugger;
    const frmData = new FormData();
    if (crDocumentFile) {
      frmData.append("documentFile", crDocumentFile);
    }
    frmData.append("vendor_key", vendorKey);
    frmData.append("profile", JSON.stringify(profile));

    debugger;
    const response = await axios.post(
      base_url + "/agency_profile_update",
      frmData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response.status === 200 && response.data.message === "success") {
      setProfile(response.data.result[0]);
      setProfile((prevPro) => ({
        ...prevPro,
        cr_document: response.data.result[0].cr_document,
      }));
      alert("Profile updated");
    }
  };
  debugger;
  useEffect(() => {
    const fetchAgentDetails = async () => {
      debugger;
      if (vendorKey && vendorKey !== "") {
        const vk = { vendor_key: vendorKey };
        try {
          const response = await fetch(`${base_url}/each_agent_details`, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${usrtok}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(vk),
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();

          if (data.message === "success") {
            setProfile(data.result[0]);
          } else {
            console.error("Error fetching agent profile:", data.message);
          }
        } catch (error) {
          console.error("Error fetching agent profile:", error);
        }
      }
    };

    fetchAgentDetails();
  }, [vendorKey, usrtok]);

  // if (error) {
  //   return <div>Error: {error}</div>;
  // }

  // if (!profile) {
  //   return <div className="overlay">
  //     <div className="spinner"></div>
  //   </div>;
  // }

  return (
    <div>
      <div className="header_section1">
        <Navbar />

        <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">
            <h3 className="abt-txt my-5">{t("PROFILE")}</h3>
          </div>
        </div>
      </div>

      {/* <div className="features_section layout_padding my-1">
      //   <div className="container">

      //     <div className="row g-4 justify-content-center">

      //       <div className="col-5 wow fadeInUp" data-wow-delay="0.2s">


      //         <h3>{t('Profile')} {t('Details')}</h3>

      //          {profile && 
                    
      //               <table className='mt-3'>
      //                 <tr><td>{t('First')} {t('Name')}</td><td>&nbsp; : &nbsp;</td><td>{profile.first_name}</td></tr>
      //                 <tr><td>{t('Last')} {t('Name')}</td><td>&nbsp; : &nbsp;</td><td>{profile.last_name}</td></tr>
      //                 <tr><td>{t('Email')}</td><td>&nbsp; : &nbsp;</td><td>{profile.email}</td></tr>
      //               </table>

      //               } 


      //         //  <table className='mt-3' >
      //         //   <tr><td >{(lnstr === 'en') ? 'Agency Name' : 'اسم الوكالة'}</td><td>&nbsp; : &nbsp;</td><td> {userData != null && userData != undefined ? userData.agency_name:''} </td></tr>
      //         //   <tr><td style={{ display: 'flex', justifyContent: 'flex-start' }}>{(lnstr === 'en') ? 'Address' : 'عنوان'}</td><td>&nbsp; : &nbsp;</td><td> {userData != null && userData != undefined ? userData.address:''} </td></tr>
      //         //   <tr><td>{t('Email')}</td><td>&nbsp; : &nbsp;</td><td>{userData != null && userData != undefined ? userData.email:''} </td></tr>
      //         //   <tr><td>{(lnstr === 'en') ? 'Status' : 'حالة'}</td> <td>&nbsp; : &nbsp;</td><td>{userData != null && userData != undefined ? userData.status:''} </td></tr>
      //         //   <tr><td>{(lnstr === 'en') ? 'Visa Commision (Flat rate)' : 'لجنة التأشيرات'}</td><td>&nbsp; : &nbsp;</td><td>{userData != null && userData != undefined ? userData.commission_rate:''} </td></tr>
      //         //   <tr><td>{(lnstr === 'en') ? 'Insurance Commision (%)' : 'لجنة التأمين'}</td><td>&nbsp; : &nbsp;</td><td>{userData != null && userData != undefined ? userData.insurance_commision:''} </td></tr>
      //         // </table> 

      //      </div>
      //     </div>
      //   </div>
      // </div> */}
      <div
        className="container-fluid row justify-content-center mt-5"
        style={{ padding: 0 }}
      >
        <Tabs
          selectedIndex={tabIndex}
          onSelect={(index) => setTabIndex(index)}
          style={{ width: "100%" }}
        >
          <TabList className=" mx-md-auto" style={tabListStyle}>
            <Tab
              style={{
                ...tabItemStyle,
                ...(tabIndex === 0 ? activeTabStyle : inactiveTabStyle),
              }}
              mouseLeave={(e) => (e.target.style.backgroundColor = "")}
            >
              <a
                className="nav-link"
                href="#"
                style={{
                  ...(tabIndex === 0 ? activeTabStyle : inactiveTabStyle),
                }}
              >
                Personal Details
              </a>
            </Tab>
            <Tab
              style={{
                ...tabItemStyle,
                ...(tabIndex === 1 ? activeTabStyle : inactiveTabStyle),
              }}
            >
              <a
                className="nav-link"
                href="#"
                style={{
                  ...(tabIndex === 1 ? activeTabStyle : inactiveTabStyle),
                }}
              >
                Address Details
              </a>
            </Tab>
            <Tab
              style={{
                ...tabItemStyle,
                ...(tabIndex === 2 ? activeTabStyle : inactiveTabStyle),
              }}
            >
              <a
                className="nav-link"
                href="#"
                style={{
                  ...(tabIndex === 2 ? activeTabStyle : inactiveTabStyle),
                }}
              >
                Commision Details
              </a>
            </Tab>
          </TabList>

          <TabPanel>
            <div style={{ padding: "30px" }}>
              <div className="row">
                {/* First Name */}
                <div className="col-md-1"></div>
                <div className="col-12 col-md-5 mb-3">
                  <label className="form-label text-secondary">
                    {t("Agency")} {t("Name")}
                    <span style={{ color: "red" }}>&nbsp;*</span>
                  </label>
                  <input
                    type="text"
                    name="agencyName"
                    className="form-control"
                    value={profile?.agency_name}
                    onInput={(e) => handleInputChange(e, "agency_name")}
                    placeholder={t("Enter your agency name")}
                    style={{ borderRadius: "8px" }}
                    required
                  />
                </div>

                {/* Email */}

                <div className="col-12 col-md-5 mb-3">
                  <label className="form-label text-secondary">
                    {t("Email")}
                  </label>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    value={profile?.email}
                    onChange={(e) => handleInputChange(e, "email")}
                    placeholder={t("Enter your email")}
                    style={{ borderRadius: "8px" }}
                    disabled
                  />
                </div>
                <div className="col-md-1"></div>
                {/* Country */}
                <div className="col-md-1"></div>
                <div className="col-12 col-md-5 mb-3">
                  <label className="form-label text-secondary">
                    {t("Country")}
                    <span style={{ color: "red" }}>&nbsp;*</span>
                  </label>
                  <ReactSelect
                    options={countryList?.map((country) => ({
                      value: country.id,
                      label: country.country,
                    }))}
                    value={countryList
                      ?.map((country) => ({
                        value: country.id,
                        label: country.country,
                      }))
                      .find((option) => option.label === profile?.country)}
                    onChange={(selectedOption) => {
                      handleInputChange(
                        {
                          target: {
                            name: "Country".toLowerCase(),
                            value: selectedOption?.label,
                          },
                        },
                        "Country".toLowerCase()
                      );
                    }}
                    placeholder={t("Select your country")}
                    isSearchable
                    styles={{
                      control: (base) => ({ ...base, borderRadius: "8px" }),
                    }}
                  />
                </div>

                {/* Phone */}
                <div className="col-12 col-md-5 mb-3">
                  <label className="form-label text-secondary">
                    {t("Phone")}
                    <span style={{ color: "red" }}>&nbsp;*</span>
                  </label>
                  <div className="d-flex">
                    <input
                      type="text"
                      className="form-control mr-2 text-center"
                      style={{ width: "20%", borderRadius: "8px" }}
                      name="country_code"
                      value={profile?.country_code}
                      onChange={(e) => handleInputChange(e, "country_code")}
                      required
                    />
                    <input
                      type="text"
                      name="phone"
                      className="form-control"
                      value={profile?.phone}
                      onChange={(e) => handleInputChange(e, "phone")}
                      placeholder={t("Enter phone number")}
                      style={{ borderRadius: "8px" }}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-1"></div>

                {/* status */}
                <div className="col-md-1"></div>
                <div className="col-12 col-md-5 mb-3">
                  <label className="form-label text-secondary">
                    {t("Status")}
                  </label>
                  <input
                    type="text"
                    name="status"
                    className="form-control"
                    value={profile?.status}
                    onChange={(e) => handleInputChange(e, "status")}
                    style={{ borderRadius: "8px" }}
                    disabled
                  />
                </div>

                {/* upload and download document*/}
                {
                  profile?.cr_document === "yes" ? (
                    <div className="col-12 col-md-5 mb-3 ">
                      <label className="form-label text-secondary">
                        {t("CR")} {t("Document")}
                      </label>
                      <button
                        className="btn btn-secondary mx-2 "
                        onClick={async () => {
                          debugger;
                          var tokenData = {
                            type: "download",
                            vendor_key: vendorKey,
                          };
                          var content_type = userData.document_type;
                          var api_response = await fetch(
                            `${base_url}/view_download_agency_cr_documents`,
                            {
                              method: "POST",
                              headers: {
                                Authorization: `Bearer ${userData?.access_token}`,
                                "Content-Type": "application/json",
                              },
                              body: JSON.stringify(tokenData),
                            }
                          );
                          var api_response_data = await api_response.json();
                          debugger;

                          if (api_response_data.message === "success") {
                            var filename = profile?.document_name;
                            var base64Data = api_response_data?.result;

                            // Convert base64 string to a Blob
                            var byteCharacters = atob(base64Data);
                            var byteArrays = [];
                            for (
                              var offset = 0;
                              offset < byteCharacters.length;
                              offset += 1024
                            ) {
                              var slice = byteCharacters.slice(
                                offset,
                                offset + 1024
                              );
                              var byteNumbers = new Array(slice.length);
                              for (var i = 0; i < slice.length; i++) {
                                byteNumbers[i] = slice.charCodeAt(i);
                              }
                              var byteArray = new Uint8Array(byteNumbers);
                              byteArrays.push(byteArray);
                            }
                            var blob = new Blob(byteArrays, {
                              type: profile?.document_type,
                            });
                            const documentUrl = URL.createObjectURL(blob);
                            // Trigger the file download
                            const link = document.createElement("a");
                            link.href = documentUrl;
                            link.download = filename; // Specify the file name
                            document.body.appendChild(link);
                            link.click();
                            // Clean up the blob URL
                            URL.revokeObjectURL(documentUrl);
                            document.body.removeChild(link);
                          }
                        }}
                        style={{
                          display: "block",
                          borderRadius: "8px",
                          // width:"30%",
                          // marginTop:"28px",
                          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <i class="fa-solid fa-download"></i>&nbsp;
                        {profile?.document_name.length <= 15
                          ? `${profile?.document_name}`
                          : `${
                              (
                                profile?.document_name.split(".")[0] + ""
                              ).substring(0, 15) + "..."
                            }${profile?.document_type?.substring(
                              profile.document_type.indexOf("/") + 1
                            )}`}
                      </button>
                    </div>
                  ) : (
                    <div className="col-12 col-md-5 mb-3">
                      <label className="form-label text-secondary">
                        {t("CR")} {t("Upload")}
                      </label>
                      <input
                        className="form-control "
                        name="file"
                        type="file"
                        onChange={(e) => handleInputChange(e, "fileupload")}
                        accept=".png,.jpg,.jpeg,.pdf,.csv"
                      />
                      <small style={{ textAlign: "right", display: "block" }}>
                        Accepted formats: .png, .jpg, .jpeg, .pdf, .csv. Maximum
                        file size: 2MB.
                      </small>
                    </div>
                  )
                  // <div className="col-md-1"></div>
                }
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div style={{ padding: "30px" }}>
              <div className="row">
                {[
                  "Building",
                  "Floor",
                  "Apartment",
                  "Street",
                  "City",
                  "State",
                ].map((field) =>
                  field === "Building" ||
                  field === "Apartment" ||
                  field === "City" ? (
                    <>
                      <div className="col-md-1"></div>
                      <div className="col-12 col-md-5 mb-3" key={field}>
                        <label className="form-label text-secondary">
                          {t(field)}
                        </label>
                        <input
                          type="text"
                          name={field.toLowerCase()}
                          className="form-control"
                          value={profile?.[field.toLowerCase()] || ""}
                          onChange={(e) =>
                            handleInputChange(e, field.toLowerCase())
                          }
                          placeholder={t(`Enter your ${field.toLowerCase()}`)}
                          style={{ borderRadius: "8px" }}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-12 col-md-5 mb-3" key={field}>
                        <label className="form-label text-secondary">
                          {t(field)}
                        </label>
                        <input
                          type="text"
                          name={field.toLowerCase()}
                          className="form-control"
                          value={profile?.[field.toLowerCase()] || ""}
                          onChange={(e) =>
                            handleInputChange(e, field.toLowerCase())
                          }
                          placeholder={t(`Enter your ${field.toLowerCase()}`)}
                          style={{ borderRadius: "8px" }}
                        />
                      </div>
                      <div className="col-md-1"></div>
                    </>
                  )
                )}
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div style={{ padding: "30px" }}>
              <div className="row">
                {/* commision rate */}
                <div className="col-md-1"></div>
                <div className="col-12 col-md-5 mb-3">
                  <label className="form-label text-secondary">
                    {t("Visa")} {t("Commision")} {t("(Flat rate)")}
                  </label>
                  <input
                    type="text"
                    name="visa_commision"
                    className="form-control"
                    value={profile?.visa_commision}
                    // onInput={(e) => handleInputChange(e, 'visa_commision')}
                    style={{ borderRadius: "8px" }}
                    disabled
                  />
                </div>
                {/* insurance Commision */}

                <div className="col-12 col-md-5 mb-3">
                  <label className="form-label text-secondary">
                    {t("Insurance")} {t("Commision")} {"(%)"}
                  </label>
                  <input
                    type="text"
                    name="insurance_commision"
                    className="form-control"
                    value={profile?.insurance_commision}
                    // onInput={(e) => handleInputChange(e, 'insurance_commision')}
                    style={{ borderRadius: "8px" }}
                    disabled
                  />
                </div>
                <div className="col-md-1"></div>
              </div>
            </div>
          </TabPanel>
          {/* <TabPanel>
            <div style={{ padding: '30px' }}>
              <div className="row">

                {/* Date of Birth */}
          {/*<div className="col-12 col-md-6 mb-3">
                  <label className="form-label text-secondary">{t('Date')} {t('Of')} {t('Birth')}</label>
                  <input
                    type="date"
                    name="dob"
                    className="form-control"
                    value={profile?.dob ? profile.dob.split('T')[0] : ''}
                    onChange={(e) => handleInputChange(e, 'dob')}
                    placeholder={t('Enter date of birth')}
                    style={{ borderRadius: '8px' }}
                  />
                </div> */}

          {/* Gender */}
          {/*<div className="col-12 col-md-6 mb-3">
                  <label className="form-label text-secondary">{t('Gender')}</label>
                  <ReactSelect
                    options={
                      gender?.map((gen) => ({
                        value: gen.id,
                        label: gen.description,
                      }))
                    }
                    value={
                      gender
                        ?.map((gen) => ({
                          value: gen.id,
                          label: gen.description,
                        }))
                        .find((option) => option.label === profile.gender)
                    }
                    onChange={(selectedOption) => {
                      handleInputChange(
                        {
                          target: {
                            name: 'Gender'.toLowerCase(),
                            value: selectedOption?.label,
                          },
                        },
                        'Gender'.toLowerCase()
                      );
                    }}
                    placeholder={t('Select your gender')}
                    styles={{
                      control: (base) => ({ ...base, borderRadius: '8px' }),
                    }}
                  />
                </div>*/}

          {/* Marital Status */}
          {/* <div className="col-12 col-md-6 mb-3"> 
                  <label className="form-label text-secondary">{t('Marital')} {t('Status')}</label>
                  <ReactSelect
                    options={
                      maritalStatus?.map((marital) => ({
                        value: marital.id,
                        label: marital.description,
                      }))
                    }
                    value={
                      maritalStatus
                        ?.map((marital) => ({
                          value: marital.id,
                          label: marital.description,
                        }))
                        .find((option) => option.label === profile.marital_status)
                    }
                    onChange={(selectedOption) => {
                      handleInputChange(
                        {
                          target: {
                            name: 'marital_status'.toLowerCase(),
                            value: selectedOption?.label,
                          },
                        },
                        'marital_status'.toLowerCase()
                      );
                    }}
                    placeholder={t('Select your marital status')}
                    styles={{
                      control: (base) => ({ ...base, borderRadius: '8px' }),
                    }}
                  />
                </div>*/}

          {/* Passport Number */}
          {/*  <div className="col-12 col-md-6 mb-3">
                  <label className="form-label text-secondary">{t('Passport')} {t('No')}</label>
                  <input
                    type="text"
                    name="passport_no"
                    className="form-control"
                    value={profile?.passport_no}
                    onChange={(e) => handleInputChange(e, 'passport_no')}
                    placeholder={t('Enter your passport number')}
                    style={{ borderRadius: '8px' }}
                  />
                </div> */}

          {/* Civil ID */}
          {/* <div className="col-12 col-md-6 mb-3">
                  <label className="form-label text-secondary">{t('Civil')} {t('ID')}</label>
                  <input
                    type="text"
                    name="civil_id"
                    className="form-control"
                    value={profile?.civil_id}
                    onChange={(e) => handleInputChange(e, 'civil_id')}
                    placeholder={t('Enter your civil ID')}
                    style={{ borderRadius: '8px' }}
                  />
                </div> 
              </div>
            </div>
          </TabPanel> */}
        </Tabs>
      </div>
      {/* <div className="row">
      <div className="col-12 col-md-9 col-sm-6"></div>
      <div className="mt-md- ml-md-4 w-sm-75 ml-sm-5 mb-sm-4 d-flex justify-content-end "> */}
      <div
        className="w-sm-50 d-flex justify-content-end mt-1 ml-sm-5"
        style={{
          paddingBottom: "20px",
          paddingRight: "20px",
          marginRight: "10%",
        }}
      >
        <button
          className="btn btn-primary "
          onClick={handleSave}
          style={{
            borderRadius: "8px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          {t("Save")} {t("Changes")}
        </button>
      </div>
      <Footer />
    </div>
  );
};

export default AgentProfile;
