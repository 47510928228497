import React, { Fragment, useState, useEffect, useRef } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { useTranslation } from 'react-i18next';
import {  Col } from "react-bootstrap";
import Cookies from 'js-cookie';
import SignatureCanvas from 'react-signature-canvas';
const { config } = require('../api/api');

function Homeinformationquestions(){

  const base_url = config.baseURL;

  const lnstr = Cookies.get('langData') ? Cookies.get('langData') : 'en';
  const signatureRef = useRef();

  const { t } = useTranslation();


  const [loading, setLoading] = useState(false);

 
  const [quotationData, setQuotationData] = useState(null);
  const [personalInformation,setPersonalInformation]=useState(null);
  const [sumAssured,setSumAssured]=useState(null);
  const [homeDetails,setHomeDetails]=useState(null);
  const [homePlanType,setHomePlanType]=useState(null);
  const [quotation,setQuotation]=useState(null);
  const [premium, setPremium] = useState(null);
  const [files,setFiles]=useState([]);
  const [policyNo,setPolicyNo]=useState(null);

  const [questionList, setQuestionList] = useState();
  const [isSignatureEmpty, setIsSignatureEmpty] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [questionsData, setQuestionsData] = useState([{
    english: '',
    arabic: '',
    key: '',
    accepted_answer: '',
    isChecked: '',
    isChecked_answer: '',
    details: ''
  }]);

  const [dhofarData, setDhofarData] = useState(false);
  const [vendorKey, setVendorkey] = useState('');
  // useEffect(() => {
  //   const storedUserString = Cookies.get('homeData'); //sessionStorage.getItem('userData')
  //   if (storedUserString != undefined) {
  //     const storedUser = JSON.parse(storedUserString);
  //     setUserData(storedUser);
  //   } else {
  //     // alert("token expired");
  //     // window.location.href = '/';
  //   }
  // }, []);

 

  function handleRadioChange(index, isChecked) {
    const updatedQuestionsData = [...questionsData];
    updatedQuestionsData[index] = {
      ...updatedQuestionsData[index],
      isChecked,
      isChecked_answer: isChecked ? "Yes" : "No"
    };
    setQuestionsData(updatedQuestionsData);
  }

  function handleDetailsChange(index, details) {

    const updatedQuestionsData = [...questionsData];
    updatedQuestionsData[index] = {
      ...updatedQuestionsData[index],
      details
    };
    setQuestionsData(updatedQuestionsData);
  }




  const clearSignature = () => {
    signatureRef.current.clear();
    setIsSignatureEmpty(true); // Disable button after clearing
  };
  const handleSignature = () => {
    if (!signatureRef.current.isEmpty()) {
   
      setIsSignatureEmpty(false); // Enable button when signature is drawn
    }
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

 
  useEffect(() => {
   const storedDhofarString = Cookies.get('homeData'); //sessionStorage.getItem('userData')

    if (storedDhofarString !== undefined) {
      const storedDhofar = JSON.parse(storedDhofarString);
      setDhofarData(storedDhofar);
    }
    else {
      // alert("token expired");
      // window.location.href='/';
    }

  }, []);



  useEffect(() => {

    const postvData = { organization_name: "Superjet" };
  

    if (vendorKey === '' || vendorKey === null || vendorKey === undefined) {


      fetch( base_url + '/create_organization', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postvData),
      })
        .then(response => response.json())
        .then(data => {
          setVendorkey(data.result[0].vendor_key);
        })
        .catch(error => {
          console.error('Error posting data:', error);
        });


    }
  }, [vendorKey]); // Dependency array

  const postData = { token: dhofarData };

 

  useEffect(() => {
    if (vendorKey !== '') {
      const fetchQuestions = async () => {
        try {
        
          const response = await fetch( base_url + '/get_home_questions', {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${vendorKey}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(postData),
          });

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const data = await response.json();
        
          setQuestionList(data.result.data);
          var questionsLists = data.result.data;

          questionsLists = questionsLists.map(question => ({
            english: question.English,
            arabic: question.Arabic,
            key: question.key,
            accepted_answer: question.accepted_answer,
            isChecked: '',
            details: '',
            isChecked_answer: ''
          }));
          setQuestionsData(questionsLists);
        } catch (error) {
          console.error('Error fetching occupations:', error);
        }
      };

      fetchQuestions();
    }
  }, [vendorKey]);

 
  const api_headers = {
    'Authorization': 'Bearer ' + vendorKey,
    'Content-Type': 'application/json' // Ensure you're sending JSON
  }
 
useEffect(() => {
 const personalInfo = sessionStorage.getItem("HomeInsurancePersonalInfo");
const sectionSumAssured = sessionStorage.getItem("homeowner");
const homeAllDetails = sessionStorage.getItem("homedetails");
const homePlan = sessionStorage.getItem("homeplan");

if (personalInfo !== undefined) {
  const personalInfoParse = JSON.parse(personalInfo);
  setPersonalInformation(personalInfoParse);
}

if (sectionSumAssured !== undefined) {
  const sumAssured = JSON.parse(sectionSumAssured);
  setSumAssured(sumAssured);
}

if (homeAllDetails !== undefined) {
  const homeDetailsObj = JSON.parse(homeAllDetails);
  setHomeDetails(homeDetailsObj);
}

if (homePlan !== undefined) {
  const parseHomePlan = JSON.parse(homePlan);
  setHomePlanType(parseHomePlan);
}
  const fetchQuotation = async () => {

  
var reqQuotationData = {
  nationality: personalInformation?.nationality,
  civil_id: personalInformation?.civilid,
  personalInformation_fullName: personalInformation?.fullName,
  personalInformation_email: personalInformation?.email,
  personalInformation_mobileNumber: personalInformation?.mobilenumber,
  personalInformation_resident_location: personalInformation?.Residential_Location,
  id_expiry_date: personalInformation?.expiry_date,
  occupation: personalInformation?.occupation,
  occupation_code: personalInformation?.occupation_code,
  homeOwnerType: homePlanType?.plan.toLowerCase(),
  section_1_selected: sumAssured?.section_1,
  section_2_selected: sumAssured?.section_2,
  section_3_selected: sumAssured?.section_3,
  section_4_selected: sumAssured?.section_4,
  section_5_selected: sumAssured?.section_5,
  section_6_selected: sumAssured?.section_6,
  section_7_selected: sumAssured?.section_7,
  section_8_selected: sumAssured?.section_8,
  section_1_sum_assured: sumAssured?.section_1_sum_assured,
  section_3_sum_assured: sumAssured?.section_3_sum_assured,
  section_5_sum_assured: sumAssured?.section_5_sum_assured,
  home_details_address: homeDetails?.address,
  home_details_business_of_insured: "Employee",
  home_details_age_of_building: homeDetails?.age_of_building ,
  home_details_type_of_building: homeDetails?.type_of_building,
  home_details_floor_no: homeDetails?.floor_no,
  home_details_is_property_in_basement: homeDetails?.property_in_basement,
  home_details_have_burglar_alarm_system: homeDetails?.burglar_alarm_system,
  home_details_have_fire_alarm_system: homeDetails?.fire_alarm_system,
  home_details_have_grilled_doors: homeDetails?.grilled_doors,
  home_details_have_hydrant_system: homeDetails?.hydrant_system,
  home_details_have_portable_extinguishers: homeDetails?.portable_extinguishers,
  home_details_have_security_checkpoint: homeDetails?.security_checkpoint,
  home_details_have_surveillance_camera: homeDetails?.surveillance_camera,
  home_details_have_watchman_service: homeDetails?.watchman_service,
  home_details_is_whole_building_residential: homeDetails?.whole_building_residential,
  home_details_not_residential_reason: "test",
  home_details_other_fire_protection: homeDetails?.other_fire_protection || "not available",
  home_details_other_security_system: homeDetails?.other_security_system || "not available",
  home_details_previous_insurance_details: "not available",
  token: dhofarData
};
    try {
      const response = await fetch(`${base_url}/get_home_quotation_owner`, {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${vendorKey}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(reqQuotationData)
      });
      const data = await response.json();
      if (data.message === "success") {
        setQuotation(data?.result?.data?.amount);
        setPremium(data?.result?.data?.amount?.premium_details)
      } else {
        setQuotation(null);
        setPremium(null);
      }
    }
     catch (error) {
      console.error('Error fetching quotation:', error);
    }
  };

  if(vendorKey!==null&&vendorKey!==undefined&& vendorKey!==""){
  fetchQuotation();
  }
}, [vendorKey]);

var addPolicyData = {
  nationality: personalInformation?.nationality,
  civil_id: personalInformation?.civilid,
  personalInformation_fullName: personalInformation?.fullName,
  personalInformation_email: personalInformation?.email,
  personalInformation_mobileNumber: personalInformation?.mobilenumber,
  personalInformation_resident_location: personalInformation?.Residential_Location,
  id_expiry_date: personalInformation?.expiry_date,
  occupation: personalInformation?.occupation,
  occupation_code: personalInformation?.occupation_code,
  amount_base_price: quotation?.base_price,
  amount_emergency_fund_fee: quotation?.emergency_fund_fee,
  amount_government_fee: quotation?.government_fee,
  amount_policy_fee:quotation?.policy_fee,
  amount_premium_details_section_1_premium: premium?.section_1_premium,
  amount_premium_details_section_2_premium: premium?.section_2_premium,
  amount_premium_details_section_3_premium: premium?.section_3_premium,
  amount_premium_details_section_4_premium: premium?.section_4_premium,
  amount_premium_details_section_5_premium: premium?.section_5_premium,
  amount_premium_details_section_6_premium_ownership_type: premium?.section_6_premium_ownership_type,
  amount_premium_details_section_6_premium_personal:premium?.section_6_premium_personal ,
  amount_premium_details_section_7_premium: premium?.section_7_premium,
  amount_premium_details_section_8_premium: premium?.section_8_premium,
  amount_premium_details_total_premium: premium?.total_premium,
  amount_total: quotation?.total,
  amount_vat: quotation?.vat,
  home_details_address: homeDetails?.address,
  home_details_age_of_building: homeDetails?.age_of_building ,
  home_details_business_of_insured: "Employee",
  home_details_floor_no: homeDetails?.floor_no,
  home_details_have_burglar_alarm_system: homeDetails?.burglar_alarm_system,
  home_details_have_fire_alarm_system: homeDetails?.fire_alarm_system,
  home_details_have_grilled_doors: homeDetails?.grilled_doors,
  home_details_have_hydrant_system: homeDetails?.hydrant_system,
  home_details_have_portable_extinguishers: homeDetails?.portable_extinguishers,
  home_details_have_security_checkpoint: homeDetails?.security_checkpoint,
  home_details_have_surveillance_camera: homeDetails?.surveillance_camera,
  home_details_have_watchman_service: homeDetails?.watchman_service,
  home_details_is_property_in_basement: homeDetails?.property_in_basement,
  home_details_is_whole_building_residential: homeDetails?.whole_building_residential,
  home_details_not_residential_reason: "test",
  home_details_other_fire_protection:homeDetails?.other_fire_protection || "not available",
  home_details_other_security_system: homeDetails?.other_security_system||"not available",
  home_details_previous_insurance_details: "not available",
  home_details_type_of_building: homeDetails?.type_of_building,
  homeOwnerType: homePlanType?.plan.toLowerCase(),
  question_has_any_claims: questionsData[4]?.isChecked_answer,
  question_have_any_mortgage: questionsData[3]?.isChecked_answer,
  question_have_any_special_conditions_imposed: questionsData[2]?.isChecked_answer,
  question_have_insurance_cancelled_by_other_company: questionsData[1]?.isChecked_answer,
  question_have_insurance_declined_before: questionsData[0]?.isChecked_answer,
  section_1_selected: sumAssured?.section_1,
  section_1_sum_assured: sumAssured?.section_1_sum_assured,
  section_2_selected: sumAssured?.section_2,
  section_3_selected: sumAssured?.section_3,
  section_3_sum_assured: sumAssured?.section_3_sum_assured,
  section_4_selected: sumAssured?.section_4,
  section_5_selected: sumAssured?.section_5,
  section_5_sum_assured: sumAssured?.section_5_sum_assured,
  section_6_selected: sumAssured?.section_6,
  section_7_selected: sumAssured?.section_7,
  section_8_selected: sumAssured?.section_8,
  signature: signatureRef?.current?.toDataURL(),
  token: dhofarData,
  total_property_photograph_count: "3"

}

useEffect(()=>{

const storedFiles=sessionStorage.getItem("files");
if(storedFiles){
    const fileArray=JSON.parse(storedFiles);
    const recreatedFiles =fileArray.map((file)=>{
    const arr= file.base64.split(",");
    const bstr=atob(arr[1]);
    let n = bstr.length;
    const u8arr =new Uint8Array(n);
    while(n--){
      u8arr[n]=bstr.charCodeAt(n);
    }
    return new File([u8arr],file.name,{type:file.type})
  });
  setFiles(recreatedFiles);
}
},[])



  const handleQuotation = async (event) => {
    
      event.preventDefault();
      setLoading(true); 
      try{
      
        var policyFormData = new FormData();
  policyFormData.append("policy_data",JSON.stringify(addPolicyData));
  files.forEach((file,index)=>{
  policyFormData.append(`property_photograph_${index+1}`,file);
  });
  
      const response=await fetch(base_url + "/get_home_owner_addpolicy", {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${vendorKey}`
        },
        body:policyFormData
      });
      const  data=await response.json();
      if(data.message==="success"){
       setPolicyNo(data?.result?.policy_number);
       sessionStorage.setItem("homePolicyNo",data?.result?.policy_number);
      }
      else{
      setPolicyNo(null);
      }
      }
      catch(err){
      console.log("error occured while getting policy No",err)
      }
     setLoading(false);
      window.location.href ='/ownersummary';
  
    }

  return (
    <Fragment>

      <div className="header_section1">

        <Navbar />


        <div className="container-fluid bg-breadcrumb">
          <div className="container text-center py-5">
            <h3 className="abt-txt my-5">{(lnstr === 'en') ? 'Please answer the following' : 'الرجاء الإجابة على ما يلي\n'}</h3>
          </div>
        </div>


      </div>


      <div className="features_section">
        <div className="container">

          <div className="row">

            <Col md={3}></Col>

            <Col md={6} className="myforms my-5">


              {questionsData.map((question, index) => (
                <div key={index}>
                  <p>{question.english}</p>
                  <div className="custom-radio" style={{color:'#3fd2f2'}}>
                    <input
                      type="radio"
                      id={`yes-${index}`}
                      name={`option-${index}`}
                      value="Yes"
                      checked={question.isChecked === true}
                      onChange={() => handleRadioChange(index, true)}
                    />
                    <label htmlFor={`yes-${index}`}>Yes</label>

                    <input
                      type="radio"
                      id={`no-${index}`}
                      name={`option-${index}`}
                      value="No"
                      checked={question.isChecked === false}
                      onChange={() => handleRadioChange(index, false)}
                    />
                    <label htmlFor={`no-${index}`}>No</label>
                  </div>
                  <br />
                  {question.isChecked_answer != '' && question.accepted_answer !== question.isChecked_answer && (
                    <div className='col-md-12'>
                      <textarea
                        style={{
                          height: '100px',
                          width: '100%'
                        }}
                        value={question.details || ''} // Ensure there's a default value
                        onChange={(e) => handleDetailsChange(index, e.target.value)}
                        placeholder="Please provide more details"
                      />
                    </div>
                  )}
                </div>
              ))}


              <b>Please put your signature here</b><br />

              <SignatureCanvas
                ref={signatureRef}
                penColor="black"
                canvasProps={{ width: 340, height: 200, className: 'sigCanvas' }}
                style={{ border: '1px solid #ccc', backgroundColor: 'white' }} // Ensure background is white
                onEnd={handleSignature} // Detect when drawing ends
              />
              <div>
                <button type="button"
                  style={{ backgroundColor: !isSignatureEmpty ? '#1D3561' : 'grey', borderColor: '#1D3561' }}
                  className="btn btn-primary rounded-pill text-white my-3 py-1 " onClick={async () => {
                    clearSignature();
                  }}
                  disabled={isSignatureEmpty}
                >
                  Clear Signature
                </button>
              </div>



              <p ><input type="checkbox" checked={isChecked}
              onChange={handleCheckboxChange} name="note" />
              {"I/We hereby declare that to the best of my/our knowledge and belief; the above statements and particulars as furnished herein are complete in all form and true and that I/We have not misstated or misrepresented or suppressed any material facts. (A material fact is one which is likely to influence Dhofar Insurance company acceptance or assessment of this proposal. If in any doubt whether facts are material, they should be disclosed). Submitting this form does not bind the Proposer to complete the Insurance, nor Dhofar Insurance company to accept, but it is agreed that this form shall be the basis of the contract should a policy be issued."}
            </p>
            <div className='col-md-2 text-center'>
              <button type="button" disabled={isChecked ? false : true }
                style={{ backgroundColor: isChecked ? '#1D3561' : "grey", borderColor: '#1D3561' }}
                className="btn btn-primary rounded-pill text-white my-3 py-1 "
                // disabled={isChecked ? false : true}
                onClick={async (event) => {


                  var question_valid = !questionsData.some(q => q.isChecked_answer === '');
          
                  if (!question_valid) {
                    alert('Please ensure the question answer');
                  }
                  else if ( questionsData[0].isChecked_answer !=='No'){
                    alert(`Please select the Answer No in question 1 `)
                  }else if (questionsData[1].isChecked_answer !=='No'){
                    alert(`Please select the Answer Yes in question 2 `)
                  }else if (questionsData[2].isChecked_answer !=='No'){
                    alert(`Please select the Answer No in question 3`)
                  }else if (questionsData[3].isChecked_answer !=='No'){
                    alert(`Please select the Answer No in question 4`)
                  }else if (questionsData[4].isChecked_answer !=='No'){
                    alert(`Please select the Answer No in question 5`)
                  
                  } 
                  else if (signatureRef.current.isEmpty()) {
                    alert('Please put signature');
                  } else if (!isChecked) {

                    alert('Please ensure the declare');
                  } else {
                    setLoading(true);
                    await handleQuotation(event);
                  }
                }}
              >
                Proceed
              </button>
            </div>

            </Col>


            <Col md={3}></Col>

          </div>

        </div>
      </div>

      <Footer />

      {loading && (
        <div className="overlay">
          <div className="spinner"></div>
        </div>
      )}

    </Fragment>
  );
}


export default Homeinformationquestions;